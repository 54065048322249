import React, { Component, Fragment } from "react";
import axios from "axios";

// Import validate function
import { VALIDATION_EMAIL, VALIDATION_TEXT } from "./validate";

// Import Component
import ResponseModal from "../Modal/ResponseModal";

// Redux
import { connect } from "react-redux";
import { setMessage, setLandingPageHeader } from "../../redux/store";

const mapStateToProps = (state) => {
  return {
    REQUEST_STATUS: state.REQUEST_STATUS,
    API_KEY: state.API_KEY,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMessage: (value) => {
      dispatch(setMessage(value));
    },
    setLandingPageHeader: (value) => {
      dispatch(setLandingPageHeader(value));
    },
  };
};

class GetInTouch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      lastname: "",
      email_id: "",
      jobId: "",
      comments: "",
      message: "",
      title: "",
      modalShow: false,
      loader: false,
      validation: {
        firstname: "",
        lastname: "",
        email_id: "",
        jobId: "",
        comments: "",
        message: "",
      },
    };
  }
  handleModal = () => {
    this.setState({ modalShow: !this.state.modalShow }, () => {
      if (!this.props.modalShow) {
        this.setState({
          message: "",
          title: "",
        });
      }
    });
  };

  handleSubmit = () => {
    if (this.state.firstname == "") {
      this.setState({
        validation: {
          ...this.state.validation,
          firstname: "Field Value Missing",
        },
      });
      return;
    } else if (this.state.lastname == "") {
      this.setState({
        validation: {
          ...this.state.validation,
          lastname: "Field Value Missing",
        },
      });
      return;
    } else if (!VALIDATION_TEXT(this.state.firstname)) {
      this.setState({
        validation: {
          ...this.state.validation,
          firstname: "Invalid firstname",
        },
      });
      return;
    } else if (!VALIDATION_TEXT(this.state.lastname)) {
      this.setState({
        validation: {
          ...this.state.validation,
          lastname: "Invalid lastname",
        },
      });
      return;
    } else if (this.state.jobId.length > 6) {
      this.setState({
        validation: {
          ...this.state.validation,
          jobId: "Error!!Invalid Job Id",
        },
      });
      return;
    } else if (!VALIDATION_EMAIL(this.state.email_id)) {
      this.setState({
        validation: {
          ...this.state.validation,
          email_id: "Need Valid Email address",
        },
      });
      return;
    } else if (this.state.comments == "") {
      this.setState({
        validation: {
          ...this.state.validation,
          comments: "Field Value Missing",
        },
      });
      return;
    } else if (this.state.comments > 500) {
      this.setState({
        validation: {
          ...this.state.validation,
          comments: "Maximum length of 500 characters",
        },
      });
      return;
    } else {
      this.handleModal();
      this.setState({
        loader: !this.state.loader,
      });

      let data = {
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        email: this.state.email_id,
        jobId: this.state.jobId,
        comments: this.state.comments,
      };
      // Sending Data to Backend
      axios
        .post("https://qa-uk-api.herokuapp.com/quick_assist/messages", data, {
          headers: {
            Authorization: `Basic ${this.props.API_KEY}`,
          },
        })
        .then((response) => {
          this.setState(
            {
              loader: !this.state.loader,
            },
            () => {
              this.setState({
                firstname: "",
                lastname: "",
                email_id: "",
                jobId: "NA",
                comments: "",
                message: response.data.message,
                title: response.data.title,
              });
            }
          );
        })
        .catch((error) => {
          this.setState({
            loader: !this.state.loader,
            message: error.message,
            title: "Error",
          });
        });
    }
  };
  handleBlur = (e) => {
    if (e.target.value === "" && e.target.id !== "jobId") {
      this.setState({
        [e.target.id]: e.target.value,
        validation: {
          ...this.state.validation,
          [e.target.id]: "Field Value Missing",
        },
      });
    } else if (
      (e.target.id === "firstname" || e.target.id === "lastname") &&
      !VALIDATION_TEXT(e.target.value)
    ) {
      this.setState({
        [e.target.id]: e.target.value,
        validation: {
          ...this.state.validation,
          [e.target.id]: "Invalid Text",
        },
      });
    } else if (this.state.jobId.length > 6) {
      this.setState({
        jobId: e.target.value,
        validation: {
          ...this.state.validation,
          jobId: "Error!!Invalid Job Id",
        },
      });
    } else if (
      e.target.id === "email_id" &&
      !VALIDATION_EMAIL(this.state.email_id)
    ) {
      this.setState({
        email_id: e.target.value,
        validation: {
          ...this.state.validation,
          email_id: "Need Valid Email address",
        },
      });
    } else if (this.state.comments > 500) {
      this.setState({
        comments: this.state.comments.substring(0, 500),
        validation: {
          ...this.state.validation,
          comments: "Maximum length of 500 characters",
        },
      });
    }
  };

  handleChange = (e) => {
    if (e.target.id !== "comments") {
      if (
        (e.target.id === "firstname" || e.target.id === "lastname") &&
        !VALIDATION_TEXT(e.target.value)
      ) {
        this.setState({
          [e.target.id]: e.target.value,
          validation: {
            ...this.state.validation,
            [e.target.id]: "Invalid Text",
          },
        });
      } else {
        this.setState({
          [e.target.id]: e.target.value.trim(),
          validation: {
            ...this.state.validation,
            [e.target.id]: "",
          },
        });
      }
    } else if (e.target.id === "email_id") {
      if (!VALIDATION_EMAIL(this.state.email_id)) {
        this.setState({
          email_id: e.target.value,
          validation: {
            ...this.state.validation,
            email_id: "Need Valid Email address",
          },
        });
      } else {
        this.setState({
          email_id: e.target.value,
          validation: {
            ...this.state.validation,
            email_id: "",
          },
        });
      }
    } else if (e.target.id === "jobId") {
      if (this.state.jobId.length > 6) {
        this.setState({
          jobId: this.state.jobId.substring(0, 5),
          validation: {
            ...this.state.validation,
            jobId: "Maximum length of 6 Characters",
          },
        });
      } else {
        this.setState({
          jobId: this.state.jobId.substring(0, 5),
          validation: {
            ...this.state.validation,
            jobId: "",
          },
        });
      }
    } else if (e.target.id === "comments") {
      if (this.state.comments.length > 500) {
        this.setState({
          comments: this.state.comments.substring(0, 500),
          validation: {
            ...this.state.validation,
            email_id: "Maximum length of 500 characters",
          },
        });
      } else {
        this.setState({
          [e.target.id]: e.target.value,
          validation: {
            ...this.state.validation,
            comments: "",
          },
        });
      }
    }
  };

  render() {
    return (
      <Fragment>
        {/* Mobile */}
        <div
          className="bg-white  d-block d-sm-none"
          onMouseEnter={() => this.props.setLandingPageHeader(false)}
        >
          <div className=" row justify-content-center mx-0 px-2  ">
            {/* Heading and Sub heading */}
            <div className="col-md-12 col-lg-8 col-xl-8 px-4 mb-4 pb-4 mx-auto text-center captions">
              <div className="col_4 header_1">Get In Touch</div>
              <div className="col_2 header_3">
                Most questions can be answered by our FAQ's, but if you can't
                find what you're after, drop us a line and we'll be in touch
              </div>
            </div>

            {/* Content */}

            <div className="col-12 mt-4  d-flex flex-column justify-content-center align-self-center layer_2">
              <div className="card_contact p-4">
                <div class=" px-2 row g-3">
                  <div class="col-12">
                    <h4 for="firstname" className="form-label col_1">
                      First Name
                    </h4>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First name"
                      id="firstname"
                      value={this.state.firstname}
                      onBlur={this.handleBlur}
                      onChange={this.handleChange}
                    />
                    <h5 for="firstname" className="pt-1 col_3">
                      {this.state.validation.firstname}
                    </h5>
                  </div>
                  <div class="col-12">
                    <h4 for="lastname" className="form-label col_1">
                      Last Name
                    </h4>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last name"
                      id="lastname"
                      value={this.state.lastname}
                      onBlur={this.handleBlur}
                      onChange={this.handleChange}
                    />
                    <h5 for="firstname" className="pt-1 col_3">
                      {this.state.validation.lastname}
                    </h5>
                  </div>
                  <div class="col-12">
                    <h4 for="email_id" className="form-label col_1">
                      Email Address
                    </h4>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="abd@domain.com"
                      id="email_id"
                      value={this.state.email_id}
                      onBlur={this.handleBlur}
                      onChange={this.handleChange}
                    />
                    <h5 for="firstname" className="pt-1 col_3">
                      {this.state.validation.email_id}
                    </h5>
                  </div>
                  <div class="col-12">
                    <h4 for="jobId" className="form-label col_1">
                      Order Number
                    </h4>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=" Optional "
                      id="jobId"
                      value={this.state.jobId}
                      onBlur={this.handleBlur}
                      onChange={this.handleChange}
                    />
                    <h5 for="firstname" className="pt-1 col_3">
                      {this.state.validation.jobId}
                    </h5>
                  </div>
                  <div class="form-floating">
                    <div className="d-flex flex-row justify-content-between align-items-center">
                      <h4 for="shortDescription" className="form-label col_1">
                        Comments
                      </h4>
                      <h4 className="col_1">
                        {this.state.comments.length} | 500
                      </h4>
                    </div>
                    <textarea
                      className=" w-100 px-3 py-1"
                      placeholder="Maximum 500 characters"
                      id="comments"
                      style={{ height: "10rem" }}
                      value={this.state.comments}
                      placeholder="Leave a comment here"
                      onBlur={this.handleBlur}
                      onChange={this.handleChange}
                    ></textarea>
                    <h5 for="firstname" className="pt-1 col_3">
                      {this.state.validation.comments}
                    </h5>
                  </div>
                  <div class="d-grid gap-2 ">
                    <button
                      className="btn btn-warning btn-sm shadow py-3 w-100"
                      type="button"
                      onClick={this.handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" row mx-0 py-4 justify-content-start bg py-4 ">
            <div className="col-12  d-flex justify-content-center pt-4 mt-4 layer_1_mobile  ">
              <a
                className="col_1 sub_title  px-4 mt-4"
                href="https://www.facebook.com/people/QuickAssistuk/100064909273893/"
                target={"_blank"}
              >
                <div className=" pr-4 bi bi-facebook header_1 "></div>
              </a>

              <a
                className="col_1 px-3 mt-4 "
                href="https://www.linkedin.com/company/quickassistuk/about/?viewAsMember=true"
                target={"_blank"}
              >
                <div className=" pr-4 bi bi-linkedin  header_1"></div>
              </a>
              <a
                className="col_1 px-3 mt-4 "
                href="https://www.instagram.com/quickassist.uk/"
                target={"_blank"}
              >
                <div className=" pr-4 bi bi-instagram  header_1"></div>
              </a>
              <a
                className="col_1 px-3 mt-4 "
                href="mailto:info@quickassist.uk?subject = Feedback&body = Message"
              >
                <div className=" pr-4 bi bi-envelope-fill header_1"></div>
              </a>
            </div>
          </div>
        </div>
        {/* Desktop */}
        <div
          className="bg-white  d-none d-sm-block"
          onMouseEnter={() => this.props.setLandingPageHeader(false)}
        >
          <div className=" row justify-content-center px-4 mx-0  ">
            {/* Heading and Sub heading */}
            <div className="col-md-12 col-lg-8 col-xl-8 px-4 mb-4 pb-4 mx-auto text-center captions">
              <div className="col_4 header_1">Get In Touch</div>
              <div className="col_2 header_3">
                Most questions can be answered by our FAQ's, but if you can't
                find what you're after, drop us a line and we'll be in touch
              </div>
            </div>

            {/* Content */}
            <div className="col-md-12 row justify-content-center align-items-center">
              <div className="col-md-6 col-lg-6 col-xl-7 px-2 d-flex flex-column  justify-content-center align-items-center ">
                <img
                  id="img_tag"
                  src="image_svg/get_in_touch.png"
                  alt="get in touch"
                />
              </div>
              <div className="col-md-6 col-lg-6 col-xl-5 px-4 d-flex flex-column justify-content-center align-self-center height_tag">
                <div className="card_contact p-4">
                  <div class=" px-2 row g-3">
                    <div class="col-6">
                      <h4 for="firstname" className="form-label col_1">
                        First Name
                      </h4>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First name"
                        id="firstname"
                        value={this.state.firstname}
                        onBlur={this.handleBlur}
                        onChange={this.handleChange}
                      />
                      <h5 for="firstname" className="pt-1 col_3">
                        {this.state.validation.firstname}
                      </h5>
                    </div>
                    <div class="col-6">
                      <h4 for="lastname" className="form-label col_1">
                        Last Name
                      </h4>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last name"
                        id="lastname"
                        value={this.state.lastname}
                        onBlur={this.handleBlur}
                        onChange={this.handleChange}
                      />
                      <h5 for="firstname" className="pt-1 col_3">
                        {this.state.validation.lastname}
                      </h5>
                    </div>
                    <div class="col-12">
                      <h4 for="email_id" className="form-label col_1">
                        Email Address
                      </h4>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="abd@domain.com"
                        id="email_id"
                        value={this.state.email_id}
                        onBlur={this.handleBlur}
                        onChange={this.handleChange}
                      />
                      <h5 for="firstname" className="pt-1 col_3">
                        {this.state.validation.email_id}
                      </h5>
                    </div>
                    <div class="col-12">
                      <h4 for="jobId" className="form-label col_1">
                        Order Number
                      </h4>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=" Optional "
                        id="jobId"
                        value={this.state.jobId}
                        onBlur={this.handleBlur}
                        onChange={this.handleChange}
                      />
                      <h5 for="firstname" className="pt-1 col_3">
                        {this.state.validation.jobId}
                      </h5>
                    </div>
                    <div class="form-floating">
                      <div className="d-flex flex-row justify-content-between align-items-center">
                        <h4 for="shortDescription" className="form-label col_1">
                          Comments
                        </h4>
                        <h4 className="col_1">
                          {this.state.comments.length} | 500
                        </h4>
                      </div>
                      <textarea
                        className=" w-100 px-3 py-1"
                        placeholder="Maximum 500 characters"
                        id="comments"
                        style={{ height: "10rem" }}
                        value={this.state.comments}
                        onBlur={this.handleBlur}
                        onChange={this.handleChange}
                      ></textarea>
                      <h5 for="firstname" className="pt-1 col_3">
                        {this.state.validation.comments}
                      </h5>
                    </div>
                    <div class="d-grid gap-2 ">
                      <h3
                        className="btn btn-warning btn shadow py-2 w-100"
                        onClick={this.handleSubmit}
                      >
                        Submit
                      </h3>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" col-md-12 d-flex flex-row justify-content-end pt-3">
                <a
                  className="col_2 sub_title px-3"
                  href="https://www.facebook.com/people/QuickAssistuk/100064909273893/"
                  target={"_blank"}
                >
                  <div className=" pr-4 bi bi-facebook col_4 header_1"></div>
                </a>

                <a
                  className="col_2 px-3"
                  href="https://www.linkedin.com/company/quickassistuk/about/?viewAsMember=true"
                  target={"_blank"}
                >
                  <div className=" pr-4 bi bi-linkedin col_4 header_1 "></div>
                </a>
                <a
                  className="col_2 px-3"
                  href="https://www.instagram.com/quickassist.uk/"
                  target={"_blank"}
                >
                  <div className=" pr-4 bi bi-instagram  col_4 header_1"></div>
                </a>
                <a
                  className="col_2 px-3 "
                  href="mailto:info@quickassist.uk?subject = Feedback&body = Message"
                >
                  <div className=" pr-4 bi bi-envelope-fill col_4 header_1"></div>
                </a>
              </div>
            </div>
          </div>

          {this.state.modalShow ? (
            <ResponseModal
              handleModal={this.handleModal}
              modalShow={this.state.modalShow}
              loader={this.state.loader}
              message={this.state.message}
              title={this.state.title}
            />
          ) : null}
        </div>
      </Fragment>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(GetInTouch);
