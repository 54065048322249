import React, { useState } from "react";

export const ServiceCard = (props) => {
  const [iconMode, setIconMode] = useState(false);
  const handleMouseOver = () => {
    setIconMode(!iconMode);
  };
  const handleRedirect = (url) => {
    window.location.href = url;
  };
  return (
    <div
      key={props.data.serviceCode}
      className={props.device == "desktop" ? "col-6" : "col-12 py-2 mx-1"}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOver}
      onClick={() => handleRedirect(`/service?id=${props.data.serviceCode}`)}
    >
      <div
        className={` d-flex flex-${
          props.device == "desktop" ? "row" : "column"
        }  border-top rounded-top rounded-bottom`}
      >
        <div
          className={
            iconMode ? "service_card_img p-2" : "service_card_img_hover p-2"
          }
        >
          <img
            src={iconMode ? props.data.image_url : props.data.logo_url}
            alt={props.data.title}
            className="card_blog_img_tag "
          />
        </div>
        <div className="service_card_body p-4">
          <div className="col_3 text-start fw-bold">{props.data.title}</div>
          <div className="col_1  text-start py-2 header_4">
            {props.data.shortDescription}
          </div>
        </div>
      </div>
    </div>
  );
};
