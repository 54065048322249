import React, { Component, Fragment } from "react";
import axios from "axios";

// Redux
import { connect } from "react-redux";
import { setLandingPageHeader } from "../../redux/store";

const mapDispatchToProps = (dispatch) => {
  return {
    setLandingPageHeader: (value) => {
      dispatch(setLandingPageHeader(value));
    },
  };
};

class CustomerReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      comments: [],
    };
  }

  handleNext = () => {
    if (this.state.count >= this.state.comments.length - 1) {
      this.setState({ count: 0 });
    } else {
      this.setState({ count: this.state.count + 1 });
    }
  };

  handlePrev = () => {
    if (this.state.count <= 0) {
      this.setState({ count: this.state.comments.length - 1 });
    } else {
      this.setState({ count: this.state.count - 1 });
    }
  };

  handlerDuplicatesByKey = (array, key) => {
    const unique = [];
    const seen = new Set();

    for (const obj of array) {
      const value = obj[key];

      if (!seen.has(value)) {
        seen.add(value);
        unique.push(obj);
      }
    }
    return unique;
  };

  componentDidMount() {
    axios
      .get("https://qa-uk-api.herokuapp.com/quick_assist/order/feedback")
      .then((response) => {
        this.setState({ comments: response.data.feedback }, () => {
          let unique = this.handlerDuplicatesByKey(this.state.comments, "name");
          this.setState({ comments: unique });
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  render() {
    return (
      <Fragment>
        {this.state.comments.length === 0 ? null : (
          <div
            className=" row justify-content-start px-4 mx-0 bg-white "
            onMouseEnter={() => this.props.setLandingPageHeader(false)}
          >
            {/* Heading and Sub heading */}

            <div className="col-md-12 col-lg-12 col-xl-12 px-4 mb-4 pb-4 mx-auto text-center captions">
              <div className="col_4 header_1">
                What Our Customers Say About Us
              </div>
              <div className="mb-4 col_2  header_3">
                Don't just take our word for it!
              </div>
            </div>
            {/* Content */}
            <div className=" col-sm-12 col-md-12 col-lg-6 col-xl-5 my-4 pb-4 mx-auto d-flex flex-column justify-content-between align-items-start">
              <div className="col_4 py-2 header_1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="73"
                  height="44"
                  fill="none"
                  viewBox="0 0 73 44"
                >
                  <path
                    fill="#FFD646"
                    d="M65.604 22H54.293v-6.25c0-3.447 4.058-6.25 9.049-6.25h1.13c1.881 0 3.394-1.045 3.394-2.344V2.47c0-1.3-1.513-2.344-3.393-2.344h-1.131C50.843.125 40.72 7.117 40.72 15.75v23.438c0 2.587 3.04 4.687 6.786 4.687h18.098c3.747 0 6.787-2.1 6.787-4.688v-12.5C72.39 24.1 69.35 22 65.604 22zm-40.72 0h-11.31v-6.25c0-3.447 4.057-6.25 9.048-6.25h1.131c1.88 0 3.393-1.045 3.393-2.344V2.47c0-1.3-1.512-2.344-3.393-2.344h-1.13C10.122.125 0 7.117 0 15.75v23.438c0 2.587 3.04 4.687 6.787 4.687h18.097c3.747 0 6.787-2.1 6.787-4.688v-12.5C31.67 24.1 28.63 22 24.884 22z"
                  />
                </svg>
              </div>
              {this.state.comments.map((data, index) =>
                index === this.state.count ? (
                  <Fragment>
                    <div className="col_2 py-2 header_3">{data.comment}</div>
                    <div className="col_3 fw-bold header_2">{data.name}</div>
                  </Fragment>
                ) : null
              )}
            </div>
            <div className=" d-none d-sm-block col-md-6 col-lg-6 col-xl-6 px-4 d-flex flex-row justify-content-start">
              <img
                id="img_tag"
                src="image_svg/feedback.png"
                alt="get in touch"
              />
            </div>

            <div className="col-md-12 col-lg-12 col-xl-12 px-4 mb-4 pb-4 mx-auto text-center  d-flex flex-row justify-content-end">
              <div
                className="bi bi-arrow-left-square-fill col_2 header_1 "
                onClick={this.handlePrev}
              ></div>
              <div
                className="bi bi-arrow-right-square-fill px-2 col_2 header_1 "
                onClick={this.handleNext}
              ></div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default connect(null, mapDispatchToProps)(CustomerReview);
