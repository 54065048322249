export const DATA = [
  {
    id: 1,
    title: "Plumbing",
    serviceCode: "qa1",
    image_url: "/ServiceCardImg/Plumbing Icon.png",
    logo_url: "/ServiceCardImg/1.png",
    icon: "/ServiceCardImg/1a.png",
    shortDescription:
      "Our qualified plumbers can assist with your plumbing related issues, whether it be in the bathroom, kitchen or anywhere else",
    description:
      "Our plumbers can provide virtual support for many plumbing tasks and projects. If your job turns out to be too big, the Adviser will be able to advise you on next steps and when to call in a professional to complete the task.",
    category: [
      "Leaky taps",
      "Fault finding",
      "Bleeding radiators",
      "Pipes and pipework",
      "Unblocking sinks and drains",
      "Improving low water pressure",
      "Installing a bathroom suite",
      "Lagging pipes",
      "Fitting taps",
      "Boiler fault finding",
      "Other",
    ],
    charges: "One flat fee of £30 for a 30 minute call.",
    adviserType: "Plumber",
  },
  {
    id: 2,
    title: "Electrical",
    serviceCode: "qa2",
    image_url: "/ServiceCardImg/Electrical Icon.png",
    logo_url: "/ServiceCardImg/2.png",
    icon: "/ServiceCardImg/2a.png",
    shortDescription:
      "Our qualified electricians can assist with most of your minor electricity related issues",
    description:
      "Our qualified electricians can assist with most of your minor electrical issues. If the works are unsafe for you to complete yourself, our advisers will let you know what the best and safest course of action is.",
    category: [
      "Wiring plugs",
      "Changing lampshades",
      "Installing switches",
      "Installing lights",
      "Installing sockets ",
      "Other",
    ],
    charges: "One flat fee of £30 for a 30 minute call.",
    adviserType: "Electrician",
  },
  {
    id: 3,
    title: "General DIY",
    serviceCode: "qa3",
    image_url: "/ServiceCardImg/DIY Icon.png",
    logo_url: "/ServiceCardImg/3.png",
    icon: "/ServiceCardImg/3a.png",
    shortDescription:
      "Our professional handymen can provide advice on works around the home, as well as provide DIY tips and tricks",
    description:
      "Our qualified and experienced handymen can provide you with virtual videocall support and guide you through many home improvement tasks and activities",
    category: [
      "Laying flooring",
      "Hanging frames",
      "Wall mounting TVs",
      "Filling and plastering",
      "Painting and decorating",
      "Hanging wallpaper",
      "Hanging curtains and blinds",
      "Erecting a shed",
      "Tiling",
      "Laying carpets",
      "Shelving",
      "Other",
    ],
    charges: "One flat fee of £30 for a 30 minute call.",
    adviserType: "Handyman",
  },
  {
    id: 4,
    title: "Building & Fitting Furniture",
    serviceCode: "qa4",
    image_url: "/ServiceCardImg/Building & Fitting Furniture.png",
    logo_url: "/ServiceCardImg/4.png",
    icon: "/ServiceCardImg/4a.png",
    shortDescription:
      "Our experts can provide support when you are building and fitting your furniture",
    description:
      "Our experts can provide support when you are building and fitting your furniture. No matter where in the process you are, our experts can guide you on completing the build yourself.",
    category: [
      "Shelves",
      "Drawers",
      "Desks",
      "Beds & other bedroom furniture",
      "Any other furniture",
    ],

    charges: "One flat fee of £30 for a 30 minute call.",
    adviserType: "Handyman",
  },
  {
    id: 5,
    title: "Installation Support",
    serviceCode: "qa5",
    image_url: "/ServiceCardImg/Installation Support Icon.png",
    logo_url: "/ServiceCardImg/5.png",
    icon: "/ServiceCardImg/5a.png",
    shortDescription:
      "Our experts can provide support if you are undertaking a bigger project and need some additional support via video call",
    description:
      "Our experts can provide support if you are undertaking an installation project and need some additional support via video call.",
    category: [
      "Fitting kitchen cabinets",
      "Bathroom refits",
      "Erecting a garden shed",
      "Any other larger projects",
    ],
    charges: "One flat fee of £30 for a 30 minute call.",
    adviserType: "Handyman",
  },
  {
    id: 6,
    title: "Quote Checking & Independent Advice",
    serviceCode: "qa6",
    image_url: "/ServiceCardImg/Quote Checking & Independent Advice.png",
    logo_url: "/ServiceCardImg/6.png",
    icon: "/ServiceCardImg/6a.png",
    shortDescription:
      "Our experts can provide you with peace of mind on any bigger works you are doing",
    description:
      "Our experts can provide you with peace of mind on any bigger works you are doing.\nUpload any quotes or workplace, explain what your concerns are and our expert will review the request ahead of the call to provide you with the tailored independent advice you require. ",
    category: [
      "Where do I start",
      "What should I look out for",
      "Why are my quotes so different",
      "How long should the job take",
      "Any other concerns",
    ],
    charges: "One flat fee of £30 for a 30 minute call.",
    adviserType: "Handyman",
  },
];
