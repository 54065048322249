import React, { Component, Fragment } from "react";
import { useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import moment from "moment";
import axios from "axios";

function withRouter(Children) {
  return (props) => {
    let { search } = useLocation();
    let query = new URLSearchParams(search);
    let key = query.get("id");
    let userId = query.get("user");

    return <Children {...props} id={key} userId={userId} />;
  };
}

class OrderCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      docId: "",
      image_url: [],
      userId: "",
      terms: false,
      modalShow: false,
      loader: false,
      error: false,
      title: "",
      message: "",
      orderNumber: "",
      jobcode: "",
      confirmJobCode: "",
      jobDecline: "",
      cancelOrderStatus: false,
      jobTitle: "",
      problem: "",
      jobDescription: "",
      date: "",
      time: "",
      timestamp: null,
      acceptedTime: "",
      acceptedTimeStamp: null,
      adviserStatus: false,
      images: "",
      status: "",
      adviserId: "",
      timeCount: -1,
      imageCount: 0,
      moment: null,
    };
  }
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value.trim() });
  };

  handleSelect = (code, time) => {
    this.setState({
      time: `${moment(this.state.moment.format())
        .add(time, "minutes")
        .format("HH:mm")}`,
      acceptedTime: `${moment(this.state.moment.format())
        .add(time, "minutes")
        .format("HH:mm")}`,
      acceptedTimeStamp: moment(this.state.moment.format())
        .add(time, "minutes")
        .valueOf(),
      timeCount: code,
    });
  };

  handleDecline = () => {
    if (window.confirm("Are you sure you want to Decline?") === true) {
      this.setState({ loader: true, modalShow: true });
      axios
        .post(
          "https://qa-uk-api.herokuapp.com/quick_assist/order/reject",
          {
            key: this.props.id,
            userId: this.props.userId,
            requestType: "decline",
          },
          {
            headers: {
              Authorization: `Basic ${this.props.API_KEY}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              loader: false,
              modalShow: false,
              jobDecline: response.data.message,
            });
          }
        })
        .catch((err) => {
          this.setState({
            loader: false,
            error: true,
            title: "Error",
            message: err.response.data.message,
          });
        });
    } else {
      return false;
    }
  };

  handleAcceptSubmit = () => {
    if (
      this.state.terms === false ||
      this.state.code === "" ||
      this.state.acceptedTime === ""
    ) {
      this.setState({
        error: true,
        title: "Error",
        message:
          "Please select time from the preferred time slots, fill the Acceptance code and accept the Cancellation policy",
        modalShow: true,
      });
      return;
    }
    this.setState({ loader: true, modalShow: true });
    axios
      .post(
        "https://qa-uk-api.herokuapp.com/quick_assist/order/accept",
        {
          code: this.state.code.toLowerCase(),
          key: this.props.id,
          userId: this.props.userId,
          acceptedTime: this.state.acceptedTime,
          acceptedTimeStamp: this.state.acceptedTimeStamp,
        },
        {
          headers: {
            Authorization: `Basic ${this.props.API_KEY}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState(
            {
              jobcode: response.data.jobcode,
              orderNumber: response.data.orderId,
            },
            () => {
              this.setState({ loader: false, modalShow: false });
            }
          );
        }
      })
      .catch((err) => {
        this.setState({
          loader: false,
          error: true,
          title: "Error",
          message: err.response.data.message,
        });
      });
  };

  handleCancelOrderModal = () => {
    this.setState({ cancelOrderStatus: true, modalShow: true });
  };

  handleCancelOrder = () => {
    this.setState(
      {
        loader: true,
        cancelOrderStatus: false,
      },
      () => {
        axios
          .post(
            "https://qa-uk-api.herokuapp.com/quick_assist/order/reject",
            {
              key: this.props.id,
              jobCode: this.state.confirmJobCode.toLowerCase(),
              requestType: "cancel",
            },
            {
              headers: {
                Authorization: `Basic ${this.props.API_KEY}`,
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              this.setState({
                loader: false,
                modalShow: false,
                jobDecline: response.data.message,
              });
            }
          })
          .catch((err) => {
            this.setState({
              loader: false,
              error: true,
              title: "Error",
              message: err.response.data.message,
            });
          });
      }
    );
  };

  handleModal = () => {
    this.setState({ modalShow: !this.state.modalShow }, () => {
      if (!this.state.modalShow) {
        this.setState({
          error: false,
          title: "",
          message: " ",
        });
      }
    });
  };

  handleImageCount = (type) => {
    if (type === "next") {
      if (this.state.imageCount >= 0 && this.state.imageCount < 3) {
        this.setState({ imageCount: this.state.imageCount + 1 });
      } else if (this.state.imageCount === 3) {
        this.setState({ imageCount: 0 });
      }
    } else if (type === "previous") {
      if (this.state.imageCount > 0 && this.state.imageCount <= 3) {
        this.setState({ imageCount: this.state.imageCount - 1 });
      } else if (this.state.imageCount === 0) {
        this.setState({ imageCount: 3 });
      }
    }
  };

  componentDidMount() {
    this.setState({ loader: true, modalShow: true });
    axios
      .post(
        "https://qa-uk-api.herokuapp.com/quick_assist/order/orderDetails",
        { key: this.props.id, userId: this.props.userId },
        {
          headers: {
            Authorization: `Basic ${this.props.id}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState(
            {
              jobTitle: response.data.jobTitle,
              problem: response.data.problem,
              jobDescription: response.data.jobDescription,
              date: response.data.date,
              time: response.data.adviserStatus
                ? response.data.acceptedtime
                : response.data.time,
              acceptedTime: response.data.acceptedtime,
              timestamp: response.data.timestamp,
              moment: moment(response.data.timestamp),
              images: response.data.imageUrl,
              status: response.data.status,
              adviserId: response.data.adviserId,
              adviserStatus: response.data.adviserStatus,
              jobcode: response.data.jobcode,
              orderNumber: response.data.orderId,
              image_url: response.data.images,
            },
            () => {
              this.setState({ loader: false, modalShow: false });
            }
          );
        }
      })
      .catch((err) => {
        this.setState({
          loader: false,
          error: true,
          title: "Error",
          message: err.response.data.message,
        });
      });
  }

  render() {
    return (
      <div className="order-card-approved ">
        <div className="justify-content-center mx-2 ">
          {this.state.jobDecline === "" && this.state.status === "open" ? (
            <Fragment>
              <div className="w-100 px-0 mx-0">
                <div className="col_3 text-start card p-2 modal_bg text-center mt-2">
                  <h3 className="col_3">Quick Assist</h3>
                </div>
                <div className="card p-3 my-2 modal_bg">
                  <h4 className="col_1 fw-bold ">{this.state.jobTitle}</h4>
                  <h3 className="col_1 ">{this.state.problem}</h3>
                  <h5 className="col_1 text-break">
                    {this.state.jobDescription}
                  </h5>
                </div>
                {this.state.image_url.length !== 0 ? (
                  <div className="card my-2 modal_bg">
                    <div
                      className="row mx-0 Inside_Wrapper upload_image d-flex flex-column justify-content-end align-item-center  p-0"
                      style={{
                        backgroundImage: `url(${
                          this.state.image_url[this.state.imageCount]
                        })`,
                        backgroundSize: "contain, cover",
                        minHeight: "60vh",
                      }}
                    >
                      {this.state.image_url.length === 0 ? null : (
                        <div className="d-flex flex-row justify-content-between align-item-center ">
                          <h2
                            class="bi bi-arrow-left-square-fill  "
                            onClick={() => this.handleImageCount("next")}
                          ></h2>
                          <h2
                            class="bi bi-arrow-right-square-fill   "
                            onClick={() => this.handleImageCount("previous")}
                          ></h2>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="card p-3 my-2 modal_bg">
                    <h3 className="col_3 ">
                      No Photographs uploaded by Customer
                    </h3>
                  </div>
                )}

                {this.state.orderNumber === "" && this.state.jobcode == "" ? (
                  <Fragment>
                    <div className="card p-3 my-2 modal_bg">
                      <div className="d-flex flex-row justify-content-between ">
                        <div>
                          <h5 className="col_1 pb-1 ">Appointment Date</h5>
                          <h4>{this.state.date}</h4>
                        </div>
                      </div>
                    </div>

                    {this.state.adviserStatus ? null : (
                      <div className="card p-3 my-2 modal_bg">
                        <h5 className="col_1"> Select the exact call time </h5>
                        <div className="d-flex flex-row justify-content-between">
                          {[0, 30, 60, 90].map((time, index) => (
                            <div
                              className={
                                this.state.timeCount === index
                                  ? "btn btn-warning"
                                  : "btn btn-outline-warning"
                              }
                              onClick={() => this.handleSelect(index, time)}
                            >
                              {moment(this.state.moment.format())
                                .add(time, "minutes")
                                .format("HH:mm")}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </Fragment>
                ) : (
                  <div className="card p-3 my-2 modal_bg">
                    <h4> Confirmed Appointment</h4>
                    <div className="d-flex flex-row justify-content-between pt-2 ">
                      <div>
                        <h5 className="col_1 ">Date</h5>
                        <h4 className="col_3">{this.state.date}</h4>
                      </div>
                      <div>
                        <h5 className="col_1  text-end">Time</h5>
                        <h4 className="col_3">{this.state.acceptedTime}</h4>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.orderNumber !== "" && this.state.jobcode !== "" ? (
                  <Fragment>
                    <div className="card p-3 my-2 modal_bg">
                      <div className="d-flex flex-row justify-content-between align-items-center">
                        <div>
                          <h5 className="col_1 ">Order Number</h5>
                          <h3 className="text-uppercase font-monospace col_3">
                            {this.state.orderNumber.toUpperCase()}
                          </h3>
                        </div>
                        <div>
                          <h5 className="col_1 text-end">Job Code</h5>
                          <h3 className="text-uppercase font-monospace col_3 text-end">
                            {this.state.jobcode.toUpperCase()}
                          </h3>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between ">
                      <div
                        className="col_2 pointer"
                        onClick={this.handleCancelOrderModal}
                      >
                        Cancel
                      </div>
                      <a
                        target={"_blank"}
                        href={`https://www.quickassist.uk/adviser?id=${this.state.orderNumber}&jobcode=${this.state.jobcode}`}
                        className="btn btn-warning"
                      >
                        Login to proceed to call
                      </a>
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className="card p-3 my-2 modal_bg ">
                      <h5 className="col_1 ">Acceptance Code</h5>
                      <input
                        type="text"
                        className="form-control"
                        id="code"
                        value={this.state.code}
                        onChange={this.handleChange}
                        placeholder="0000"
                      />
                      <h5 className="col_3 pt-2 ">
                        Can be found in your job notification text message or
                        email received
                      </h5>
                    </div>
                    <div className="card p-3 my-2 modal_bg ">
                      <div className="form-check py-2 d-flex flex-row justified-content-between align-items-start">
                        <input
                          className="form-check-input px-2"
                          type="checkbox"
                          id="terms"
                          onClick={() => {
                            this.setState({ terms: !this.state.terms });
                          }}
                        />
                        <h4 className="form-check-label col_1 px-2 ">
                          I have read and agree the
                          <a
                            target={"_blank"}
                            href="/adviserTerms"
                            className="col_3 px-1 pointer"
                            style={{ display: "inline" }}
                          >
                            Cancellation Policy
                          </a>
                        </h4>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <h4
                        className="btn btn-warning "
                        onClick={this.handleDecline}
                      >
                        Decline
                      </h4>
                      <h4
                        className="btn btn-warning "
                        onClick={this.handleAcceptSubmit}
                      >
                        Accept
                      </h4>
                    </div>
                  </Fragment>
                )}
              </div>
            </Fragment>
          ) : this.state.jobDecline !== "" && this.state.status === "open" ? (
            <Fragment>
              <div className="w-100 px-0 mx-0">
                <div className="col_3 text-start card p-2 modal_bg text-center">
                  <h3 className="col_3">Quick Assist</h3>
                </div>
                <div className="col_3 text-start card p-2 my-2 modal_bg text-center">
                  <div>{this.state.jobDecline.toUpperCase()}</div>
                </div>
              </div>
            </Fragment>
          ) : this.state.status !== "open" ||
            this.props.userId !== this.state.adviserId ? (
            <Fragment>
              <div className="w-100 px-0 mx-0">
                <div className="col_3 text-start card p-2 modal_bg text-center">
                  <h3 className="col_3">Quick Assist</h3>
                </div>
                <div className="col_1 p-3 my-2 card modal_bg text-center">
                  <div className="col_1 ">Status</div>
                  <div className="col_1 ">Job Expired</div>
                </div>
              </div>
            </Fragment>
          ) : null}
        </div>

        <Modal
          show={this.state.modalShow}
          onHide={!this.state.loader ? this.handleModal : null}
          centered
        >
          {this.state.loader ? (
            <div className="d-flex flex-column justified-content-center align-items-center p-4 modal_bg">
              <div className="spinner-border text-warning p-4" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : this.state.error ? (
            <Fragment>
              <Modal.Header closeButton>
                <Modal.Title> {this.state.title}</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <h4>{this.state.message}</h4>
              </Modal.Body>
            </Fragment>
          ) : this.state.cancelOrderStatus ? (
            <Fragment>
              <div className="mx-2 p-2">Cancel Order</div>
              <div className="mx-2 p-2">
                <input
                  type="text"
                  className="form-control input_style col bg-light w-100"
                  id="confirmJobCode"
                  value={this.state.confirmJobCode}
                  onChange={this.handleChange}
                />
              </div>
              <h5 className="mx-2 py-1">Please Enter the Job Code</h5>
              <div className="d-flex flex-row justify-content-end  py-1 mx-2">
                <div></div>
                <div
                  className="btn btn-warning w-25 my-1"
                  onClick={this.handleCancelOrder}
                >
                  Submit
                </div>
              </div>
            </Fragment>
          ) : null}
        </Modal>
      </div>
    );
  }
}

export default withRouter(OrderCard);
