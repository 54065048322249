export const VALIDATION_EMAIL = (e) => {
  let filter =
    /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
  return String(e).search(filter) != -1;
};

export const VALIDATION_TEXT = (e) => {
  let filter = /^[a-zA-Z ]*$/;
  return String(e).search(filter) != -1;
};

export const VALIDATION_NUMBER = (e) => {
  if (isNaN(e)) {
    return true;
  } else if (e.length < 9 || e.length > 11) {
    return true;
  }
};

export const VALIDATION_POSTALCODE = (p) => {
  // var postcodeRegEx = /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/;
  return false;
};
