import React, { Component, Fragment } from "react";
import { Link, useLocation } from "react-router-dom";

// Data
import { FAQ_DATA } from "./data";

function withRouter(Children) {
  return (props) => {
    let { pathname } = useLocation();
    let location = pathname.split("/")[1];
    return <Children {...props} location={location} />;
  };
}

class Faqs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tag_Id: 1,
      faq_data:
        this.props.location === "adviser" ||
        this.props.location === "newapplicant"
          ? FAQ_DATA[0]
          : FAQ_DATA[1],
    };
  }

  handleTagClick = (id) => {
    this.setState({ tag_Id: id });
  };

  componentDidMount() {
    this.setState({
      faq_data:
        this.props.location === "adviser" ||
        this.props.location === "newapplicant"
          ? FAQ_DATA[0]
          : FAQ_DATA[1],
    });
  }

  render() {
    return (
      <Fragment>
        <div className=" row justify-content-center align-items-start px-4 mx-0 FAQ_bg ">
          {/* Heading and Sub heading */}
          <div className="col-md-12 col-lg-9 col-xl-8 px-4 mb-4 pb-4 mx-auto text-center captions">
            <div className="col_4 header_1"> Frequently Ask Questions</div>
            <div className="col_2 header_3">
              Everything you need to know about our service
            </div>
          </div>
          {/*Content */}
          <div className=" d-none d-sm-block col-md-6 col-lg-6 col-xl-7 mb-4 px-4 d-flex flex-row justify-content-center  align-items-center">
            <img id="img_tag" src="image_svg/faqs.png" alt="get in touch" />
          </div>

          {/*Desktop */}
          <div className="d-none d-sm-block col-md-6 col-lg-6 col-xl-5 mb-4 mx-0 px-0 d-flex flex-row justify-content-center ">
            <div className="row mx-0 px-0 no-gutter ">
              {this.state.faq_data === {} ? null : (
                <Fragment>
                  {this.state.faq_data.contents.map((content, index) =>
                    index > 4 ? null : (
                      <div className="col-12 mx-0 row align-self-center py-3 px-3 border-white shadow">
                        <div
                          className=" col-12 px-0 mx-0 col_4 fw-bold  pointer header_3"
                          onClick={() => this.handleTagClick(index + 1)}
                        >
                          {content.title}
                        </div>

                        {this.state.tag_Id === index + 1 ? (
                          <div className="col-12 pl-2 col_2 pb-3">
                            {content.title === "How does it work?" ? (
                              <div className="col_2 ">
                                Please see our{" "}
                                <a
                                  href={content.link}
                                  style={{ display: "inline" }}
                                >
                                  How it works’
                                </a>{" "}
                                page here
                              </div>
                            ) : (
                              content.body
                            )}
                          </div>
                        ) : null}
                      </div>
                    )
                  )}
                </Fragment>
              )}
              {this.props.location === "adviser" ||
              this.props.location === "newapplicant" ? (
                <p className="col-12 col_4 mx-0 px-0 py-2 justified-content-end">
                  <Link
                    to={`/faqAdviser`}
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                    }}
                  >
                    <div className="w-100 text-end">
                      Click Here for More FAQ’s
                    </div>
                  </Link>
                </p>
              ) : (
                <p className="col-12 col_4  px-4  py-2 justified-content-end">
                  <Link
                    to={`/faqConsumer`}
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                    }}
                  >
                    <div className="w-100 text-end">
                      Click Here for More FAQ’s
                    </div>
                  </Link>
                </p>
              )}
            </div>
          </div>
          {/*Mobile */}
          <div className="col-sm-12 px-0  mx-0 d-flex flex-row justify-content-center d-block d-sm-none">
            <div className="row mx-0 no-gutter">
              {this.state.faq_data === {} ? null : (
                <Fragment>
                  {this.state.faq_data.contents.map((content, index) =>
                    index > 4 ? null : (
                      <div className="col-12 row mx-0 align-self-center py-3  border-white shadow">
                        <div
                          className=" col-12 col_4 fw-bold  pointer header_3"
                          onClick={() => this.handleTagClick(index + 1)}
                        >
                          {content.title}
                        </div>

                        {this.state.tag_Id === index + 1 ? (
                          <div className="col-12 pl-2 col_2 pb-3">
                            {content.title === "How does it work?" ? (
                              <div className="col_2 ">
                                Please see our{" "}
                                <a
                                  href={content.link}
                                  style={{ display: "inline" }}
                                >
                                  How it works’
                                </a>{" "}
                                page here
                              </div>
                            ) : (
                              content.body
                            )}
                          </div>
                        ) : null}
                      </div>
                    )
                  )}
                </Fragment>
              )}
              {this.props.location === "adviser" ||
              this.props.location === "newapplicant" ? (
                <p className="col-12 col_4  px-4  py-2 justified-content-end">
                  <Link
                    to={`/faqAdviser`}
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                    }}
                  >
                    <div className="w-100 text-end">
                      Click Here for More FAQ’s
                    </div>
                  </Link>
                </p>
              ) : (
                <p className="col-12 col_4  px-4  py-2 justified-content-end">
                  <Link
                    to={`/faqConsumer`}
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                    }}
                  >
                    <div className="w-100 text-end">
                      Click Here for More FAQ’s
                    </div>
                  </Link>
                </p>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withRouter(Faqs);
