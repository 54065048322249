export const SERVICE_DATA = {
  type_1: {
    id: 1,
    title: "Plumber",
    serviceCode: "QA_1",
    description:
      "Our plumbers can provide virtual support for many plumbing tasks and projects. If your job turns out to be too big, the Adviser will be able to advise you on next steps and when to call in a professional to complete the task.",
    category: [
      "Leaky taps",
      "Fault finding",
      "Bleeding radiators",
      "Pipes and pipework",
      "Unblocking sinks and drains",
      "Improving low water pressure",
      "Installing a bathroom suite",
      "Lagging pipes",
      "Fitting taps",
      "Boiler fault finding",
    ],
    charges: "One flat fee of £30 for a 30 minute call.",
  },
  type_2: {
    id: 2,
    title: "Electrician",
    serviceCode: "QA_2",
    description:
      "Our qualified electricians can assist with most of your minor electrical issues. If the works are unsafe for you to complete yourself, our advisers will let you know what the best and safest course of action is.",
    category: [
      "Wiring plugs",
      "Changing lampshades",
      "Installing switches",
      "Installing lights",
      "Installing sockets ",
    ],
    charges: "One flat fee of £30 for a 30 minute call.",
  },
  type_3: {
    id: 3,
    title: "General DIY",
    serviceCode: "QA_3",
    description:
      "Our qualified and experienced handymen can provide you with virtual videocall support and guide you through many home improvement tasks and activities",
    category: [
      "Laying flooring",
      "Hanging frames",
      "Wall mounting TVs",
      "Filling and plastering",
      "Painting and decorating",
      "Hanging wallpaper",
      "Hanging curtains and blinds",
      "Erecting a shed",
      "Tiling",
      "Laying carpets",
      "Shelving",
    ],
    charges: "One flat fee of £30 for a 30 minute call.",
  },
  type_4: {
    id: 4,
    title: "Building & Fitting Furniture",
    serviceCode: "QA_4",
    description:
      "Our experts can provide support when you are building and fitting your furniture. No matter where in the process you are, our experts can guide you on completing the build yourself.",
    category: [
      "Laying flooring",
      "Hanging frames",
      "Wall mounting TVs",
      "Filling and plastering",
      "Painting and decorating",
      "Hanging wallpaper",
      "Hanging curtains and blinds",
      "Erecting a shed",
      "Tiling",
      "Laying carpets",
      "Shelving",
    ],
    charges: "One flat fee of £30 for a 30 minute call.",
  },
  type_5: {
    id: 5,
    title: "Installation Support",
    serviceCode: "QA_5",
    description:
      "Our experts can provide support if you are undertaking a bigger project and need some additional support via video call.",
    category: [
      "Laying flooring",
      "Hanging frames",
      "Wall mounting TVs",
      "Filling and plastering",
      "Painting and decorating",
      "Hanging wallpaper",
      "Hanging curtains and blinds",
      "Erecting a shed",
      "Tiling",
      "Laying carpets",
      "Shelving",
    ],
    charges: "One flat fee of £30 for a 30 minute call.",
  },
  type_6: {
    id: 6,
    title: "Quote Checking & Independent Advice",
    serviceCode: "QA_6",
    description:
      "Upload any quotes or workplace, explain what your concerns are and our expert will review the request ahead of the call to provide you with the tailored independent advice you require. ",
    category: [
      "Laying flooring",
      "Hanging frames",
      "Wall mounting TVs",
      "Filling and plastering",
      "Painting and decorating",
      "Hanging wallpaper",
      "Hanging curtains and blinds",
      "Erecting a shed",
      "Tiling",
      "Laying carpets",
      "Shelving",
    ],
    charges: "One flat fee of £30 for a 30 minute call.",
  },
};

export const FAQ_DATA = [
  {
    title: "adviser",
    contents: [
      {
        title: "What is Quick Assist for tradespeople?",
        body: "Quick Assist is an online platform which allows you to give live virtual help to customers enabling them to complete plumbing, electrical or DIY works themselves. Quick Assist can also be used to provide an unbiased opinion as to how much the works required from a professional tradesperson should cost.",
      },
      {
        title: "What are the benefits?",
        body: "Quick Assist provides tradespeople with the flexibility of earning an extra income when they want it from wherever they are. You simply choose to accept a job as it becomes available, select your 30-minute availability within a 2-hour time slot that the customer has given and the job is yours! As a tradesperson, you have the freedom to choose whether you use the service full-time, part-time or just a few hours ad-hoc basis when you’d like to earn some extra income. Best of all, because the advice is virtual you can do it all from the comfort from your own home.",
      },
      {
        title: "What happens if something goes wrong? ",
        body: "Whilst we aim to avoid any accidents or mistakes we understand that on occasion something could go wrong. Neither Quick Assist nor you as the tradesperson can be held liable for any actions the customer undertakes. As you are only providing advice and guidance and customers accept that they have liability in our disclaimer and terms and conditions before they book a job.",
      },
      {
        title: "How does it work?",
        body: "Please see our‘Tradesperson - How it works’page here. ",
        link: "https://www.quickassist.uk/HowItWorksAdviser",
      },
      {
        title: "What tools will a customer have?",
        body: "We have a list of tools which we recommend customers to have but this is just a recommendation and not a guarantee. Please note however that customers vary in their DIY skills and tools which they may have and it’s less likely that they will have specialist tools at hand.",
      },
      {
        title: "What advice will I be providing? ",
        body: "This will entirely depend on the nature of the job, but it will always be in line with your specialisation. The job can be either a ‘how to’ e.g. ‘How to change a tap?’ for a plumber, or an unbiased quote of what a job should cost e.g. ‘How much should I be paying to get a new bathroom?'",
      },
      {
        title: "What’s the minimum amount of time I can booked for?",
        body: "The Service is one flat fee of £30 for a 30 minute call.",
      },

      {
        title: "What happens if I cannot provide advice?",
        body: "Before accepting the job, you get customer job details & pictures of the issue, therefore you will be able to gauge if you are able to provide the advice or not. If you accept a call and are unable to provide the guidance and advice, you will not be paid. It will be unfair to charge the customer when you have not provided any advice or guidance.",
      },

      {
        title:
          "What happens if it is unsafe for the customer to do the DIY works?",
        body: "If the job is deemed bigger than what was described in the initial request, you will still get paid. If you are local to the customer and could complete the job yourself, you can let the customer know this and explain the cost of doing so. We recommend that you deduct the Quick Assist consultation fee off from your callout charge, as we feel it is unfair to charge customers twice when the initial Quick Assist consultation did not resolve their problem.",
      },

      {
        title: "How often will I get paid?",
        body: "Every week for the two prior weeks of work.",
      },
      {
        title: "How much do I get paid?",
        body: "For every 30-minute consultation you do, you get paid £15. This fee may however vary in the event of disputes with customers.",
      },
      {
        title: "How long does it take to get verified?",
        body: "We aim to get every tradesperson verified within a week, however depending on the number of tradespeople signing up to the platform can affect how quickly we can get tradespeople verified.",
      },
      {
        title: "How do I sign-up and get verified?",
        body: "Follow our simple registration process here. We require the usual details such as name, address, trade, specialisations and upload any qualifications you have. Once we’ve certified your profile, we’ll have a video call with you to ask some basic questions and discuss your specialisation. After verification is completed, you will be able to start earning income with Quick Assist.",
        link: "https://www.quickassist.uk/newapplicant",
      },
    ],
  },
  {
    title: "consumer",
    contents: [
      {
        title: "What is Quick Assist?",
        body: "Quick Assist is an online platform which allows you to get live & tailored virtual help from qualified professionals enabling you to complete plumbing, electrical or general DIY works yourself, which under normal circumstances may require some professional guidance. Quick Assist can also be used to get an unbiased opinion as to how much the works required from a professional tradesperson should cost. From experience, we have found that the difference in quotes for the same job can be several thousand pounds. Using Quick Assist, you pay a modest nominal fee to potentially save thousands.",
      },
      {
        title: "What are the benefits?",
        body: "Quick assist saves you time, money and removes the often-encountered anxiety that comes from having to call random tradespeople. You can get the help and support exactly when you need it, no more time wasted waiting for tradespeople that never turn up. Then there is the cost side. You will only pay a fraction of the price that would be charged from a tradesperson to carry out the work by doing it yourself with the help and professional guidance from an experienced professional via our online service. The experience gained will also help you to deal with problems in the future. Being a live support service also means that no one has to come into your home, keeping you and your home safe. However, if you have a call with a local professional and the problem requires them to come to your home, this is also a possibility.",
      },
      {
        title: "What happens if the call goes on for longer than 30 minutes?",
        body: "It is at the tradesperson’s discretion to end the call as after 30 minutes as they won’t earn any additional income. We do, however, recommend that the tradespeople allows a call to run for an extra couple of minutes as it can be difficult to finish at the allotted time.",
      },
      {
        title: "What tools do I need?",
        body: "This is dependent on the job, but standard tools are almost always helpful such as a drill, screwdriver, screws, hammer, pliers, an adjustable wrench, a tape measure and a spirit level.",
      },
      {
        title: "Are the tradeperson's providing advice qualified?",
        body: "Yes! We go through a rigorous vetting process to ensure that each tradesperson is qualified to do the job and that they can provide easy to follow, step-by-step guidance that is clear and succinct.",
      },
      {
        title: "What is the vetting process? ",
        body: "In order to become an Adviser on the Quick Assist platform, all tradespeople perform virtual interviews with Quick Assist, run through a mock support video call, provide proof of qualifications as well as references to ensure they are qualified and suitable to provide virtual support.",
      },
      {
        title: "What’s the minimum amount of time I can book someone for? ",
        body: "The service is one flat fee of £30 for a 30-minute call.",
      },

      {
        title: "How does it work?",
        body: "Please see our ‘How it works’ page here",
        link: "https://www.quickassist.uk/HowItWorksCustomer",
      },

      {
        title:
          "What happens if the tradesperson advises that the DIY job is unsafe to do?",
        body: "You will still have to pay for the tradesperson as you are still using their time; we do however appreciate that your problem isn’t resolved. Please bear in mind that if the tradesperson is advising that a job is unsafe then consider hiring a qualified tradesperson to come in to do the job.",
      },
      {
        title: "What happens if something goes wrong?",
        body: "Whilst we aim to avoid any accidents or mistakes, we understand that on occasion something could go wrong. Neither Quick Assist nor our tradespeople can be held liable for any actions the customer undertakes. Our tradespeople can only provide guidance and if it’s a job which is, in their professional opinion, deemed to be complex, unsafe or you do not feel comfortable doing it yourself then we recommend a qualified tradesperson to be hired to do the works for you.",
      },
      {
        title:
          "Can I use Quick Assist to get an unbiased quote for building works?",
        body: "Absolutely. This is one of the reasons to use Quick Assist. ",
      },
      {
        title: "What if I have an emergency? ",
        body: "Quick Assist can be used to get quick access to a qualified tradesperson to help stop an issue, however, depending on the severity of the emergency, you may require an in-person callout.",
      },
      {
        title: "Can I use the service for all of my DIY needs?",
        body: "Quick Assist is perfectly suited to provide professional guidance for your DIY needs.",
      },
      {
        title: "What happens if I am not happy with the service provided?",
        body: "Please contact us at info@quickassist.uk explaining in as much detail as possible why you were not satisfied with the service and we will aim to resolve the issue in a timely manner.",
      },
    ],
  },
];
