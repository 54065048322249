import React, { Component, Fragment } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Helmet } from "react-helmet";

// Stylesheet
import "./StyleSheet.Style.css";

// module
import Landing from "../Components/Common/Landing";
import WhyQuickAssist from "../Components/Common/WhyQuickAssist";
import GetInTouch from "../Components/Common/GetInTouch";
import NewsLetter from "../Components/Common/NewsLetter";
import CustomerReview from "../Components/Common/CustomerReview";
import Faqs from "../Components/Common/Faqs";
import HowItsWorks from "../Components/Common/HowItsWorks";
import OurServices from "../Components/Services/OurServices";
// import TrustPilot from "../Components/Common/TrustPilot";
import BlogContainer from "../Components/Blogs/BlogContainer";
import FooterBar from "../Components/Common/FooterBar";

//  Error Handling
import Fallback from "../Components/ErrorHandle/ErrorBoundary";

// Redux
import { connect } from "react-redux";
import { setLandingPageHeader, setPageCategory } from "../redux/store";

class Home extends Component {
  constructor(props) {
    super(props);
  }
  myErrorHandler = (error, errorInfo) => {
    console.log("logging", error, errorInfo);
  };

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  componentWillUnmount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  render() {
    return (
      <ErrorBoundary FallbackComponent={Fallback} onError={this.myErrorHandler}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Home</title>
          <meta
            name="description"
            content="Quick Assist is an online platform which provides quick and affordable access to tradespeople support via video-call"
          />
          <link rel="canonical" href="https://www.quickassist.uk/" />
        </Helmet>
        <div
          onContextMenu={(e) => e.preventDefault()}
          className="home_section_a row mx-0 px-sm-0 px-md-4 px-lg-4 px-xl-4 px-xxl-4"
          onMouseEnter={() => this.props.setLandingPageHeader(true)}
        >
          <Landing
            page="Home"
            title="Home Improvement Help at Your Fingertips"
            sub_title=" Get access to qualified tradespeople who can guide you
          through home improvement works virtually!"
          />
        </div>
        {/* Section-b : Why Quick Assist */}
        <Fragment onMouseEnter={() => this.props.setLandingPageHeader(false)}>
          <WhyQuickAssist />
          <OurServices />
          <HowItsWorks />
          <CustomerReview />
          {/* Intergrate Trust Piolot */}
          {/* <TrustPilot /> */}
          {/* <TrustPilotBadge /> */}
          <NewsLetter />
          <BlogContainer />
          <Faqs Category="Consumer" />
          <GetInTouch />
          <FooterBar page="home" />
        </Fragment>

        {/* </Suspense> */}
      </ErrorBoundary>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLandingPageHeader: (value) => {
      dispatch(setLandingPageHeader(value));
    },
    setPageCategory: (value) => {
      dispatch(setPageCategory(value));
    },
  };
};

export default connect(null, mapDispatchToProps)(Home);
