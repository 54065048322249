import React from "react";

const BlogCard = ({ data, size, handleRedirect }) => {
  return (
    <div className={`col-${size} d-flex flex-column`}>
      <div
        id={data.id}
        className="card_blog border-top rounded-top rounded-bottom "
        onClick={() => handleRedirect(data.id)}
      >
        <div className="card_blog_title p-4 ">
          <div className="col_4 text-start fw-bold">{data.title}</div>
          <div className="col_5 text-start pt-1 header_5">
            Posted on {data.date}
          </div>
        </div>
        <div className="card_blog_img ">
          <img
            src={data.imgurl}
            alt={data.title}
            className="card_blog_img_tag "
          />
        </div>
        <div className="card_blog_body p-4 ">
          <div className="col_1 py-2">{data.summary}</div>
          <div className="col_3 text-bold fw-bold header_5">
            Read full article here
          </div>
        </div>
      </div>
    </div>
  );
};
export default BlogCard;
