import React, { Component, Fragment } from "react";
import { motion } from "framer-motion/dist/framer-motion";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Redux
import { connect } from "react-redux";
import { setLandingPageHeader } from "../../redux/store";

// import the service data
import { DATA } from "./data";
import { ServiceCard } from "./ServiceCard";
const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  rtl: true,
};

class OurServices extends Component {
  render() {
    return (
      <Fragment>
        {/* Desktop */}
        <motion.div className=" row justify-content-center px-4 mx-0 py-4  bg-white d-none d-sm-block">
          {/* Heading and Sub heading */}
          <div className="col-md-12 col-lg-8 col-xl-8 px-4 mx-auto text-center captions mb-4 ">
            <div className="col_4 header_1">How Quick Assist Can Help?</div>
            <div className="col_2 header_3">
              From leaky taps and blocked drains, to laying flooring, hanging
              wallpaper to simple independent advice - our trusted and qualified
              advisers are here to help
            </div>
          </div>

          <div className="col-12 p-4 my-4 text-center">
            <div className="row g-4 ">
              {DATA.map((items) => {
                return (
                  <ServiceCard
                    data={items}
                    device="desktop"
                    handleCard={this.handleChange}
                  />
                );
              })}
            </div>
          </div>
        </motion.div>
        {/* Mobile */}
        <motion.div className=" row justify-content-center mx-0 py-4 bg-white d-block d-sm-none">
          {/* Heading and Sub heading */}
          <div className="col-md-12 col-lg-8 col-xl-8 px-4 mx-auto text-center captions mb-4 ">
            <div className="col_4 header_1">How Quick Assist Can Help?</div>
            <div className="col_2 header_3">
              From leaky taps and blocked drains, to laying flooring, hanging
              wallpaper to simple independent advice - our trusted and qualified
              advisers are here to help
            </div>
          </div>

          <div className="col-12 mt-2 mb-4 pt-2 pb-4 row mx-0 justify-content-center ">
            <div className="row g-2">
              {/* <Slider {...settings}> */}
              {DATA.map((items) => {
                return (
                  <ServiceCard
                    data={items}
                    device="mobile"
                    handleCard={this.handleChange}
                  />
                );
              })}
              {/* </Slider> */}
            </div>
          </div>
        </motion.div>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLandingPageHeader: (value) => {
      dispatch(setLandingPageHeader(value));
    },
  };
};

export default connect(null, mapDispatchToProps)(OurServices);
