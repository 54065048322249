import React, { Component } from "react";

export default class PrivacyPolicy extends Component {
  render() {
    return (
      <div className="home_section_faq row mx-0 d-flex flex-column justified-content-center ">
        <div className="header_faq row mx-0 parallax_service">
          <div className="col-md-12 col-lg-9 col-xl-7 px-4 mb-4 pb-4 mx-auto text-center captions d-flex flex-column justified-content-center align-self-center">
            <h1 className="col_1"> Privacy Policy</h1>
            <h4 className="col_1">Last updated on 15th January 2023</h4>
          </div>
        </div>
        <div className="row bg-white mx-0">
          <div className="col-sm-12 col-lg-7 col-xl-7 px-4 mb-4 pb-4 mx-auto  captions d-flex flex-column justified-content-start align-self-start">
            <div>
              Thank you for choosing to be part of our community at LLMK Ltd.,
              doing business as Quick Assist (hereinafter referred to as "Quick
              Assist", "we", "us", "our''). We are committed to protecting your
              personal information and your right to privacy. If you have any
              questions or concerns about this privacy policy, or our practices
              with regards to your personal information, please contact us at
              info@quickassist.uk.
            </div>
            <div>
              When you visit our website http://www.quickassist.uk (the
              "Website" or “Platform”), and more generally, use any of our
              services (the "Services", which include the Website), we
              appreciate that you are trusting us with your personal
              information. In this privacy policy we seek to explain to you in
              the clearest way possible what information we collect, how we use
              it and what rights you have in relation to it.
            </div>
            <div className="py-2">
              This privacy policy applies to all information collected through
              our Services (which, as described above, includes our Platform),
              as well as any related services, sales, marketing or events.
            </div>
            <ul>
              <li>What information do wecollect?</li>
              <li> How do we use your information?</li>
              <li>Will your information be shared with anyone?</li>
              <li> Do we use cookies and other tracking technologies?</li>
              <li> How long do we keep your information for?</li>
              <li> How do we keep your information safe? </li>
              <li> What are your privacy rights?</li>
              <li>Controls for do-not-track features</li>
              <li>Do we make updates to this policy?</li>
              <li> How can you contact us about this policy?</li>
            </ul>
            <div className="py-2">
              <h3 className="fw-bold col_2">
                {" "}
                What information do we collect?
              </h3>
              <div>
                In Short: We collect the personal information that you
                voluntarily provide to us.
              </div>
              <div>
                We collect the personal information that you voluntarily provide
                to us when you register on the Platform, express an interest in
                obtaining information about us or our services, when you
                participate in activities on the Platform (such as searching for
                tradespeople, leaving reviews) or otherwise when you contact us.
              </div>
              <div>
                The personal information that we collect depends on the context
                of your interactions with us and the Platform, the choices you
                make and the services you use. The personal information we
                collect may include the following:
              </div>

              <ul>
                <li>
                  Information provided by you, such as names; phone numbers;
                  email addresses; mailing addresses; job titles; usernames;
                  passwords; contact preferences; contact or authentication
                  data; billing addresses; debit/credit card numbers; and other
                  similar information.
                </li>
                <li>
                  Messages and videos sent and recorded on the Platform. We may
                  record any messages sent and received, and record video
                  conversations undertaken between Advisers and you. If this is
                  undertaken, it is to ensure quality of service provided by our
                  Advisers as well as in the event of disputes between you and
                  Advisers to allow us to resolve the dispute in a fair and
                  timely manner.
                </li>
                <li>
                  Payment Data. We may collect data necessary to process your
                  payment if you make purchases, such as your payment instrument
                  number (such as a credit card number), and the security code
                  associated with your payment instrument. All payment data is
                  stored by Stripe. You may find their privacy policy link(s)
                  here: https://stripe.com/en-gb/privacy
                </li>
              </ul>
              <div>
                All personal information that you provide to us must be true,
                complete and accurate, and you must notify us of any changes to
                such personal information.
              </div>
              <div>
                <strong>Information automatically collected</strong>{" "}
              </div>
              <div>
                In Short: Some information - such as your Internet Protocol (IP)
                address and/or browser and device characteristics - is collected
                automatically when you visit our Platform.
              </div>
              <div>
                We automatically collect certain information when you visit, use
                or navigate our Platform. This information does not reveal your
                specific identity (such as your name or contact information) but
                may include device and usage information, such as your IP
                address, browser and device characteristics, operating system,
                language preferences, referring URLs, device name, country,
                location, information about how and when you use our Platform
                and other technical information. This information is primarily
                needed to maintain the security and operation of our Platform,
                and for our internal analytics and reporting purposes.
              </div>
              <div>
                Like many businesses, we also collect information through
                cookies and similar technologies.
              </div>
              <div>The information we collect includes:</div>
              <ul>
                <li>
                  Log and Usage Data. Log and usage data is service-related,
                  diagnostic, usage and performance information our servers
                  automatically collect when you access or use our Platform and
                  which we record in log files. Depending on how you interact
                  with us, this log data may include your IP address, device
                  information, browser type and settings and information about
                  your activity on the Platform (such as the date/time stamps
                  associated with your usage, pages and files viewed, searches
                  and other actions you take such as which features you use),
                  device event information (such as system activity, error
                  reports and hardware settings).
                </li>
                <li>
                  Device Data. We collect device data such as information about
                  your computer, phone, tablet or other devices you use to
                  access the Platform in order to maintain device compatibility
                  with our platform. Depending on the device used, this device
                  data may include information such as your IP address (or proxy
                  server), device and application identification numbers,
                  location, browser type, hardware model Internet service
                  provider and/or mobile carrier, operating system and system
                  configuration information.
                </li>
                <li>
                  Location Data. We collect location data such as information
                  about your device's location. How much information we collect
                  depends on the type and settings of the device you use to
                  access the Platform. For example, we may use GPS and other
                  technologies to collect geolocation data that tells us your
                  current location (based on your IP address). You can opt out
                  of allowing us to collect this information either by refusing
                  access to the information or by disabling your Location
                  setting on your device. Note however if you choose to opt out,
                  you may not be able to use certain aspects of the Platform.
                </li>
              </ul>
            </div>
            <div className="py-2">
              <h3 className="fw-bold col_2">
                {" "}
                How do we use your information?
              </h3>
              <div>
                In Short: We process your information for purposes based on
                legitimate business interests, the fulfilment of our contract
                with you, compliance with our legal obligations, and/or your
                consent.
              </div>
              <div>
                We use personal information collected via our Platform for a
                variety of business purposes described below. We process your
                personal information for these purposes in reliance on our
                legitimate business interests, in order to enter or perform a
                contract with you, with your consent and/or for compliance with
                our legal obligations. We indicate the specific processing
                grounds we rely on next to each purpose listed below.
              </div>
              <div>We use the information we collect or receive:</div>
              <ul>
                <li>
                  To post testimonials. We post testimonials on our Platform
                  that may contain personal information. Prior to posting a
                  testimonial, we will obtain your consent to use your name and
                  the content of the testimonial. If you wish to update or
                  delete your testimonial, please contact us at
                  privacy@quickassist.uk and be sure to include your name,
                  testimonial location, and contact information. By posting a
                  testimonial you expressly consent Quick Assist to publicise
                  said testimonial in its entirety and without restriction.
                </li>
                <li>
                  Request feedback. We may use your information to request
                  feedback and to contact you about your use of our Platform.
                </li>
                <li>
                  To enable user-to-user communications. We may use your
                  information in order to enable user-trusted communications
                  with each user’s consent.
                </li>
                <li>
                  To manage user accounts. We may use your information for the
                  purposes of managing our account and keeping it in working
                  order.
                </li>
                <li>
                  To send administrative information to you. We may use your
                  personal information to send service and new feature
                  information and/or information about changes to our terms,
                  conditions, and policies.
                </li>
                <li>
                  To protect our Platform. We may use your information as part
                  of our efforts to keep our Platform safe and secure (for
                  example for fraud monitoring and prevention).
                </li>
                <li>
                  To enforce our terms, conditions and policies for business
                  purposes, to comply with legal and regulatory requirements or
                  in connection with our contract.
                </li>
                <li>
                  To respond to legal requests and prevent harm. If we receive a
                  subpoena or other legal request, we may need to inspect the
                  data we hold to determine how to respond.
                </li>
                <li>
                  To deliver and facilitate delivery of services to the user. We
                  may use your information to provide you with the requested
                  service.
                </li>
                <li>
                  To respond to user inquiries/offer support to users. We may
                  use your information to respond to your queries and solve any
                  potential issues you might have with the use of our Services.
                </li>
                <li>
                  To monitor the service provided by Advisers and in the event
                  of disputes, to allow us to make a fair and timely decision
                  with respect to any disputes.
                </li>
                <li>
                  To send you marketing and promotional communications. We
                  and/or our third-party marketing partners may use the personal
                  information you send to us for our marketing purposes if this
                  is in accordance with your marketing preferences. For example,
                  when expressing an interest in obtaining information about us
                  or our Platform, subscribing to marketing or otherwise
                  contacting us, we will collect personal information from you.
                  You can opt-out of our marketing emails at any time (see the
                  "What are your privacy rights?” below).
                </li>
                <li>
                  Deliver targeted advertising to you. We may use your
                  information to develop and display personalized content and
                  advertising (and work with third parties who do so) tailored
                  to your interests and/or location and to measure its
                  effectiveness.
                </li>
                <li>
                  For other business purposes. We may use your information for
                  other business purposes, such as data analysis, identifying
                  usage trends, determining the effectiveness of our promotional
                  campaigns and to evaluate and improve our Platform, products,
                  marketing and your experience. We may use and store this
                  information in aggregated and anonymized form so that it is
                  not associated with individual end users and does not include
                  personal information. We will not use identifiable personal
                  information without your consent.
                </li>
              </ul>
              <div className="py-2">
                <h3 className="fw-bold col_2">
                  Will your information be shared with anyone?
                </h3>
                <div>
                  In Short: We only share information with your consent, to
                  comply with laws, to provide you with services, to protect
                  your rights, or to fulfil business obligations.
                </div>
                <div>
                  We may process or share your data that we hold based on the
                  following legal basis:
                </div>
                <ul>
                  <li>
                    Consent: We may process your data if you have given us
                    specific consent to use your personal information for a
                    specific purpose.
                  </li>
                  <li>
                    Legitimate Interests: We may process your data when it is
                    reasonably necessary to achieve our legitimate business
                    interests.
                  </li>
                  <li>
                    Performance of a Contract: Where we have entered into a
                    contract with you, we may process your personal information
                    to fulfil the terms of such contract such as paying you for
                    your services.
                  </li>
                  <li>
                    Legal Obligations: We may disclose your information where we
                    are legally required to do so in order to comply with
                    applicable law, governmental requests, judicial proceedings,
                    court order, or other legal process, such as in response to
                    a court order (including in response to public authorities
                    to meet national security or law enforcement requirements).
                  </li>
                  <li>
                    Vital Interests: We may disclose your information where we
                    believe it is necessary to investigate, prevent, or take
                    action regarding potential violations of our policies,
                    suspected fraud, situations involving potential threats to
                    the safety of any person and illegal activities, or as
                    evidence in legal disputes in which we are involved.
                  </li>
                </ul>
                <div>
                  More specifically, we may need to process your data or share
                  your personal information in the following situations:
                </div>
                <ul>
                  <li>
                    Business Transfers. We may share or transfer your
                    information in connection with, or during negotiations of,
                    any merger, sale of company assets, financing, or
                    acquisition of all or a portion of our business to another
                    company.
                  </li>
                  <li>
                    Other users. When you share personal information (for
                    example, by posting comments, contributions or other content
                    to the Platform) or otherwise interact with public areas of
                    the Platform, such personal information may be viewed by all
                    users and may be publicly made available outside the
                    Platform in perpetuity. Similarly, depending on the nature
                    of your profile type other users may be able to view
                    descriptions of your activity, communicate with you within
                    our Platform, and view your profile.
                  </li>
                </ul>
              </div>
            </div>
            <div className="py-2">
              <h3 className="fw-bold col_2">
                Do we use cookies and other tracking technologies
              </h3>
              <div>
                In Short: We may use cookies and other tracking technologies to
                collect and store your information.
              </div>
              <div>
                We may use cookies and similar tracking technologies (like web
                beacons and pixels) to access or store information. Specific
                information about how we use such technologies and how you can
                refuse certain cookies is set out in our Cookie Notice.
              </div>
            </div>
            <div className="py-2">
              <h3 className="fw-bold col_2">
                How long do we keep your information for?
              </h3>
              <div>
                In Short: We keep your information for as long as necessary to
                fulfil the purposes outlined in this privacy policy unless
                otherwise required by law.
              </div>
              <div>
                We will only keep your personal information for as long as it is
                necessary for the purposes set out in this privacy policy,
                unless a longer retention period is required or permitted by law
                (such as tax, accounting or other legal requirements). No
                purpose in this policy will require us keeping your personal
                information for longer than the period of time in which users
                have an account with us.
              </div>
              <div>
                When we have no ongoing legitimate business need to process your
                personal information, we will either delete or anonymize such
                information, or, if this is not possible (for example, because
                your personal information has been stored in backup archives),
                then we will securely store your personal information and
                isolate it from any further processing until deletion is
                possible.
              </div>
            </div>
            <div className="py-2">
              <h3 className="fw-bold col_2">
                How do we keep your information safe?
              </h3>
              <div>
                In Short: We aim to protect your personal information through a
                system of organisational and technical security measures.
              </div>
              <div>
                We have implemented appropriate technical and organisational
                security measures designed to protect the security of any
                personal information we process. However, despite our safeguards
                and best efforts to secure your information, no electronic
                transmission over the Internet or information storage technology
                can be guaranteed to be 100% secure, so we cannot promise or
                guarantee that hackers, cybercriminals, or other unauthorized
                third parties will not be able to defeat our security, and
                improperly collect, access, steal, or modify your information.
                Although we will endeavour to use our efforts to protect your
                personal information, transmission of personal information to
                and from our Platform is at your own risk. You should only
                access the Platform from a secure environment and via an
                internet connection that you are confident to be trustworthy and
                secure.
              </div>
            </div>
            <div className="py-2">
              <h3 className="fw-bold col_2"> What are your privacy rights?</h3>
              <div>
                In Short: You may review, change, or terminate your account at
                any time.
              </div>{" "}
              <div>
                Under UK data protection law and General Data Protection
                Regulation, you have rights including:
              </div>{" "}
              <ul>
                <li>
                  {" "}
                  Your right of access - You have the right to ask us for copies
                  of your personal information.{" "}
                </li>
                <li>
                  Your right to rectification - You have the right to ask us to
                  rectify personal information you think is inaccurate. You also
                  have the right to ask us to complete information you think is
                  incomplete.{" "}
                </li>
                <li>
                  Your right to erasure - You have the right to ask us to erase
                  your personal information in certain circumstances.{" "}
                </li>
                <li>
                  Your right to restriction of processing - You have the right
                  to ask us to restrict the processing of your personal
                  information in certain circumstances.{" "}
                </li>
                <li>
                  Your right to object to processing - You have the right to
                  object to the processing of your personal information in
                  certain circumstances.
                </li>
                <li>
                  Your right to data portability - You have the right to ask
                  that we transfer the personal information you gave us to
                  another organisation, or to you, in certain circumstances.
                </li>
                <li>
                  You are not required to pay any charge for exercising your
                  rights. If you make a request, we have one month to respond to
                  you.
                </li>
              </ul>
              <div>
                Please contact us at info@quickassist.uk if you wish to make
                such a request.
              </div>{" "}
              <div></div>
              How to complain (Complaints Procedure)
            </div>{" "}
            <div>
              If you have any concerns about our use of your personal
              information, you can make a complaint to us at
              info@quickassist.uk. You can also complain to the ICO (Information
              Commissioner’s Office) if you are unhappy with how we have used
              your data.
            </div>{" "}
            <div>The ICO’s address is:</div>{" "}
            <div>
              Information Commissioner’s Office, Wycliffe House, Water Lane,
              Wilmslow, Cheshire, SK9 5AF
            </div>{" "}
            <div>Helpline number: 0303 123 1113 </div>{" "}
            <div> ICO website: https://www.ico.org.uk</div>{" "}
            <div>Account Information </div>
            <div>
              If you would at any time like to review or change the information
              in your account or terminate your account, you can log in to your
              account settings and update your user account.
            </div>
            <div>
              Upon your request to terminate your account, we will deactivate or
              delete your account and information from our active databases.
              However, we may retain some information in our files to prevent
              fraud, troubleshoot problems, assist with any investigations,
              enforce our Terms of Use and/or comply with applicable legal
              requirements.
            </div>
            <div>
              Cookies and similar technologies: Most Web browsers are set to
              accept cookies by default. If you prefer, you can usually choose
              to set your browser to remove cookies and to reject cookies. If
              you choose to remove cookies or reject cookies, this could affect
              certain features or services of our Platform.
            </div>
            <div>
              Opting out of email marketing: You can unsubscribe from our
              marketing email list at any time by clicking on the unsubscribe
              link in the emails that we send or by contacting us using the
              details provided below. You will then be removed from the
              marketing email list. However, we may still communicate with you,
              for example to send you service-related emails that are necessary
              for the administration and use of your account, to respond to
              service requests, or for other non­marketing purposes. To
              otherwise opt-out, you may access your account settings and update
              your preferences.
            </div>
            <div className="py-2">
              <h3 className="fw-bold col_2">
                Controls for do-not-track features Do we make updates to this
                policy?
              </h3>
              <div>
                Most web browsers and some mobile operating systems and mobile
                applications include a Do-Not-Track ("DNT") feature or setting
                you can activate to signal your privacy preference not to have
                data about your online browsing activities monitored and
                collected. At this stage no uniform technology standard for
                recognising and implementing DNT signals has been finalised. As
                such, we do not currently respond to DNT browser signals or any
                other mechanism that automatically communicates your choice not
                to be tracked online. If a standard for online tracking is
                adopted that we must follow in the future, we will inform you
                about that practice in a revised version of this privacy policy.
              </div>
            </div>
            <div className="py-2">
              <h3 className="fw-bold col_2">
                Do we make updates to this policy?
              </h3>
              <div>
                In Short: Yes, we will update this policy as necessary to stay
                compliant with relevant laws. We may update this privacy policy
                from time to time. The updated version will be indicated by an
                updated "Revised" date and the updated version will be effective
                as soon as it is accessible. If we make material changes to this
                privacy policy, we may notify you either by prominently posting
                a notice of such changes or by directly sending you a
                notification. We encourage you to review this privacy policy
                frequently to be informed of how we are protecting your
                information.
              </div>
            </div>
            <div className="py-2">
              <h3 className="fw-bold col_2">
                How can you contact us about this policy?
              </h3>
              <div>
                If you have questions or comments about this policy, you may
                email us at info@quickassist.uk.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
