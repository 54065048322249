import React, { Component } from "react";
import { Helmet } from "react-helmet";

export default class AdvsiorTerms extends Component {
  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Advisor Terms </title>
          <meta
            name="description"
            content="Terms of becoming an Adviser with Quick Assist "
          />
          <link
            rel="canonical"
            href="https://www.quickassist.uk/adviserTerms"
          />
        </Helmet>

        <div className="home_section_faq row mx-0 d-flex flex-column justified-content-center ">
          <div className="header_faq row mx-0 parallax_service">
            <div className="col-md-12 col-lg-9 col-xl-7 px-4 mb-4 pb-4 mx-auto text-center captions d-flex flex-column justified-content-center align-self-center">
              <h1 className="col_1"> Adviser Terms and Conditions</h1>
              <h4 className="col_1">Last updated on 15th January 2023</h4>
            </div>
          </div>

          <div className="row bg-white mx-0">
            <div className="col-sm-12 col-lg-7 col-xl-7 px-4 mb-4 pb-4 mx-auto  captions d-flex flex-column justified-content-start align-self-start">
              <div className="py-2">
                <h3 className="fw-bold col_2">1.Agreement to Terms</h3>
                <ol>
                  <li className="py-2">
                    These Terms and Conditions constitute a legally binding
                    agreement made between you, whether personally or on behalf
                    of an entity (“you”), and LLMK Limited, trading as Quick
                    Assist (“Quick Assist”, “we”, “our”, “us”), concerning your
                    access to and use of the Quick Assist Platform (the
                    “Platform”) as well as any related applications and forms of
                    communication between Quick Assist and its customers.
                  </li>
                  <li className="py-2">
                    The Platform provides the following services: Virtual access
                    to qualified professionals to help guide customers through
                    works around the home and issues they face in their
                    household (“Services”). You agree that by accessing the
                    Platform and/or Services, you have read, understood, and
                    agree to be bound by our Terms and Conditions. If you do not
                    agree with our Terms and Conditions, then you are prohibited
                    from using the Platform and Services and you must
                    discontinue use immediately.
                  </li>
                  <li className="py-2">
                    The supplemental policies set out below, as well as any
                    supplemental terms and condition or documents that may be
                    posted on the Platform from time to time, are expressly
                    incorporated by reference.
                  </li>
                  <li className="py-2">
                    We may make changes to these Terms and Conditions at any
                    time, without providing prior notice. The updated version of
                    these Terms and Conditions will be indicated by an updated
                    "Revised" date and the updated version will be effective as
                    soon as it is accessible on our website or on the Platform.
                    You are responsible for reviewing these Terms and Conditions
                    to stay informed of updates. Your continued use of the
                    Platform indicates your express agreement with such changes
                    without reservation.
                  </li>
                  <li className="py-2">
                    We may update or change the Platform, including our website
                    from time to time to reflect changes to our products, our
                    users’ needs and/or our business priorities.
                  </li>
                  <li className="py-2">
                    Our Platform is designed for use of people residing in the
                    United Kingdom. The information provided on the Platform is
                    not intended for distribution to or use by any person or
                    entity in any jurisdiction or country where such
                    distribution or use would be contrary to law or regulations
                    or which would subject us to any registration requirement
                    within such jurisdiction or country.
                  </li>
                  <li className="py-2">
                    The Platform is intended for users who are at least 18 years
                    old. If you are under the age of 18, you are not permitted
                    to register for the Platform or use the Services without
                    parental or guardian permission, and the parent or guardian
                    expressly agrees to these Terms and Conditions as part of
                    their granting permission.
                  </li>
                  <li className="py-2">
                    Supplemental policies which also apply to your use of the
                    Platform include: Our Privacy Policy setting out the terms
                    on which we process any personal data we collect from you,
                    or that you provide to us. By using the Platform, you
                    consent to such processing and you warrant that all data
                    provided by you is accurate.
                  </li>
                </ol>
              </div>
              <div className="py-2">
                <h3 className="fw-bold col_2">2.Acceptable Use</h3>
                <ol>
                  <li className="py-2">
                    You may not access or use the Platform for any purpose other
                    than that for which we make the Platform and our Services
                    available. The Platform may not be used in connection with
                    any commercial endeavours except those that are specifically
                    endorsed or approved by us.
                  </li>
                  <li className="py-2">
                    If we detect or suspect that you are misusing the Platform,
                    we may prohibit your use of the Platform and our Services
                    immediately, including suspending or terminating your
                    account and seek compensation for any damages caused by such
                    misuse and we shall have no liability to you.
                  </li>
                </ol>
              </div>
              <div className="py-2">
                <h3 className="fw-bold col_2">
                  3.Information you provide to us
                </h3>
                <ol>
                  <li className="py-2">
                    <div>
                      You confirm and warrant that: (a) all registration
                      information you submit will be true, accurate, current,
                      and complete and relate to you and not a third party; (b)
                      you will maintain the accuracy of such information and
                      promptly update such information as necessary; (c) you
                      will keep your password confidential and will be
                      responsible for all use of your password and account; (d)
                      you have the legal capacity and you agree to comply with
                      these Terms and Conditions; and (e) you are not a minor in
                      the jurisdiction in which you reside, or if a minor, you
                      have received parental or guardian permission to use the
                      Platform.
                    </div>
                    <div>
                      If you know or suspect that anyone other than you has
                      knowledge of your user information (such as an
                      identification code or user name) and/or password you must
                      promptly notify us at info@quickassist.uk.
                    </div>
                  </li>
                  <li className="py-2">
                    If you provide any information that is untrue, inaccurate,
                    not current, or incomplete, we may suspend or terminate your
                    account including but not limited to qualifications. We may
                    remove or change a username or email you select if we
                    determine that such username is inappropriate.
                  </li>
                </ol>
              </div>
              <div className="py-2">
                <h3 className="fw-bold col_2">4.Content You Provide To Us</h3>
                <ol>
                  <li className="py-2">
                    Any testimonial authored by you is provided at your own
                    discretion and is provided on a voluntary bases and is not
                    the responsibility of the Platform but is subject to any
                    vetting undertaken by the Platform to ensure conformity with
                    our standard terms of use. You shall have no legal recourse
                    for testimonials or any feedback to be published on the
                    Platform relating to you or an affiliated party. Reasons for
                    testimonials not being permitted by the Platform for
                    publication include, but are not limited to, incorrect
                    information, defamatory comments about the Platform or the
                    customers or any aspersions directed at the Platform and/or
                    the Advisers providing their service via the Platform.
                  </li>
                  <li className="py-2">
                    If you wish to complain about User Content uploaded by other
                    users, please contact us at info@quickassist.uk.
                  </li>
                </ol>
              </div>
              <div className="py-2">
                <h3 className="fw-bold col_2">5.Our Content</h3>
                <ol>
                  <li className="py-2">
                    Unless otherwise indicated the Platform and Services
                    including source code, databases, functionality, software,
                    website designs, audio, video, text, photographs, and
                    graphics on the Platform (“Our Content”) are owned or
                    licensed to us and are protected by copyright and trademark
                    laws.
                  </li>
                  <li className="py-2">
                    Except as expressly provided in these Terms and Conditions,
                    no part of the Platform, Services or Our Content may be
                    copied, reproduced, aggregated, republished, uploaded,
                    posted, publicly displayed, encoded, translated,
                    transmitted, distributed, sold, licensed, or otherwise
                    exploited for any commercial purpose whatsoever, without our
                    express prior written permission.
                  </li>
                  <li className="py-2">
                    You shall not (a) try to gain unauthorised access to the
                    Platform or any networks, servers or computer systems
                    connected to the Platform; and/or (b) make for any
                    modifications, adaptions, additions or enhancements to the
                    Platform or Our Content, for any purpose, including any
                    error corrections.
                  </li>
                  <li className="py-2">
                    You shall not (a) try to gain unauthorised access to the
                    Platform or any networks, servers or computer systems
                    connected to the Platform; and/or (b) make for any purpose
                    including error correction, any modifications, adaptions,
                    additions or enhancements to the Platform or Our Content.
                  </li>
                  <li className="py-2">
                    We shall (a) prepare the Platform and Our Content with all
                    reasonable skill and care; and (b) use industry standard
                    virus detection software to endeavour to block the uploading
                    of content to the Platform that contains viruses.
                  </li>
                  <li className="py-2">
                    Although we make reasonable efforts to update the
                    information on our Platform, we make no representations,
                    warranties or guarantees, whether express or implied, that
                    Our Content on the Platform is accurate, complete, or up to
                    date.
                  </li>
                </ol>
              </div>
              <div className="py-2">
                <h3 className="fw-bold col_2">6.Link to Third Party Content</h3>
                <ol>
                  <li className="py-2">
                    The Platform may contain links to websites or applications
                    operated by third parties. We do not have any influence or
                    control over any such third-party websites or applications
                    or the third-party operator. We are not responsible for and
                    do not endorse any third-party websites or applications or
                    their availability or content.
                  </li>
                  <li className="py-2">
                    In the event there are adverts on the Platform, we do not
                    accept responsibility for such adverts contained within the
                    Platform. If you agree to purchase goods and/or services
                    from any third party who advertises on the Platform, you do
                    so at your own risk. The advertiser, and not us, is
                    responsible for such goods and/or services and if you have
                    any questions or complaints in relation to them, you should
                    contact the advertiser.
                  </li>
                </ol>
              </div>
              <div className="py-2">
                <h3 className="fw-bold col_2">7.Platform Management</h3>
                <ol>
                  <li className="py-2">
                    We reserve the right, at our sole discretion, to (1) monitor
                    the Platform for breaches of these Terms and Conditions; (2)
                    take appropriate legal action against anyone in breach of
                    applicable laws or these Terms and Conditions; (3) refuse,
                    restrict access to or availability of, or disable (to the
                    extent technologically feasible) any of your contributions;
                    (4) remove from the Platform or otherwise disable all files
                    and content that are excessive in size or are in any way put
                    excessive loads on our systems; and (5) otherwise manage the
                    Platform in a manner designed to protect our rights and
                    property and to facilitate the proper functioning of the
                    Platform and Services.
                  </li>
                  <li className="py-2">
                    We do not guarantee that the Platform will be secure or free
                    from bugs or viruses.
                  </li>
                  <li className="py-2">
                    You are responsible for configuring your information
                    technology, computer programs and platform to access the
                    Platform and you should use your own virus protection
                    software.
                  </li>
                </ol>
              </div>
              <div className="py-2">
                <h3 className="fw-bold col_2">
                  8.Modifications to and Availability of the Platform
                </h3>
                <ol>
                  <li className="py-2">
                    We reserve the right to change, modify, or remove any
                    contents of the Platform at any time or for any reason at
                    our sole discretion without prior notice. We also reserve
                    the right to modify or discontinue all or part of the
                    Services without notice at any time.
                  </li>
                  <li className="py-2">
                    We cannot guarantee the Platform and Services will always be
                    available. We may experience hardware, software, or other
                    problems or need to perform maintenance related to the
                    Platform resulting in interruptions, delays, or errors. You
                    agree that we have no liability whatsoever for any loss,
                    damage, or inconvenience caused by your inability to access
                    or use the Platform or Services during any downtime or
                    discontinuance of the Platform or Services. We are not
                    obliged to maintain and support the Platform or Services or
                    to supply any corrections, updates, or releases.
                  </li>
                  <li className="py-2">
                    There may be information on the Platform that contains
                    typographical errors, inaccuracies, or omissions that may
                    relate to the Services, including descriptions, pricing,
                    availability, and various other information. We reserve the
                    right to correct any errors, inaccuracies, or omissions and
                    to change or update the information at any time, without
                    prior notice.
                  </li>
                </ol>
              </div>
              <div className="py-2">
                <h3 className="fw-bold col_2">
                  9.Disclaimer/Limitation of Liabilitystate
                </h3>
                <ol>
                  <li className="py-2">
                    The Platform and Services are provided on an as-is and
                    as-available basis. You agree that your use of the Platform
                    and/or Services will be at your sole risk except as
                    expressly set out in these Terms and Conditions. The
                    Services are provided on an advisery basis from you as the
                    Adviser to customers only, and neither you or we shall have
                    any liability for any losses or harm which may be incurred
                    from your advice as the Adviser. All warranties, terms,
                    conditions and undertakings, express or implied (including
                    by statute, custom or usage, a course of dealing, or common
                    law) in connection with the Platform and Services and your
                    use thereof including, without limitation, the implied
                    warranties of satisfactory quality, fitness for a particular
                    purpose and non-infringement are excluded to the fullest
                    external permitted by applicable law.
                    <div></div>
                    We do not warrant or make warranties or representations
                    about the accuracy or completeness of the Platform's content
                    and are not liable for any (1) errors or omissions in
                    content: (2) any unauthorised access to or use of our
                    servers and/or any and all personal information and/or
                    financial information stored on our server; (3) any
                    interruption or cessation of transmission to or from the
                    Platform or services: and/or (4) any bugs, viruses, trojan
                    horses, or the like which may be transmitted to or through
                    the Platform by any third party. We will not be responsible
                    for any delay or failure to comply with our obligations
                    under these Terms and Conditions if such delay or failure is
                    caused by an event beyond our reasonable control.
                  </li>
                  <li className="py-2">
                    We take no responsibility for loss or damage suffered by
                    you, both as a consumer or as an Adviser. We provide the
                    Platform as a means for you to speak to customers for the
                    sole purpose of providing advice virtually. We or you as the
                    Advisers do not provide any guarantees or representations
                    that the Advisers will be able to fix your issue virtually,
                    and you may need to attend to the issue in person. In the
                    event that the issue needs you to attend to the issue in
                    person, the Services shall cease from the point that you
                    agree with the customer on the attendance in person, save
                    for that you may agree to waive the cost to you of the
                    Platform Services from your fees. Any subsequent engagement
                    between you and the customer after the end of the Services
                    and not on the Platform shall be between you and the
                    customer and we hold no responsibility or liabilities for
                    this.
                  </li>
                </ol>
              </div>
              <div className="py-2">
                <h3 className="fw-bold col_2">10.Term and Termination</h3>
                <ol>
                  <li className="py-2">
                    These Terms and Conditions shall remain in full force and
                    effect while you use the Platform or Services or are
                    otherwise a user of the Platform, as applicable. You may
                    terminate your use or participation at any time, for any
                    reason, by following the instructions for terminating user
                    accounts in your account settings, if available, or by
                    contacting us at info@quickassist.uk
                  </li>
                  <li className="py-2">
                    Without limiting any other provision of these Terms and
                    Conditions, we reserve the right to, at our sole discretion
                    and without notice or liability, deny access to and use of
                    the Platform and the Services (including blocking certain IP
                    addresses), to any person for any reason including without
                    limitation for breach of any representation, warranty or
                    covenant contained in these Terms and Conditions or of any
                    applicable law or regulation.
                    <div></div>
                    If we determine, at our sole discretion, that your use of
                    the Platform/Services is in breach of these Terms and
                    Conditions or of any applicable law or regulation, we may
                    terminate your use or participation in the Platform and the
                    Services or delete your profile and any content or
                    information that you posted at any time, without warning, at
                    our sole discretion.
                  </li>
                  <li className="py-2">
                    If we terminate or suspend your account for any reason set
                    out in this section, you are prohibited from registering and
                    creating a new account under your name, a fake or borrowed
                    name, or the name of any third party, even if you may be
                    acting on behalf of the third party. In addition to
                    terminating or suspending your account we reserve the right
                    to take appropriate legal action, including without
                    limitation pursuing civil and criminal proceedings and /or
                    seek an interim injunction to provide immediate remedy to
                    any wrongful of harmful activity on the Platform.
                  </li>
                </ol>
              </div>
              <div className="py-2">
                <h3 className="fw-bold col_2">11.Mobile Application</h3>
                <ol>
                  <li className="py-2">
                    If you access the Services via a mobile application, then we
                    grant you a revocable, non-exclusive, non-transferable,
                    limited right to install and use the mobile application on
                    wireless electronic devices owned or controlled by you, and
                    to access and use the mobile application on such devices
                    strictly in accordance with the terms and conditions of this
                    licence.
                  </li>
                  <li className="py-2">
                    The following terms apply when you use a mobile web app or
                    an application obtained from either the Apple Store or
                    Google Play (each an App Distributor) to access the
                    Services:
                    <ol>
                      <li className="py-2">
                        The licence granted to you for our mobile application is
                        limited to a non-transferable licence to use the
                        application on a device that utilises the Apple iOS,
                        Android operating or other applicable system, as
                        applicable, and in accordance with the usage rules set
                        forth in the applicable App Distributor terms of
                        service;
                      </li>
                      <li className="py-2">
                        We are responsible for providing any maintenance and
                        support services with respect to the mobile application
                        as specified in these Terms and Conditions or as
                        otherwise required under applicable law. You acknowledge
                        that each App Distributor has no obligation whatsoever
                        to furnish any maintenance and support services with
                        respect to the mobile application;
                      </li>
                      <li className="py-2">
                        In the event of any failure of the mobile application to
                        conform to any applicable warranty, you may notify an
                        App Distributor, and the App Distributor, in accordance
                        with its terms and policies, may refund the purchase
                        price, if any, paid for the mobile application, and to
                        the maximum extent permitted by applicable law, an App
                        Distributor will have no other warranty obligation
                        whatsoever with respect to the mobile application;
                      </li>
                      <li className="py-2">
                        You must comply with applicable third party terms of
                        agreement when using the mobile application, e.g., if
                        you have a voice over internet protocol “VolP”
                        application, then you must not be in breach of their
                        wireless data service agreement when using the mobile
                        application; and
                      </li>
                      <li className="py-2">
                        You acknowledge and agree that the App Distributors are
                        third party beneficiaries of these Terms and Conditions,
                        and that each App Distributor will have the right (and
                        will be deemed to have accepted the right) to enforce
                        these Terms and Conditions against you as a third party
                        beneficiary thereof.
                      </li>
                    </ol>
                  </li>
                </ol>
              </div>
              <div className="py-2">
                <h3 className="fw-bold col_2">12.General</h3>
                <ol>
                  <li className="py-2">
                    Visiting the Platform, sending us emails, and completing
                    online forms constitute electronic communications. You
                    consent to receive electronic communications and you agree
                    that all agreements, notices, disclosures, and other
                    communications we provide to you electronically, via email
                    and on the Platform, satisfy any legal requirement that such
                    communication be in writing.
                  </li>
                  <li className="py-2">
                    You hereby agree to the use of electronic signatures,
                    contracts, orders and other records and to electronic
                    delivery of notices, policies and records of transactions
                    initiated or completed by us or via the Platform. You hereby
                    waive any rights or requirements under any statutes,
                    regulations, rules, ordinances or other laws in any
                    jurisdiction which require an original signature or delivery
                    or retention of non electronic records, or to payments or
                    the granting of credits by other than electronic means.
                  </li>
                  <li className="py-2">
                    These Terms and Conditions and any policies or operating
                    rules posted by us on the Platform or in respect to the
                    Services constitute the entire agreement and understanding
                    between you and us.
                  </li>
                  <li className="py-2">
                    Our failure to exercise or enforce any rights or provisions
                    of these Terms and Conditions shall not operate as a waiver
                    of such rights or provisions.
                  </li>
                  <li className="py-2">
                    We may assign any or all of our rights and obligations to
                    others at any time.
                  </li>
                  <li className="py-2">
                    We shall not be responsible or liable for any loss, damage,
                    delay or failure to act caused by any cause beyond our
                    reasonable control.
                  </li>
                  <li className="py-2">
                    If any provision or part of a provision of these Terms and
                    Conditions is unlawful, void or unenforceable, that
                    provision or part thereof is deemed severable from these
                    Terms and Conditions and does not affect the validity and
                    enforceability of any remaining provisions.
                  </li>
                  <li className="py-2">
                    There is no joint venture, partnership, employment or agency
                    relationship created between you and us as a result of these
                    Terms and Conditions or use of the Platform or Services.
                  </li>
                  <li className="py-2">
                    A person who is not a party to these Terms and Conditions
                    shall have no right under the Contracts (Rights of Third
                    Parties) Act 1999 to enforce any term of these Terms and
                    Conditions.
                  </li>
                  <li className="py-2">
                    In order to resolve a complaint regarding the Services or to
                    receive further information regarding use of the Services,
                    please contact us by email at info@quickassist.uk.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
