import React, { Component } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion/dist/framer-motion";

import { DATA } from "../Services/data";
const NavData = [
  {
    id: 1,
    content: [
      {
        id: 1,
        name: "Adviser Account",
        route: "adviser",
        logo: "/ServiceCardImg/Adviser.png",
      },
      {
        id: 2,
        name: "Customer Account",
        route: "consumer",
        logo: "/ServiceCardImg/Customer.png",
      },
    ],
  },
  {
    id: 2,
    content: [
      {
        id: 3,
        name: "Blogs",
        route: "blog",
        logo: "/ServiceCardImg/6.png",
      },
      {
        id: 4,
        name: "Register as an Adviser",
        route: "newapplicant",
        logo: "/ServiceCardImg/Adviser.png",
      },
    ],
  },
  {
    id: 3,
    content: [
      {
        id: 1,
        name: "Adviser FAQ",
        route: "faqAdviser",
        logo: "/ServiceCardImg/Adviser.png",
      },
      {
        id: 2,
        name: "Customer FAQ",
        route: "faqConsumer",
        logo: "/ServiceCardImg/Customer.png",
      },
    ],
  },
];

const Icons = (props) => {
  return (
    <div
      className=" d-flex flex-column justified-content-center align-items-center py-2"
      id={props.id}
      onClick={() => props.handleClick(props.id)}
      onTouch={() => props.handleClick(props.id)}
    >
      <div className={`bi ${props.icon} my-0 col_3 header_2 `}></div>
      <div className="col_1 header_5">{props.name}</div>
    </div>
  );
};

const ActionIcons = (props) => {
  return (
    <div
      className="mobile_footer_action_btn"
      id={props.id}
      onClick={() => props.handleClick(props.id)}
      onTouch={() => props.handleClick(props.id)}
    >
      <div className="mobile_footer_action_icon ">
        <img src="/ServiceCardImg/booknow.svg" alt={props.name} width="80%" />
      </div>
    </div>
  );
};
const NavIcon = (props) => {
  return (
    <div
      key={props.id}
      className="col-12 col_2 bg-light my-1"
      onClick={() => props.handleClick(props.icon_id)}
    >
      <Link
        to={`/${props.route}`}
        className="d-flex flex-row justify-content-start align-items-center"
      >
        <img src={props.logo} alt={props.name} width="50px" height="50px" />
        <div className="px-1 col_2">{props.name}</div>
      </Link>
    </div>
  );
};

const Booking = (props) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 2000 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, type: "tween" }}
      className="col-12 px-0 mx-0"
    >
      <div className="text-end p-2 w-100">
        <i
          class="bi bi-x-circle"
          onClick={() => props.handleClick(props.id)}
          onTouch={() => props.handleClick(props.id)}
        ></i>
      </div>
      {DATA.map((item, index) => (
        <NavIcon
          id={`nav_${index}`}
          name={item.title}
          route={
            props.page === "home"
              ? `service?id=${item.serviceCode}&state=true`
              : `service?id=${item.serviceCode}`
          }
          logo={item.logo_url}
          icon_id={props.id}
          handleClick={props.handleClick}
        />
      ))}
    </motion.div>
  );
};
const Order = (props) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 2000 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, type: "tween" }}
      className="col-12 px-0 mx-0"
    >
      <div className="text-end p-2 w-100">
        <i
          class="bi bi-x-circle"
          onClick={() => props.handleClick(props.id)}
          onTouch={() => props.handleClick(props.id)}
        ></i>
      </div>
      {NavData[0].content.map((item, index) => (
        <NavIcon
          id={`nav_${index}`}
          name={item.name}
          route={item.route}
          logo={item.logo}
          icon_id={props.id}
          handleClick={props.handleClick}
        />
      ))}
    </motion.div>
  );
};
const Faq = (props) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 2000 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, type: "tween" }}
      className="col-12 px-0 mx-0"
    >
      <div className="text-end p-2 w-100">
        <i
          class="bi bi-x-circle"
          onClick={() => props.handleClick(props.id)}
          onTouch={() => props.handleClick(props.id)}
        ></i>
      </div>

      {NavData[2].content.map((item, index) => (
        <NavIcon
          id={`nav_${index}`}
          name={item.name}
          route={item.route}
          logo={item.logo}
          icon_id={props.id}
          handleClick={props.handleClick}
        />
      ))}
    </motion.div>
  );
};

const Menu = (props) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 2000 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, type: "tween" }}
      className="col-12 px-0 mx-0"
    >
      <div className="text-end p-2 w-100">
        <i
          class="bi bi-x-circle"
          onClick={() => props.handleClick(props.id)}
          onTouch={() => props.handleClick(props.id)}
        ></i>
      </div>
      {NavData[1].content.map((item, index) => (
        <NavIcon
          id={`nav_${index}`}
          name={item.name}
          route={item.route}
          logo={item.logo}
          icon_id={props.id}
          handleClick={props.handleClick}
        />
      ))}
    </motion.div>
  );
};

export default class FooterBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      option_id: null,
    };
  }

  handleClick = (e) => {
    if (this.state.option_id == e) {
      this.setState({ option_id: null });
    } else {
      this.setState({ option_id: e });
    }
  };

  handleHome = (e) => {
    this.setState({ option_id: null });
    let url = "/";
    window.location.href = url;
  };

  render() {
    return (
      <div className="d-block d-sm-none">
        <div className="mobile_footer_main row m-0">
          {this.state.option_id == null ? null : this.state.option_id == 2 ? (
            <Faq id={2} handleClick={this.handleClick} />
          ) : this.state.option_id == 3 ? (
            <Booking
              id={3}
              handleClick={this.handleClick}
              page={this.props.page}
            />
          ) : this.state.option_id == 4 ? (
            <Order id={4} handleClick={this.handleClick} />
          ) : this.state.option_id == 5 ? (
            <Menu id={5} handleClick={this.handleClick} />
          ) : null}
          {(this.props.page === "service") & (this.state.option_id == null) ? (
            <div className="OrderForm-Mobile-section-c p-4 text-center">
              <button
                className="btn btn-warning shadow  w-75"
                type="button"
                onClick={this.props.handleBookingNow}
              >
                Book Now
              </button>
            </div>
          ) : null}

          {/* Icons */}
          <div className="mobile_footer_nav col-12  ">
            <Icons
              icon="bi-house"
              name="Home"
              id={1}
              handleClick={this.handleHome}
            />
            <Icons
              icon="bi-info-circle"
              name="FAQ"
              id={2}
              handleClick={this.handleClick}
            />
            {this.props.page === "home" ? (
              <Icons
                name="Book Now"
                id={3}
                icon="bi-clock-history"
                handleClick={this.handleClick}
              />
            ) : this.props.page === "service" ? (
              <Icons
                name="Our Service"
                id={3}
                icon=" bi-box-seam"
                handleClick={this.handleClick}
              />
            ) : null}

            <Icons
              icon="bi-person"
              name="Account"
              id={4}
              handleClick={this.handleClick}
            />
            <Icons
              icon="bi-list"
              name="More"
              id={5}
              handleClick={this.handleClick}
            />
          </div>
        </div>
      </div>
    );
  }
}
