import React, { Component } from "react";
import { Helmet } from "react-helmet";

export default class CancellationPolicy extends Component {
  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Cancellation Policy</title>
          <meta
            name="description"
            content="The relevant and acceptable cancellation options available if you do not need to use Quick Assist anymore"
          />
          <link
            rel="canonical"
            href="https://www.quickassist.uk/cancellationPolicy"
          />
        </Helmet>

        <div className="home_section_faq row mx-0 d-flex flex-column justified-content-center ">
          <div className="header_faq row mx-0 parallax_service">
            <div className="col-md-12 col-lg-9 col-xl-7 px-4 mb-4 pb-4 mx-auto text-center captions d-flex flex-column justified-content-center align-self-center">
              <h1 className="col_1"> Cancellation Policy</h1>
              <h4 className="col_1">Last updated on 15th January 2023</h4>
            </div>
          </div>
          <div className="row bg-white mx-0">
            <div className="col-sm-12 col-lg-7 col-xl-7 px-4 mb-4 pb-4 mx-auto  captions d-flex flex-column justified-content-start align-self-start">
              <div className="py-2">
                <h4>
                  Maybe you managed to fix the problem yourself or it turned
                  into a bigger issue and our services aren't suitable anymore.
                  Here are the relevant and acceptable cancellation options
                  available, including any fee reimbursements that may be due:
                </h4>
                <ul>
                  <li className="py-2">
                    Cancelling more than 24 hours before the start of your
                    timeslot: You will be entitled to 100% of the fee.
                  </li>
                  <li className="py-2">
                    Cancelling 12 to 24 hours before the start of your timeslot:
                    You will be entitled to 50% of the fee.
                  </li>
                  <li className="py-2">
                    Cancelling 3 to 12 hours before the start of your timeslot:
                    You will be entitled to 25% of the fee.
                  </li>
                  <li className="py-2">
                    Cancelling less than 3 hours before the start of your
                    timeslot: Unfortunately, you will not be entitled to a
                    refund.
                  </li>
                  <li className="py-2">
                    Refunds can take up to 5-7 working days.
                  </li>
                  <li className="py-2">
                    In the event of a last-minute cancellation where an Adviser
                    has already been booked and allocated then none of the fees
                    shall be reimbursed.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
