import React, { Component, Fragment } from "react";

class NavCard_mobile extends Component {
  render() {
    return (
      <div
        className="col-12 px-4 py-2 col_2 bg-light my-1"
        onClick={() => {
          this.props.handleNavigation(this.props.route);
        }}
      >
        <div className="d-flex flex-row justify-content-start align-items-center">
          <h4 className={`bi ${this.props.icon} col_2`}></h4>
          <h4 className="NavText px-1 col_2">{this.props.name}</h4>
        </div>
      </div>
    );
  }
}

class FollowUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "",
      menu: false,
    };
  }
  //  For Mobile
  handleMenuBar = () => {
    this.setState({ menu: !this.state.menu });
  };

  handleNavigation = (url) => {
    window.location.href = url;
    this.handleMenuBar();
  };

  render() {
    return (
      <Fragment>
        <div className="col py-1 d-flex flex-row justify-content-end align-items-center logo">
          {this.state.menu ? (
            <h3 class="bi bi-x my-0  px-2" onClick={this.handleMenuBar}></h3>
          ) : (
            <h4 class="my-0 px-2" onClick={this.handleMenuBar}>
              Follow Us
            </h4>
          )}
        </div>
        {this.state.menu ? (
          <Fragment>
            <NavCard_mobile
              name="Facebook"
              route="https://www.facebook.com/people/QuickAssistuk/100064909273893/"
              icon="bi-facebook"
              handleNavigation={this.handleNavigation}
            />
            <NavCard_mobile
              name="Instagram"
              route="https://www.instagram.com/quickassist.uk/"
              icon="bi-instagram"
              handleNavigation={this.handleNavigation}
            />
            <NavCard_mobile
              name="LinkedIn"
              route="https://www.linkedin.com/company/quickassistuk/about/?viewAsMember=true"
              icon="bi-linkedin"
              handleNavigation={this.handleNavigation}
            />
          </Fragment>
        ) : null}
      </Fragment>
    );
  }
}
export default FollowUs;
