import React, { useState, Fragment } from "react";

// import blog Data
import { BLOG_DATA } from "./BlogData";
import BlogCard from "./BlogCard";

const BlogContainer = () => {
  const [show, setShow] = useState(false);

  const handleExtraBlog = () => {
    setShow((prevState) => !prevState);
  };

  const handleRedirect = (id) => {
    let url = "/blog?id=" + id;
    window.location.href = url;
  };

  return (
    <Fragment>
      {/* Desktop */}
      <div className=" row justify-content-center px-4 mx-0 py-4  bg-white d-none d-sm-block">
        {/* Heading and Sub heading */}
        <div className="col-md-12 col-lg-8 col-xl-8 px-4 mx-auto text-center captions mb-4 ">
          <div className="col_4 header_1">Blogs</div>
          <div className="col_2 header_3">Tips, Tricks and Exciting News</div>
        </div>

        <div className="col-12  px-4 my-4 py-4">
          <div className="row g-4">
            {BLOG_DATA.map((data, index) => {
              return index <= 2 ? (
                <BlogCard
                  data={data}
                  size={4}
                  handleRedirect={handleRedirect}
                />
              ) : index > 2 && show ? (
                <BlogCard
                  data={data}
                  size={4}
                  handleRedirect={handleRedirect}
                />
              ) : null;
            })}
          </div>
        </div>
        <div
          className="col-12  px-4 my-4 text-end pointer"
          onClick={handleExtraBlog}
        >
          {show ? "Show Less Blogs" : "Show More Blogs"}
        </div>
      </div>
      {/* Mobile */}
      <div className=" row justify-content-center mx-0 py-4 bg-white d-block d-sm-none">
        {/* Heading and Sub heading */}
        <div className="col-md-12 col-lg-8 col-xl-8 px-4 mx-auto text-center captions mb-4 ">
          <div className="col_4 header_1">Blogs</div>
          <div className="col_2 header_3">Tips, Tricks and Exciting News</div>
        </div>
        <div className="col-12  px-4 my-4 py-4">
          <div className="row g-4">
            {BLOG_DATA.map((data, index) => {
              return index <= 1 ? (
                <BlogCard
                  data={data}
                  size={12}
                  handleRedirect={handleRedirect}
                />
              ) : index > 1 && show ? (
                <BlogCard
                  data={data}
                  size={12}
                  handleRedirect={handleRedirect}
                />
              ) : null;
            })}
          </div>
        </div>
        <div
          className="col-12  px-4 my-4 text-end pointer"
          onClick={handleExtraBlog}
        >
          {show ? "Show Less Blogs" : "Show More Blogs"}
        </div>
      </div>
    </Fragment>
  );
};

export default BlogContainer;
