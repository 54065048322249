import React, { Component } from "react";
import { Helmet } from "react-helmet";

export default class FourZeroFour extends Component {
  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>404</title>
          <link rel="canonical" href="https://www.quickassist.uk/404" />
          <meta name="errorpage" content="true" />
          <meta name="errortype" content="404 - Not Found" />
          <meta name="prerender-status-code" content="404" />
        </Helmet>
        {/* Desktop */}
        <div className="d-none d-sm-block">
          <div className="FourOneFour  row mx-0 px-sm-0 px-md-4 px-lg-4 px-xl-4 px-xxl-4">
            <div className="col col-xl-7  FourOneFour-img"></div>
            <div className="col col-xl-5  d-none d-sm-block mt-4 pt-4">
              <div className="Outside_Wrapper_normal row d-flex flex-column justify-content-end  align-item-end mb-3 ">
                <div className="col-12 py-2 top_margin">
                  <div>
                    <h2 className="pb-2 " id="home">
                      UH_OH....
                    </h2>
                    <h1 className="FourOneFour-txt">404</h1>
                    <h3>
                      Seems that this page is broken, but don't worry, Quick
                      Assist is on the case!
                    </h3>
                    <h3>In the meantime, let's find you the right support!</h3>
                    <a
                      className="btn btn-warning btn-sm shadow py-3 w-50 mt-4"
                      type="button"
                      href="/"
                    >
                      Back to Home
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Mobile */}
        <div className=" d-block d-sm-none">
          <div className="FourOneFour row mx-0">
            <div></div>

            <div className="col-12 px-4 d-flex flex-column justify-content-end  align-item-end mb-3 ">
              <div className="col w-100 mx-0 FourOneFour-img-mobile"></div>
              <div className="col-12 py-4 mb-4 ">
                <div>
                  <h2 className="pb-2 " id="home">
                    UH_OH....
                  </h2>
                  <h1 className="FourOneFour-txt-mobile">404</h1>
                  <h4 style={{ color: "white" }}>
                    Seems that this page is broken, but don't worry, Quick
                    Assist is on the case!
                  </h4>
                  <h4 style={{ color: "white" }}>
                    In the meantime, let's find you the right support!
                  </h4>
                  <a
                    className="btn btn-warning btn-sm shadow py-3 w-100 mt-4"
                    type="button"
                    href="/"
                  >
                    Back to Home
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
