import thunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";

// Initial State...
const initialState = {
  //   Header Page
  AVCONTROLLER_STATUS: false,
  AVCONTROLLER_VIDEO_TOGGLE: false,
  AVCONTROLLER_VIDEO: false,
  AVCONTROLLER_AUDIO: false,
  LANDING_PAGE_SECTION: true,
  PAGE_CATEGORY: "qa1",
  FAQ_CATEGORY: "",
  USER_DATA: {},
  LOGIN_STATUS: false,
  REQUEST_STATUS: { type: "", statusCode: null },
  API_KEY: "",
  ORDER: [
    {
      data: { title: "Plumber" },
      image: [],
      date: "",
      time: "",
      problem: "",
      shortDescription: "",
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      callType: "",
      jobCode: "",
      advisor: {
        firstname: "",
        lastname: "",
        phone: "",
        callType: "",
        passcode: "",
        description: "",
        startTime: "",
        endTime: "",
      },
    },
  ],
  MESSAGES: [
    {
      email: "dasgupta.sourish@gmail.com",
      firstname: "dasgupta",
      lastname: "souirsh",
      timeStamp: "null",
      jobId: "NA",
    },
  ],
  JOBS: [
    {
      firstname: "",
      lastname: "",
      dob: "",
      email: "",
      phone: "",
      city: "",
      postalCode: "",
      trade: "",
      qualification: "",
      shortDescription: "",
      profileText: "",
    },
  ],
  SUBSCRIPTION: [{ email: "hiii", timeStamp: "S" }],
};
// Reducer.....
const reducer = (state = initialState, action) => {
  switch (action.type) {
    //   Header Page
    case "LANDING_PAGE_SECTION":
      return {
        ...state,
        LANDING_PAGE_SECTION: action.value,
      };
    case "PAGE_CATEGORY":
      return {
        ...state,
        PAGE_CATEGORY: action.value,
      };
    case "FAQ_CATEGORY":
      return {
        ...state,
        FAQ_CATEGORY: action.value,
      };
    case "USER_DATA":
      return {
        ...state,
        USER_DATA: action.value,
        ORDER: [
          {
            ...action.value,
            advisor: {
              firstname: "",
              lastname: "",
              phone: "",
              callType: "",
              passcode: "",
              description: "",
              startTime: "",
              endTime: "",
            },
          },
          ...state.ORDER,
        ],
      };
    case "LOGIN_STATUS":
      return {
        ...state,
        LOGIN_STATUS: action.value,
      };

    case "MESSAGE":
      return {
        ...state,
        MESSAGES: [action.value, ...state.MESSAGES],
      };
    case "JOBS":
      return {
        ...state,
        JOBS: [action.value, ...state.JOBS],
      };
    case "SUBSCRIPTION":
      return {
        ...state,
        SUBSCRIPTION: [action.value, ...state.SUBSCRIPTION],
      };
    case "REQUEST_STATUS":
      return {
        ...state,
        REQUEST_STATUS: action.value,
      };
    case "API_KEY":
      return {
        ...state,
        API_KEY: action.value,
      };
    case "AVCONTROLLER_VIDEO":
      return {
        ...state,
        AVCONTROLLER_VIDEO: !state.AVCONTROLLER_VIDEO,
      };
    case "AVCONTROLLER_AUDIO":
      return {
        ...state,
        AVCONTROLLER_AUDIO: !state.AVCONTROLLER_AUDIO,
      };

    case "AVCONTROLLER_VIDEO_TOGGLE":
      return {
        ...state,
        AVCONTROLLER_VIDEO_TOGGLE: !state.AVCONTROLLER_VIDEO_TOGGLE,
      };
    case "AVCONTROLLER_RESET":
      return {
        ...state,
        AVCONTROLLER_VIDEO_TOGGLE: false,
        AVCONTROLLER_AUDIO: false,
        AVCONTROLLER_VIDEO: false,
        AVCONTROLLER_STATUS: false,
      };
    case "AVCONTROLLER_STATUS":
      return {
        ...state,
        AVCONTROLLER_STATUS: !state.AVCONTROLLER_STATUS,
      };

    default:
      return state;
  }
};

// Store
export const store = createStore(reducer, applyMiddleware(thunk));

export const setApiKey = (value) => {
  return {
    type: "API_KEY",
    value: value,
  };
};

export const setRequestStatus = (value) => {
  return {
    type: "REQUEST_STATUS",
    value: value,
  };
};

// Header Page
export const setLandingPageHeader = (value) => {
  return {
    type: "LANDING_PAGE_SECTION",
    value: value,
  };
};
export const setPageCategory = (value) => {
  return {
    type: "PAGE_CATEGORY",
    value: value,
  };
};

export const setFaqCategory = (value) => {
  return {
    type: "FAQ_CATEGORY",
    value: value,
  };
};
export const setLogin = (value) => {
  if (value === false) {
    window.location.reload();
  }
  return {
    type: "LOGIN_STATUS",
    value: value,
  };
};

export const setUserData = (value) => {
  return {
    type: "USER_DATA",
    value: value,
  };
};

export const setMessage = (value) => {
  return {
    type: "MESSAGE",
    value: value,
  };
};

export const setJobApplication = (value) => {
  return {
    type: "JOBS",
    value: value,
  };
};

export const setSubscription = (value) => {
  return {
    type: "SUBSCRIPTION",
    value: value,
  };
};

export const setAVControlVideo = () => {
  return {
    type: "AVCONTROLLER_VIDEO",
  };
};
export const setAVControlAudio = () => {
  return {
    type: "AVCONTROLLER_AUDIO",
  };
};

export const setAVControlVideoToggle = () => {
  return {
    type: "AVCONTROLLER_VIDEO_TOGGLE",
  };
};

export const setAVControlReset = () => {
  return {
    type: "AVCONTROLLER_RESET",
  };
};

export const setAVControlStatus = () => {
  return {
    type: "AVCONTROLLER_STATUS",
  };
};
