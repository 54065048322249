import { blog_1, blog_2, blog_3, blog_4, blog_5, blog_6 } from "./BlogContent";
export const BLOG_DATA = [
  {
    id: 1,
    title: "Refresh Your Home with a Coat of Paint",
    date: "1st May, 2023",
    summary:
      "Summer is the perfect season for paint projects. If you have areas in your home that you've been meaning to repaint, such as entire rooms or the exterior, summer is the time to do it. The warm weather lets the paint cure properly and ensures that it will last longer.",
    imgurl: "/BlogsImg/Blog1.png",
    content: blog_1.data,
  },
  {
    id: 2,
    title: "Check & Clean Gutters",
    date: "8th May, 2023",
    summary:
      "Winter, followed by a rainy spring, can take a toll on your gutters and downspouts. If they're ignored, you could end up needing to replace them altogether, or worse, dealing with foundation damage, interior flooding, or landscape erosion.",
    imgurl: "/BlogsImg/Blog2.png",
    content: blog_2.data,
  },
  {
    id: 3,
    title: "Protect Against Pests",
    date: "15th May, 2023",
    summary:
      "Don’t fancy sharing your home with ants, wasps, flies or mosquitoes? Make sure to seal cracks, repair screens, and remove any standing water to keep unwanted pests at bay.",
    imgurl: "./BlogsImg/Blog3.png",
    content: blog_3.data,
  },
  {
    id: 4,
    title: "Enhance Outdoor Lighting",
    date: "22nd May, 2023",
    summary:
      "Create a welcoming ambiance in your outdoor areas by installing energy-efficient LED lights, illuminate pathways, highlight landscape features, and add charm to your warm evenings spent outside! ",
    imgurl: "./BlogsImg/Blog4.png",
    content: blog_4.data,
  },
  {
    id: 5,
    title: "Clean Your Windows",
    date: "31 May, 2023",
    summary:
      "Summer is the perfect time to get cleaning your windows both, inside and out. The warm weather will help them dry easily and it will allow the sun to shine through your newly cleaned windows, instantly brightening up your home.",
    imgurl: "/BlogsImg/Blog5.png",
    content: blog_5.data,
  },
  {
    id: 6,
    title: "Plumbing Problems?",
    date: "13 April, 2023",
    summary:
      "If you are having problems with your boiler, struggling with a toilet that does not flush, or any other plumbing-related issue, then your first thought might be to call a plumber.",
    imgurl: "/BlogsImg/Blog6.png",
    content: blog_6.data,
  },
];
