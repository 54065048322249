import React, { Component, Fragment } from "react";

// Redux

import { connect } from "react-redux";
import { setMessage, setLandingPageHeader } from "../../redux/store";

const mapDispatchToProps = (dispatch) => {
  return {
    setMessage: (value) => {
      dispatch(setMessage(value));
    },
    setLandingPageHeader: (value) => {
      dispatch(setLandingPageHeader(value));
    },
  };
};

class HowItsWorks extends Component {
  render() {
    return (
      <Fragment>
        {/* Mobiile */}
        <div className="d-block d-sm-none">
          <div className=" row justify-content-center px-4 mx-0 works pb-4 ">
            {/* Heading and Sub heading */}
            <div className="col-md-12 col-lg-6 col-xl-6 px-4 mb-4 pb-4 mx-auto text-center captions">
              <div className="header_1"> How it Works</div>
              <div className="mb-4 header_3">
                Get support from one of our trusted advisers in 4 easy steps
              </div>
            </div>
            {/* Heading and Sub heading */}
            <div className="col-12 gx-5 px-4 mb-4 pb-4 mx-auto text-center d-flex flex-row justify-content-center row">
              {/* step_1 */}
              <div className="col-12 d-flex flex-column justify-content-start align-content-center mb-4">
                <div className="text-center px-3 py-3 shadow card_Steps">
                  <div className="header_1">01</div>
                  <div className="header_3"> Select the Service</div>
                </div>
                <div className="shadow py-4 d-flex flex-row justify-content-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100"
                    height="100"
                    fill="none"
                    viewBox="0 0 100 150"
                  >
                    <path
                      fill="#FFC700"
                      d="M88.688 71.75l-20.25-10.125c8.062-6.25 13.25-16 13.25-27C81.688 15.687 66.25.25 47.312.25c-18.937 0-34.374 15.438-34.374 34.375 0 13.313 7.624 24.875 18.75 30.563v20.374c-13.438-2.874-12.625-2.75-14.125-2.75-3.313 0-6.438 1.313-8.813 3.688L0 95.375l31.813 31.812c2.687 2.75 6.437 4.313 10.312 4.313H81.5a12.428 12.428 0 0012.313-10.438l5-29.437C100.188 83.5 96.124 75.5 88.686 71.75zM86.5 89.563L81.5 119H42.125c-.563 0-1.063-.25-1.5-.625l-23-23 26.563 5.562V34.625a3.095 3.095 0 013.124-3.125 3.095 3.095 0 013.126 3.125v37.5h11l21.624 10.813c2.5 1.25 3.876 3.937 3.438 6.624zM25.437 34.624c0-12.063 9.813-21.875 21.875-21.875 12.063 0 21.876 9.813 21.876 21.875 0 5.938-2.376 11.313-6.25 15.25v-15.25C62.938 26 55.938 19 47.312 19c-8.624 0-15.624 7-15.624 15.625v15.25c-3.875-3.938-6.25-9.313-6.25-15.25z"
                    />
                  </svg>
                </div>

                <div className="text-center px-3  pt-3 ">
                  <div className="col_1 header_4">
                    Simply select from plumbing, electrical, general DIY,
                    building & fitting furniture, installation support or quote
                    checking & independent advice
                  </div>
                </div>
              </div>
              {/* step_2 */}
              <div className="col-12 d-flex flex-column justify-content-start align-content-center mb-4">
                <div className="text-center px-3 py-3 shadow card_Steps">
                  <div className="header_1">02</div>
                  <div className="header_3"> Describe Your Problem</div>
                </div>
                <div className="shadow py-4 d-flex flex-row justify-content-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100"
                    height="100"
                    fill="none"
                    viewBox="0 0 150 150"
                  >
                    <path
                      fill="#FFC700"
                      d="M106.25 75C89 75 75 89 75 106.25s14 31.25 31.25 31.25 31.25-14 31.25-31.25S123.5 75 106.25 75zm6.25-56.25H92.625C90 11.5 83.125 6.25 75 6.25s-15 5.25-17.625 12.5H37.5c-6.875 0-12.5 5.625-12.5 12.5V125c0 6.875 5.625 12.5 12.5 12.5h38.188C72 133.938 69 129.688 66.811 125H37.5V31.25H50V50h50V31.25h12.5V63c4.438.625 8.625 1.938 12.5 3.75v-35.5c0-6.875-5.625-12.5-12.5-12.5zM75 31.25c-3.438 0-6.25-2.813-6.25-6.25 0-3.438 2.813-6.25 6.25-6.25 3.438 0 6.25 2.813 6.25 6.25 0 3.438-2.813 6.25-6.25 6.25z"
                    />
                    <path
                      fill="#1B1C39"
                      d="M108.482 95.851l4.023-4.022c1.333-1.334 2-2 2.828-2 .829 0 1.495.666 2.829 2l1.009 1.01c1.334 1.333 2 2 2 2.828 0 .828-.666 1.495-2 2.828l-3.986 3.987a18.827 18.827 0 01-6.703-6.63zM107.028 97.306L94.523 109.81c-.425.426-.638.638-.777.899-.14.261-.2.556-.317 1.145l-1.282 6.411c-.067.332-.1.499-.005.593.094.095.26.062.593-.005l6.41-1.282c.59-.118.885-.177 1.146-.317.261-.139.473-.352.898-.777l12.538-12.537a20.834 20.834 0 01-6.699-6.634z"
                    />
                  </svg>
                </div>
                <div className="text-center px-3 pt-3">
                  <div className="col_1 header_4">
                    Help us help you! Provide a description and upload photos of
                    your problem so that our Advisers can be prepared before the
                    video call
                  </div>
                </div>
              </div>
              {/* step_3 */}
              <div className="col-12 d-flex flex-column justify-content-start align-content-center mb-4">
                <div className="text-center px-3 py-3 shadow card_Steps">
                  <div className="header_1">03</div>
                  <div className="header_3"> Book your Timeslot </div>
                </div>
                <div className="shadow py-4 d-flex flex-row justify-content-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100"
                    height="100"
                    fill="none"
                    viewBox="0 0 150 150"
                  >
                    <path
                      fill="#FFC700"
                      d="M106.25 75C89 75 75 89 75 106.25s14 31.25 31.25 31.25 31.25-14 31.25-31.25S123.5 75 106.25 75zm10.312 45.938L103.125 107.5v-20h6.25v17.438l11.563 11.562-4.376 4.438zM112.5 18.75H92.625c8.125 0 8.125 0 0 0 8.125 0-8.125 0 0 0H37.5c-6.875 0-12.5 5.625-12.5 12.5V125c0 6.875 5.625 12.5 12.5 12.5h38.188C72 133.938 69 129.688 66.811 125H37.5V31.25h75V63c4.438.625 8.625 1.938 12.5 3.75v-35.5c0-6.875-5.625-12.5-12.5-12.5zM75 31.25h12.5H75z"
                    />
                    <path
                      fill="#FFC700"
                      stroke="#1B1C39"
                      d="M58.5 7.5h5v16h-5zM87.5 7.5h5v16h-5z"
                    />
                  </svg>
                </div>
                <div className="text-center px-3 pt-3 ">
                  <div className="col_1 header_4">
                    Confirm a 2 hour time slot which is suitable for you
                  </div>
                </div>
              </div>
              {/* step_4 */}
              <div className="col-12 d-flex flex-column mb-4">
                <div className="text-center px-3 py-3 shadow card_Steps">
                  <div className="header_1">04</div>
                  <div className="header_3"> Conduct the Video Call </div>
                </div>
                <div className="shadow py-4 d-flex flex-row justify-content-center">
                  <svg
                    className="icon_steps"
                    xmlns="http://www.w3.org/2000/svg"
                    width="100"
                    height="100"
                    fill="none"
                    viewBox="0 0 126 150"
                  >
                    <path
                      fill="#FFC700"
                      d="M125.5.5H.562L.5 125.5l25-25h100V.5zm-25 75l-25-20v20h-50v-50h50v20l25-20v50z"
                    />
                  </svg>
                </div>
                <div className="text-center px-3 pt-3">
                  <div className="col_1 header_4">
                    Your call will be held via our integrated video system
                  </div>
                </div>
              </div>
              <div className=" col-12 d-flex flex-column justify-content-center align-items-center px-2">
                <a
                  href="https://www.quickassist.uk/HowItWorksCustomer"
                  className="col_3"
                >
                  Click Here for More Details
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* Desktop */}
        <div className="d-none d-sm-block">
          <div className=" row justify-content-center px-4 mx-0 works pb-4 ">
            {/* Heading and Sub heading */}
            <div className="col-md-12 col-lg-6 col-xl-6 px-4 mb-4 pb-4 mx-auto text-center captions">
              <div className="header_1"> How it Works</div>
              <div className="mb-4 header_3">
                Get support from one of our trusted advisers in 4 easy steps
              </div>
            </div>
            {/* Heading and Sub heading */}
            <div className="col-12 gx-5 px-4 mb-4 pb-4 mx-auto text-center d-flex flex-row justify-content-center row">
              {/* step_1 */}
              <div className="col col d-flex flex-column justify-content-start align-content-center mb-4">
                <div className="text-center px-3 py-3 shadow card_Steps">
                  <div className="header_1">01</div>
                  <div className="header_3">Select the Service</div>
                </div>
                <div className="shadow py-4 d-flex flex-row justify-content-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100"
                    height="150"
                    fill="none"
                    viewBox="0 0 100 150"
                  >
                    <path
                      fill="#FFC700"
                      d="M88.688 71.75l-20.25-10.125c8.062-6.25 13.25-16 13.25-27C81.688 15.687 66.25.25 47.312.25c-18.937 0-34.374 15.438-34.374 34.375 0 13.313 7.624 24.875 18.75 30.563v20.374c-13.438-2.874-12.625-2.75-14.125-2.75-3.313 0-6.438 1.313-8.813 3.688L0 95.375l31.813 31.812c2.687 2.75 6.437 4.313 10.312 4.313H81.5a12.428 12.428 0 0012.313-10.438l5-29.437C100.188 83.5 96.124 75.5 88.686 71.75zM86.5 89.563L81.5 119H42.125c-.563 0-1.063-.25-1.5-.625l-23-23 26.563 5.562V34.625a3.095 3.095 0 013.124-3.125 3.095 3.095 0 013.126 3.125v37.5h11l21.624 10.813c2.5 1.25 3.876 3.937 3.438 6.624zM25.437 34.624c0-12.063 9.813-21.875 21.875-21.875 12.063 0 21.876 9.813 21.876 21.875 0 5.938-2.376 11.313-6.25 15.25v-15.25C62.938 26 55.938 19 47.312 19c-8.624 0-15.624 7-15.624 15.625v15.25c-3.875-3.938-6.25-9.313-6.25-15.25z"
                    />
                  </svg>
                </div>

                <div className="text-center px-3  pt-3 ">
                  <div className="col_1 header_4">
                    Simply select from plumbing, electrical, general DIY,
                    building & fitting furniture, installation support or quote
                    checking & independent advice
                  </div>
                </div>
              </div>
              {/* step_2 */}
              <div className="col d-flex flex-column justify-content-start align-content-center mb-4">
                <div className="text-center px-3 py-3 shadow card_Steps">
                  <div className="header_1">02</div>
                  <div className="header_3">Describe Your Problem</div>
                </div>
                <div className="shadow py-4 d-flex flex-row justify-content-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="150"
                    height="150"
                    fill="none"
                    viewBox="0 0 150 150"
                  >
                    <path
                      fill="#FFC700"
                      d="M106.25 75C89 75 75 89 75 106.25s14 31.25 31.25 31.25 31.25-14 31.25-31.25S123.5 75 106.25 75zm6.25-56.25H92.625C90 11.5 83.125 6.25 75 6.25s-15 5.25-17.625 12.5H37.5c-6.875 0-12.5 5.625-12.5 12.5V125c0 6.875 5.625 12.5 12.5 12.5h38.188C72 133.938 69 129.688 66.811 125H37.5V31.25H50V50h50V31.25h12.5V63c4.438.625 8.625 1.938 12.5 3.75v-35.5c0-6.875-5.625-12.5-12.5-12.5zM75 31.25c-3.438 0-6.25-2.813-6.25-6.25 0-3.438 2.813-6.25 6.25-6.25 3.438 0 6.25 2.813 6.25 6.25 0 3.438-2.813 6.25-6.25 6.25z"
                    />
                    <path
                      fill="#1B1C39"
                      d="M108.482 95.851l4.023-4.022c1.333-1.334 2-2 2.828-2 .829 0 1.495.666 2.829 2l1.009 1.01c1.334 1.333 2 2 2 2.828 0 .828-.666 1.495-2 2.828l-3.986 3.987a18.827 18.827 0 01-6.703-6.63zM107.028 97.306L94.523 109.81c-.425.426-.638.638-.777.899-.14.261-.2.556-.317 1.145l-1.282 6.411c-.067.332-.1.499-.005.593.094.095.26.062.593-.005l6.41-1.282c.59-.118.885-.177 1.146-.317.261-.139.473-.352.898-.777l12.538-12.537a20.834 20.834 0 01-6.699-6.634z"
                    />
                  </svg>
                </div>
                <div className="text-center px-3 pt-3">
                  <div className="col_1 header_4">
                    Help us help you! Provide a description and upload photos of
                    your problem so that our Advisers can be prepared before the
                    video call
                  </div>
                </div>
              </div>
              {/* step_3 */}
              <div className="col d-flex flex-column justify-content-start align-content-center mb-4">
                <div className="text-center px-3 py-3 shadow card_Steps">
                  <div className="header_1">03</div>
                  <div className="header_3"> Book Your Timeslot </div>
                </div>
                <div className="shadow py-4 d-flex flex-row justify-content-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="150"
                    height="150"
                    fill="none"
                    viewBox="0 0 150 150"
                  >
                    <path
                      fill="#FFC700"
                      d="M106.25 75C89 75 75 89 75 106.25s14 31.25 31.25 31.25 31.25-14 31.25-31.25S123.5 75 106.25 75zm10.312 45.938L103.125 107.5v-20h6.25v17.438l11.563 11.562-4.376 4.438zM112.5 18.75H92.625c8.125 0 8.125 0 0 0 8.125 0-8.125 0 0 0H37.5c-6.875 0-12.5 5.625-12.5 12.5V125c0 6.875 5.625 12.5 12.5 12.5h38.188C72 133.938 69 129.688 66.811 125H37.5V31.25h75V63c4.438.625 8.625 1.938 12.5 3.75v-35.5c0-6.875-5.625-12.5-12.5-12.5zM75 31.25h12.5H75z"
                    />
                    <path
                      fill="#FFC700"
                      stroke="#1B1C39"
                      d="M58.5 7.5h5v16h-5zM87.5 7.5h5v16h-5z"
                    />
                  </svg>
                </div>
                <div className="text-center px-3 pt-3 ">
                  <div className="col_1 header_4">
                    Confirm a 2 hour time slot which is suitable for you
                  </div>
                </div>
              </div>
              {/* step_4 */}
              <div className="col d-flex flex-column mb-4">
                <div className="text-center px-3 py-3 shadow card_Steps">
                  <div className="header_1">04</div>
                  <div className="header_3"> Conduct the Video Call </div>
                </div>
                <div className="shadow py-4 d-flex flex-row justify-content-center">
                  <svg
                    className="icon_steps"
                    xmlns="http://www.w3.org/2000/svg"
                    width="126"
                    height="150"
                    fill="none"
                    viewBox="0 0 126 150"
                  >
                    <path
                      fill="#FFC700"
                      d="M125.5.5H.562L.5 125.5l25-25h100V.5zm-25 75l-25-20v20h-50v-50h50v20l25-20v50z"
                    />
                  </svg>
                </div>
                <div className="text-center px-3 pt-3">
                  <div className="col_1 header_4">
                    Your call will be held via our integrated video system
                  </div>
                </div>
              </div>
              <div className=" col-12 d-flex flex-column justify-content-end align-items-end px-2">
                <a
                  href="https://www.quickassist.uk/HowItWorksCustomer"
                  className="col_3"
                >
                  Click Here for More Details
                </a>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(null, mapDispatchToProps)(HowItsWorks);
