import React, { Component } from "react";
// import Style

import "./ErrorBoundry.Style.css";

export default class Fallback extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
  }

  render() {
    return (
      <div className="error_screen">
        {/* Mobile */}
        <div className="d-block d-sm-none">
          <div className="col_2 w-100">
            <div className="col_2 mb-0 pb-0 header_2">
              Something went <span className="col_3 fw-bold">wrong</span> !!!
            </div>
            <div className="col_2 header_4">
              we are very sorry for inconvenience. It looks you are trying to
              access a page that either has been deleted or never been created
            </div>
            <div className="col_3 my-4 w-100 ">
              <a
                href="./"
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                <span className="btn btn-warning p-2 col_4">Back to Home</span>
              </a>
            </div>
          </div>
        </div>
        {/* Desktop */}
        <div className="d-none d-sm-block">
          <div className="col_2 w-50">
            <div className="col_2 mb-0 pb-0 header_2">
              Something went <span className="col_3 fw-bold">wrong</span> !!!
            </div>
            <div className="col_2 headre_4">
              we are very sorry for inconvenience. It looks you are trying to
              access a page that either has been deleted or never been created
            </div>
            <div className="col_3 my-4">
              <a
                href="./"
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                <span className="btn btn-warning p-2 w-25 col_4">
                  Back to Home
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
