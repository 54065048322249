import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";

// Import validate function
import { VALIDATION_EMAIL } from "./validate";

export default class Popup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      buttonStatus: false,
      email: "",
      timeStamp: `${new Date().toISOString()}`,
      validation: { email: "" },
    };
  }

  handleChange = (e) => {
    this.setState({
      email: e.target.value.trim().toLowerCase(),
      validation: { email: "" },
    });
  };

  handleSubmit = () => {
    if (this.state.email === "") {
      this.setState({ validation: { email: "Email address Missing" } });
      return;
    } else if (!VALIDATION_EMAIL(this.state.email)) {
      this.setState({
        validation: { email: "Invalid Email address" },
      });
      return;
    } else {
      this.setState({
        buttonStatus: true,
      });
      axios
        .post(
          "https://qa-uk-api.herokuapp.com/quick_assist/newsletter",
          {
            email: this.state.email,
          },
          {
            headers: {
              Authorization: `Basic ${this.props.API_KEY}`,
            },
          }
        )
        .then((response) => {
          this.setState({
            buttonStatus: false,
            validation: { email: "" },
          });
          let name = "_QA";
          let value = "subscribed";
          let days = 7 * 24 * 60;
          this.props.handleSetCookie(name, value, days);
          this.handleModal();
        })
        .catch((error) => {
          this.setState({
            buttonStatus: false,
            validation: { email: "Error Please try later" },
          });
        });
    }
  };

  handleModalClose = () => {
    let name = "_QA";
    let value = "notsubscribed";
    let days = 1 * 24 * 60;
    this.props.handleSetCookie(name, value, days);
    this.handleModal();
  };

  handleModal = () => {
    this.setState({ modalShow: !this.state.modalShow }, () => {
      if (!this.props.modalShow) {
        this.setState({
          email: "",
        });
      }
    });
  };

  componentDidMount() {
    if (this.props.handleGetCookie("_QA") == null) {
      setTimeout(this.handleModal, 10000);
    }
  }

  render() {
    return (
      <div>
        <Modal show={this.state.modalShow} centered keyboard={false}>
          {/* mobile */}
          <Modal.Body className="popup d-block d-sm-none  ">
            <h3 className=" d-flex flex-column justified-content-end align-items-end col_3">
              <i
                class="bi bi-x-circle text-right"
                onClick={this.handleModalClose}
              ></i>
            </h3>
            <div className="  px-2 py-2 d-flex flex-column justified-content-center align-items-center">
              <h1 className="text-center popup_Header_mobile w-100">
                Welcome!
              </h1>
              <h4 className="text-center col_1 w-100 mb-4">
                Sign up now to receive our latest <br />
                news and special offers!
              </h4>
              <input
                type="email"
                placeholder="Your Email Address"
                className="form-control popup_input_style"
                value={this.state.email}
                onChange={this.handleChange}
              />
              <button
                className="btn btn-warning  my-2 w-50 px-2"
                disabled={this.state.buttonStatus}
                onClick={this.handleSubmit}
              >
                Submit
              </button>

              {this.state.validation.email !== "" ? (
                <div className="form-label col_3 my-2 text-left">
                  {this.state.validation.email}
                </div>
              ) : null}
              <h4 className="form-label col_1 my-2  text-center w-100">
                Your email is safe with us. Don't worry we don't spam :)
              </h4>
            </div>
          </Modal.Body>
          {/* Desktop */}
          <Modal.Body className="popup d-none d-sm-block ">
            <h3 className=" d-flex flex-column justified-content-end align-items-end col_3">
              <i
                class="bi bi-x-circle text-right"
                onClick={this.handleModalClose}
              ></i>
            </h3>
            <div className=" px-2 d-flex flex-column justified-content-center align-items-center">
              <h1 className="text-left popup_Header">Welcome!</h1>
              <h3 className="text-left col_1 w-100">
                Sign up now to receive our latest <br />
                news and special offers!
              </h3>

              <div className="input-group w-100 pt-2 mx-auto">
                <input
                  type="email"
                  className="form-control popup_input_style"
                  placeholder="Your Email Address"
                  aria-describedby="button-addon2"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
                <button
                  className="btn btn-warning w-25"
                  type="button"
                  id="button-addon2"
                  disabled={this.state.buttonStatus}
                  onClick={this.handleSubmit}
                >
                  Submit
                </button>
              </div>
              {this.state.validation.email !== "" ? (
                <div className="form-label col_3 my-2 text-left">
                  {this.state.validation.email}
                </div>
              ) : null}

              <h5 className="form-label col_1 my-2  text-left w-100">
                Your email is safe with us. Don't worry we don't spam :)
              </h5>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
