import React, { Component, Fragment } from "react";
import { Modal } from "react-bootstrap";

export default class ResponseModal extends Component {
  render() {
    return (
      <Modal
        show={this.props.modalShow}
        onHide={this.props.handleModal}
        centered
      >
        {this.props.loader ? (
          <div className="d-flex flex-column justified-content-center align-items-center p-4 modal_bg">
            <div className="spinner-border text-warning p-4" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : this.props.orderCancelMode ? (
          <Fragment>
            <div className="mx-2 py-3">Cancel Order Request</div>
            <div className="mx-2 py-1">
              <input
                type="text"
                className="form-control input_style col bg-light w-100"
                id="confirmJobCode"
                value={this.props.confirmJobCode}
                onChange={this.props.handleChange}
              />
            </div>
            <h5 className="mx-2 py-1">Please Enter the Job Code to confirm</h5>
            <div className="d-flex flex-row justify-content-end  py-1 mx-2">
              <div></div>
              <div
                className="btn btn-warning w-25 my-1"
                onClick={this.props.handleCancelJob}
              >
                Submit
              </div>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <Modal.Header closeButton>
              <Modal.Title> {this.props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4>{this.props.message}</h4>
            </Modal.Body>
          </Fragment>
        )}
      </Modal>
    );
  }
}
