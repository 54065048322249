import React, { Component, Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
import axios from "axios";

import { DATA } from "../Services/data";

// Redux
import { connect } from "react-redux";
import {
  setLandingPageHeader,
  setPageCategory,
  setFaqCategory,
  setLogin,
  setApiKey,
} from "../../redux/store";
import MenuBar from "./MenuBar";
import FollowUs from "./FollowUs";

// Import redux
const mapStateToProps = (state) => {
  return {
    LANDING_PAGE_SECTION: state.LANDING_PAGE_SECTION,
    LOGIN_STATUS: state.LOGIN_STATUS,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLandingPageHeader: (value) => {
      dispatch(setLandingPageHeader(value));
    },
    setPageCategory: (value) => {
      dispatch(setPageCategory(value));
    },
    setFaqCategory: (value) => {
      dispatch(setFaqCategory(value));
    },
    setLogin: () => {
      dispatch(setLogin(false));
    },
    setApiKey: (data) => {
      dispatch(setApiKey(data));
    },
  };
};

function withRouter(Children) {
  return (props) => {
    let { pathname } = useLocation();
    let location = pathname.split("/")[1];
    return <Children {...props} location={location} />;
  };
}

class NavText extends Component {
  render() {
    return (
      <Link
        to={`/${this.props.route}`}
        style={{ color: "inherit", textDecoration: "inherit" }}
      >
        <span className="NavText px-1 col_2">{this.props.name}</span>
      </Link>
    );
  }
}
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "",
      menu: false,
    };
  }

  handleHover = (menu) => {
    this.setState({ mode: menu });
  };
  handleNavigation = (value) => {
    this.props.setLandingPageHeader(true);
    if (value !== "") {
      this.props.setPageCategory(value);
    }
  };
  handleFAQNavigation = (value) => {
    this.props.setFaqCategory(value);
  };
  handleLogout = () => {
    this.props.setLogin();
    if (this.props.location !== "employee") {
      return <Navigate to={`/${this.props.location}`} replace={true} />;
    }
  };

  componentDidMount() {
    axios
      .get("https://qa-uk-api.herokuapp.com/quick_assist/api")
      .then((response) => {
        this.props.setApiKey(response.data.key);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  render() {
    if (this.props.location === "orderCard") return null;
    return (
      <div
        id="Header"
        className="header"
        onMouseLeave={() => this.handleHover("")}
      >
        {/* Mobile */}

        <div className="d-block d-sm-none">
          <nav className=" row mx-0 justify-content-between  align-items-center ">
            <div className="col-8 py-1 d-flex flex-row justify-content-start align-items-center logoMobile">
              <Link
                to="/"
                style={{
                  color: "inherit",
                  textDecoration: "inherit",
                }}
                className="d-flex flex-row justify-content-start align-items-center px-0"
              >
                <img
                  src="/Logo/logo.png"
                  alt="Quick Assist Logo"
                  width="50px"
                />
              </Link>
            </div>
            {this.props.location === "" || this.props.location === "service" ? (
              <FollowUs />
            ) : (
              <MenuBar />
            )}
          </nav>
        </div>

        {/* Desktop */}
        <div className="d-none d-sm-block">
          <nav className=" row mx-0  ">
            <div className=" col-sm-3 col-5  py-1 d-flex flex-row justify-content-start align-self-center logo">
              <Link
                to="/"
                style={{ color: "inherit", textDecoration: "inherit" }}
                className="d-flex flex-row justify-content-start align-items-center px-0"
              >
                <img src="/Logo/logo.svg" alt="Quick Assist Logo" height="40" />
              </Link>
            </div>
            {this.props.LANDING_PAGE_SECTION ? (
              //  Header-onload
              <div className=" col-sm-9  col-7 px-4 d-flex flex-row justify-content-end align-self-center ">
                {this.props.location !== "" ? (
                  <div
                    className="px-1 text-center NavText pointer col_1  px-4 py-2 "
                    onMouseEnter={() => this.handleHover("")}
                  >
                    <Link
                      to="/"
                      style={{ color: "inherit", textDecoration: "inherit" }}
                    >
                      Home
                    </Link>
                  </div>
                ) : (
                  <div
                    className="px-1 text-center NavText  px-4 py-2 "
                    onMouseEnter={() => this.handleHover("")}
                  >
                    <div style={{ color: "#1b1c39" }}>Home</div>
                  </div>
                )}
                <div
                  id="blog"
                  className={
                    this.props.location !== "blog"
                      ? "text-center NavText pointer col_1 px-4 py-2"
                      : "text-center NavText pointer col_3 px-4 py-2"
                  }
                  onMouseEnter={() => this.handleHover("blog")}
                >
                  <Link
                    to="/blog"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    Blogs
                  </Link>
                </div>

                <div
                  id="service"
                  className={
                    this.props.location !== "service"
                      ? "text-center NavText pointer col_1 px-4 py-2"
                      : "text-center NavText pointer col_3 px-4 py-2"
                  }
                  onMouseEnter={() => this.handleHover("service")}
                >
                  Our Services
                </div>
                <div
                  id="faq"
                  className={
                    this.props.location !== "faqConsumer" &&
                    this.props.location !== "faqAdviser"
                      ? "text-center NavText pointer col_1 px-4 py-2"
                      : "text-center NavText pointer col_3 px-4 py-2"
                  }
                  onMouseEnter={() => this.handleHover("faq")}
                >
                  FAQs
                </div>
                <div
                  className={
                    this.props.location === ""
                      ? "text-center NavText pointer col_1 bg-warning py-2 px-3 mx-1"
                      : this.props.location === "newapplicant"
                      ? "text-center NavText pointer col_3  py-2 px-3 mx-1"
                      : "text-center NavText pointer col_1  py-2 px-3 mx-1"
                  }
                  onMouseEnter={() => this.handleHover("")}
                >
                  <Link
                    to="/newapplicant"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    Register as an Adviser
                  </Link>
                </div>
                {this.props.LOGIN_STATUS ? (
                  <div
                    className={
                      this.props.location === ""
                        ? "text-center NavText pointer col_1 bg-warning py-2 px-3"
                        : this.props.location === "consumer"
                        ? "text-center NavText pointer col_3  py-2 px-3"
                        : "text-center NavText pointer col_1  py-2 px-3"
                    }
                    onClick={this.handleLogout}
                  >
                    Logout
                  </div>
                ) : (
                  <div
                    className={
                      this.props.location === ""
                        ? "text-center NavText pointer col_1 bg-warning py-2 px-3"
                        : this.props.location === "consumer"
                        ? "text-center NavText pointer col_3  py-2 px-3"
                        : "text-center NavText pointer col_1  py-2 px-3"
                    }
                    onMouseEnter={() => this.handleHover("login")}
                  >
                    Access your Booking
                  </div>
                )}
              </div>
            ) : (
              //  Header-onScroll

              <div className="col-sm-9 col-7 px-4 d-flex flex-row justify-content-end align-self-center">
                <div
                  id="service"
                  className={
                    this.props.location !== "service"
                      ? "text-center NavText pointer col_1 px-4 py-2"
                      : "text-center NavText pointer col_3 px-4 py-2"
                  }
                  onMouseEnter={() => this.handleHover("service")}
                >
                  Our Services
                </div>
              </div>
            )}
          </nav>
          {this.state.mode === "" ? null : this.state.mode === "service" ? (
            <nav className=" row header_dropdown justify-content-end py-2 px-4 mx-0">
              {DATA.map((item) => {
                return (
                  <div
                    className=" col-4 p-2 text-center"
                    key={item.serviceCode}
                    onClick={() => this.handleNavigation(item.serviceCode)}
                  >
                    <Link
                      to={`/service?id=${item.serviceCode}`}
                      style={{ color: "inherit", textDecoration: "inherit" }}
                      className="d-flex flex-row justify-content-start align-items-center"
                    >
                      <img
                        src={item.logo_url}
                        alt={item.serviceCode}
                        width="50px"
                        height="50px"
                      />
                      <div className="px-1"> {item.title}</div>
                    </Link>
                  </div>
                );
              })}
            </nav>
          ) : this.state.mode === "faq" ? (
            <nav className=" row header_dropdown justify-content-end py-2 mx-0">
              <div className="col-9 px-4 d-flex flex-row justify-content-center align-self-center   ">
                <div
                  className="px-4 text-center"
                  onClick={() => this.handleFAQNavigation("Consumer")}
                >
                  <Link
                    to="/faqConsumer"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    Customer FAQ
                  </Link>
                </div>
                <div
                  className="px-1 text-center"
                  onClick={() => this.handleFAQNavigation("Adviser")}
                >
                  <Link
                    to="/faqAdviser"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    Adviser FAQ
                  </Link>
                </div>
              </div>
            </nav>
          ) : this.state.mode === "login" ? (
            <nav className=" row header_dropdown justify-content-end py-2 mx-0">
              <div className="col-md-6 col-lg-6 col-xl-5 px-4 d-flex flex-row justify-content-end align-self-center  menu ">
                <div className="d-flex flex-row justify-content-start align-items-center">
                  <img
                    src="/ServiceCardImg/Customer.png"
                    alt="Quick Assist Customer"
                    width="50px"
                    height="50px"
                  />
                  <NavText name="Customer" route="consumer" />
                </div>
                <div className="d-flex flex-row justify-content-start align-items-center ps-4">
                  <img
                    src="/ServiceCardImg/Adviser.png"
                    alt="Quick Assist Adviser"
                    width="50px"
                    height="50px"
                  />
                  <NavText name="Adviser" route="adviser" />
                </div>
              </div>
            </nav>
          ) : null}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
