import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { DATA } from "../Services/data";

const SliderComponent = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    swipeToSlide: true,
    autoplaySpeed: 10000,
    arrows: false,
    initialSlide: 3,
  };
  const handleRedirect = (url) => {
    window.location.href = url;
  };

  return (
    <Slider {...settings}>
      {DATA.map((content) => {
        return (
          <div
            key={content.serviceCode}
            onClick={() => handleRedirect(`/service?id=${content.serviceCode}`)}
            className="d-flex flex-column  justify-content-center  align-items-center"
          >
            <img src={content.icon} width="100px" className="mx-2" />
            <div
              className="col_3 py-2 text-wrap header_5"
              style={{ width: "100px" }}
            >
              {content.title}
            </div>
          </div>
        );
      })}
    </Slider>
  );
};

export default SliderComponent;
