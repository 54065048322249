import React, { Component } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
// import blog Data
import { BLOG_DATA } from "../Components/Blogs/BlogData";
import BlogCard from "../Components/Blogs/BlogCard";

function withRouter(Children) {
  return (props) => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const query = params.get("id");
    return <Children {...props} id={query} />;
  };
}

class BlogUpdated extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogIndex: 1,
      selectedData: {},
    };
  }

  handleRedirect = (id) => {
    let url = "/blog?id=" + id;
    window.location.href = url;
  };
  handleBlogContent = () => {
    let index = 0;
    if (
      parseInt(this.props.id) >= 1 &&
      parseInt(this.props.id) <= BLOG_DATA.length
    ) {
      index = this.props.id;
    } else {
      index = 0;
    }
    this.setState({ blogIndex: index }, () => {
      if (index !== 0) {
        let blog = BLOG_DATA.filter(
          (data) => data.id === parseInt(this.state.blogIndex)
        );
        this.setState({
          selectedData: blog[0],
        });
      }
    });
  };

  componentDidMount() {
    this.handleBlogContent();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.id !== this.props.id) {
      this.handleBlogContent();
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }
  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Blogs </title>
          <meta name="description" content="Quick Assist Blogs" />
          <link rel="canonical" href="https://www.quickassist.uk/blog" />
        </Helmet>

        <div className="home_section_faq row mx-0 d-flex flex-column justified-content-center ">
          {this.state.blogIndex !== 0 ? (
            <div className="header_faq row mx-0 parallax_service">
              <div className="col-md-12 col-lg-9 col-xl-7 p-4 mx-auto text-center captions d-flex flex-column justified-content-center align-self-center">
                <h1 className="col_1"> {this.state.selectedData.title}</h1>
                <h4 className="col_1">
                  Posted on {this.state.selectedData.date}
                </h4>
              </div>
            </div>
          ) : (
            <div className="header_faq row mx-0 parallax_service">
              <div className="col-md-12 col-lg-9 col-xl-7 p-4 mx-auto text-center captions d-flex flex-column justified-content-center align-self-center">
                <h1 className="col_1"> Welcome to Our Blogs</h1>
                <h4 className="col_1">Tips, Tricks and Exciting News</h4>
              </div>
            </div>
          )}

          <div className="row bg-white mx-0">
            {this.state.blogIndex !== 0 ? (
              <>
                <div className="col-sm-12 col-lg-7 col-xl-7 px-4 pt-4 mx-auto  captions d-flex flex-column justified-content-start align-self-start">
                  <div
                    className="col_2 py-2"
                    dangerouslySetInnerHTML={{
                      __html: this.state.selectedData.content,
                    }}
                  />
                </div>
                <div className="col-sm-12 col-lg-7 col-xl-7 py-2 mx-auto fw-bold text-center">
                  Read Some More Intresting Blogs
                </div>
              </>
            ) : null}

            {/* Desktop */}
            <div className="col-sm-12 col-lg-7 col-xl-7 mx-auto mb-2 p-2  d-none d-sm-block">
              <div className="row g-4">
                {BLOG_DATA.map((data) => {
                  return data.id !== parseInt(this.state.blogIndex) ? (
                    <BlogCard
                      data={data}
                      size={6}
                      handleRedirect={this.handleRedirect}
                    />
                  ) : null;
                })}
              </div>
            </div>

            {/* Mobile */}
            <div className="col-sm-12 col-lg-7 col-xl-7 mx-auto mb-2 p-2 d-block d-sm-none">
              <div className="row g-4">
                {BLOG_DATA.map((data) => {
                  return data.id !== parseInt(this.state.blogIndex) ? (
                    <BlogCard
                      data={data}
                      size={12}
                      handleRedirect={this.handleRedirect}
                    />
                  ) : null;
                })}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(BlogUpdated);
