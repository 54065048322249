import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

// redux
import { Provider } from "react-redux";
import { store } from "./redux/store";

// Style
import "./index.css";
import AppDesktop from "./App";

const root = document.getElementById("root");
ReactDOM.render(
  <div>
    <BrowserRouter>
      <Provider store={store}>
        <AppDesktop />
      </Provider>
    </BrowserRouter>
  </div>,
  root
);
