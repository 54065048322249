import React, { Component, Fragment } from "react";
import axios from "axios";

// Import validate function
import { VALIDATION_EMAIL } from "./validate";

// Import Component
import ResponseModal from "../Modal/ResponseModal";

// Redux
import { connect } from "react-redux";
import { setSubscription } from "../../redux/store";

const mapStateToProps = (state) => {
  return {
    API_KEY: state.API_KEY,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSubscription: (data) => {
      dispatch(setSubscription(data));
    },
  };
};

class NewsLetter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      timeStamp: `${new Date().toISOString()}`,
      message: "",
      modalShow: false,
      loader: false,
      validation: { email: "" },
    };
  }
  handleChange = (e) => {
    this.setState({
      email: e.target.value.trim().toLowerCase(),
      validation: { email: "" },
    });
  };

  handleModal = () => {
    this.setState({ modalShow: !this.state.modalShow }, () => {
      if (!this.props.modalShow) {
        this.setState({
          message: "",
          title: "",
        });
      }
    });
  };

  handleSubmit = () => {
    if (this.state.email === "") {
      this.setState({ validation: { email: "Email address Missing" } });

      return;
    } else if (!VALIDATION_EMAIL(this.state.email)) {
      this.setState({
        validation: { email: "Invalid Email address" },
      });
      return;
    } else {
      this.handleModal();
      this.setState({
        loader: !this.state.loader,
      });
      axios
        .post(
          "https://qa-uk-api.herokuapp.com/quick_assist/newsletter",
          {
            email: this.state.email,
          },
          {
            headers: {
              Authorization: `Basic ${this.props.API_KEY}`,
            },
          }
        )
        .then((response) => {
          this.props.setSubscription(this.state);
          this.setState(
            {
              loader: !this.state.loader,
            },
            () => {
              this.setState({
                email: "",
                message: response.data.message,
                title: response.data.title,
              });
            }
          );
        })
        .catch((error) => {
          this.setState({
            loader: !this.state.loader,
            message: error.response.data.message,
            title: error.response.data.title,
          });
        });
    }
  };

  render() {
    return (
      <Fragment>
        <div className=" row justify-content-center mx-0 newsletter  ">
          {/* Heading and Sub heading */}
          <div className="col-md-12 col-lg-6 col-xl-6 px-4 mb-4 pb-4 mx-auto text-center captions">
            <div className="header_1"> Newsletter</div>
            <div className="mb-4 header_3">
              Be in the know with the helpful tips and tricks!
            </div>
            {/* Content */}
            {/* mobile */}
            <div className="card pt-4 px-4 pb-2 d-block d-sm-none">
              <div for="basic-url" className="form-label col_1 my-2 header_5">
                No spam we promise :)
              </div>
              <div className="input-group pt-2">
                <input
                  type="email"
                  className="form-control input_style"
                  placeholder="Email Address"
                  aria-describedby="button-addon2"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
                <button
                  className="btn btn-warning w-25"
                  type="button"
                  id="button-addon2"
                  onClick={this.handleSubmit}
                >
                  <i class="bi bi-send"></i>
                </button>
              </div>
              <label for="basic-url" className="form-label col_3 my-2">
                {this.state.validation.email}
              </label>
            </div>

            {/* Desktop */}
            <div className="card px-4 pt-4 pb-2  d-none d-sm-block">
              <div for="basic-url" className="form-label col_1 my-2">
                No spam we promise :)
              </div>
              <div className="input-group pt-2">
                <input
                  type="email"
                  className="form-control input_style"
                  placeholder="Email Address"
                  aria-describedby="button-addon2"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
                <button
                  className="btn btn-warning w-25"
                  type="button"
                  id="button-addon2"
                  onClick={this.handleSubmit}
                >
                  Submit
                </button>
              </div>
              <label for="basic-url" className="form-label col_3 my-2">
                {this.state.validation.email}
              </label>
            </div>
          </div>
        </div>
        {this.state.modalShow ? (
          <ResponseModal
            handleModal={this.handleModal}
            modalShow={this.state.modalShow}
            loader={this.state.loader}
            message={this.state.message}
            title={this.state.title}
          />
        ) : null}
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsLetter);
