import React, { Component, Fragment } from "react";
import { Navigate } from "react-router-dom";
import { motion } from "framer-motion/dist/framer-motion";

// import the service data
import { DATA } from "../Services/data";
import SliderComponent from "./Slider";

// Redux
import { connect } from "react-redux";
import { setLandingPageHeader, setPageCategory } from "../../redux/store";

const mapStateToProps = (state) => {
  return {
    USER_DATA: state.USER_DATA,
    PAGE_CATEGORY: state.PAGE_CATEGORY,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLandingPageHeader: (value) => {
      dispatch(setLandingPageHeader(value));
    },
    setPageCategory: (value) => {
      dispatch(setPageCategory(value));
    },
  };
};

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectTo: false,
      id: DATA[0].serviceCode,
    };
  }
  handleNavigate = (e) => {
    if (e.target.value == null) {
      return;
    }
    this.setState({ id: e.target.value }, () => {
      this.setState({ redirectTo: true });
    });
  };

  render() {
    return (
      <Fragment>
        {this.state.redirectTo && (
          <Navigate to={`/service?id=${this.state.id}`} />
        )}
        {/* Desktop */}
        <div className="breadCrump py-sm-1 py-4 col-12 d-none d-sm-block ">
          {""}
        </div>

        {/* Mobile */}
        <motion.div
          className="col-12 mx-0 px-0 home_banner_mobile d-block d-sm-none img-fluid"
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.3, type: "tween" }}
        >
          {""}
        </motion.div>

        {/* Desktop */}
        <motion.div
          className="col-md-6 col-lg-6 col-xl-7 home_banner  d-none d-sm-block"
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.3, type: "tween" }}
        >
          {""}
        </motion.div>

        {/* Mobile */}
        <motion.div
          className="col-12 mx-0 px-2 d-flex flex-column justify-content-start align-items-end d-block d-sm-none "
          initial={{ x: +2000 }}
          animate={{ x: 0 }}
          transition={{ duration: 0.6, type: "tween" }}
        >
          <div className="row mx-0  px-2 d-flex flex-column justify-content-center no-gutters">
            <div className="col-12 pb-2 mx-0 px-0 text-center">
              <div className="mb-3">
                <div
                  className="pb-2 font-weight-normal col_3 header_2"
                  id="home"
                >
                  Quick Assist
                </div>
                <h1>{this.props.title}</h1>
                <h3>{this.props.sub_title}</h3>
              </div>
            </div>

            {this.props.page === "Home" ? null : (
              <div className="col-12 mx-auto px-0 card_wrapper_mobile d-flex flex-row justify-content-center ">
                <div className="card w-100 px-3 py-1">
                  <div class="my-2">
                    <label className="col form-label col_1 ">
                      {this.props.form_title_1}
                    </label>
                    <input
                      type="text"
                      className="form-control input_style"
                      id="username"
                      placeholder={this.props.placeholder_1}
                      value={this.props.data.username}
                      onChange={this.props.handleChange}
                      onBlur={this.props.handleBlur}
                    />
                    <h5 className="form-label col_3 text-end my-1">
                      {this.props.data.validation.username}
                    </h5>
                  </div>
                  <div class="my-1">
                    <label className="form-label col_1 mx-0 px-0">
                      {this.props.form_title_2}
                    </label>

                    <input
                      type="password"
                      className="form-control input_style mb-2"
                      id="password"
                      placeholder={this.props.placeholder_2}
                      value={this.props.data.password}
                      onChange={this.props.handleChange}
                      onBlur={this.props.handleBlur}
                    />

                    {this.props.page === "Consumer" ? (
                      <Fragment>
                        <h5 className="form-label col_3 mx-0 text-end my-1">
                          {this.props.data.validation.password}
                        </h5>
                      </Fragment>
                    ) : (
                      <h5
                        for="username"
                        className="form-label col_3 mx-0 text-end my-1"
                      >
                        {this.props.data.validation.password}
                      </h5>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>

          {this.props.page !== "Home" ? (
            <div className=" col-12 d-flex flex-row justify-content-end  my-2 pb-2 px-2">
              <button
                className="btn btn-warning shadow  py-2 w-50"
                type="button"
                onClick={this.props.handleSubmit}
              >
                Login
              </button>
            </div>
          ) : (
            <div className="col-12 text-center mb-2 mx-0 px-4">
              <div className="pb-2 font-weight-normal col_3 header_2">
                Select the Trade
              </div>
              <SliderComponent />
            </div>
          )}
        </motion.div>

        {/* Desktop */}
        <motion.div
          className=" col-md-6 col-lg-6 col-xl-5 px-4  d-flex flex-column justify-content-end  d-none d-sm-block"
          initial={{ x: +2000 }}
          animate={{ x: 0 }}
          transition={{ duration: 0.6, type: "tween" }}
        >
          <div className="Outside_Wrapper_normal row d-flex flex-column justify-content-end  align-item-end mb-3 ">
            <div className="col-12 py-2 top_margin">
              <div className="mb-3">
                <div className="pb-2 col_3 header_2" id="home">
                  Quick Assist
                </div>
                <h1>{this.props.title}</h1>
                <h3>{this.props.sub_title}</h3>
              </div>
            </div>

            {this.props.page === "Home" ? (
              <div className="col-12 card_wrapper d-flex flex-column justify-content-end">
                <div className="card w-100 p-4">
                  <div class="my-3">
                    <h4> To begin, select the trade you need help with</h4>
                    <select
                      className="form-select mb-2"
                      aria-label="Default select example"
                      onChange={this.handleNavigate}
                    >
                      <option value={null}>Select here...</option>
                      {DATA.map((item) => {
                        return (
                          <option value={item.serviceCode}>{item.title}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-12 card_wrapper d-flex flex-row justify-content-end ">
                <div className="card w-100 p-4">
                  <div class=" my-1">
                    <label
                      for="username"
                      className="form-label col_1 mx-0 px-0"
                    >
                      {this.props.form_title_1}
                    </label>

                    <input
                      type="text"
                      className="form-control input_style mb-1"
                      id="username"
                      placeholder={this.props.placeholder_1}
                      value={this.props.data.username}
                      onChange={this.props.handleChange}
                      onBlur={this.props.handleBlur}
                    />
                    <label for="username" className="form-label col_3 my-0">
                      {this.props.data.validation.username}
                    </label>
                  </div>
                  <div class="mb-1">
                    <label
                      for="username"
                      className="form-label col_1 mx-0 px-0"
                    >
                      {this.props.form_title_2}
                    </label>
                    <input
                      type="password"
                      className="form-control input_style mb-1"
                      id="password"
                      placeholder={this.props.placeholder_2}
                      value={this.props.data.password}
                      onChange={this.props.handleChange}
                      onBlur={this.props.handleBlur}
                    />
                    <label for="username" className="form-label col_3 px-0">
                      {this.props.data.validation.password}
                    </label>
                  </div>
                </div>
              </div>
            )}
          </div>

          {this.props.page !== "Home" ? (
            <div className=" d-flex flex-row justify-content-end  mb-4 pb-4">
              <button
                className="btn btn-warning shadow w-50 py-3 "
                type="button"
                onClick={this.props.handleSubmit}
              >
                Login
              </button>
            </div>
          ) : (
            <div className=" d-flex flex-row justify-content-end  align-items-end mb-4 pb-4">
              <div className=" btn w-100 py-3  text-end col_4"></div>
            </div>
          )}
        </motion.div>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
