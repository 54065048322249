import React, { Component } from "react";
import { Helmet } from "react-helmet";

export default class Disclamier extends Component {
  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Disclaimer</title>
          <meta
            name="description"
            content="Disclaimer to keep everyone safe while using the Quick Assist platform "
          />
          <link rel="canonical" href="https://www.quickassist.uk/disclaimer" />
        </Helmet>

        <div className="home_section_faq row mx-0 d-flex flex-column justified-content-center ">
          <div className="header_faq row mx-0 parallax_service">
            <div className="col-md-12 col-lg-9 col-xl-7 px-4 mb-4 pb-4 mx-auto text-center captions d-flex flex-column justified-content-center align-self-center">
              <h1 className="col_1"> Disclaimer</h1>
              <h4 className="col_1">
                Your use of the Platform and your reliance on any information on
                the Platform is solely at your own risk, which you agree to by
                using the Platform.
              </h4>
            </div>
          </div>
          <div className="row bg-white mx-0">
            <div className="col-sm-12 col-lg-7 col-xl-7 px-4 mb-4 pb-4 mx-auto  captions d-flex flex-column justified-content-start align-self-start">
              The information provided by LLMK Limited trading as Quick Assist
              (hereinafter also referred to as "we," "us" or "our") and the
              professional advisers (“Advisers”) on www.quickassist.uk and/or
              electronic devices and communication systems such as video,
              messages and phone calls (together known as the “Platform”) are
              for general informational purposes only. All information
              (including that provided by Advisers) on the Platform is provided
              in good faith, however we make no representation or provide
              warranty of any kind, express or implied, regarding the accuracy,
              adequacy, validity, reliability, availability or completeness of
              any information provided on the Platform or through Advisers via
              any forms of communication. Under no circumstance shall we or our
              Advisers have any liability to you for any loss or damage of any
              kind incurred as a result of using the Platform. Your use of the
              Platform and your reliance on any information on the Platform is
              solely at your own risk.
            </div>
          </div>
        </div>
      </>
    );
  }
}
