export const blog_6 = {
  title: "Plumbeing Problem",
  data: `<div class="WordSection1">

  <p class="MsoNormal"><span lang="EN-GB">If you are having problems with your
  boiler, struggling with a toilet that does not flush, or any other
  plumbing-related issue, then your first thought might be to call a plumber.
  However, with expensive call-out fees and hourly rates, you don’t want to waste
  money and time calling out a plumber to visit your property when the fix is
  simple enough to do yourself when guided by a qualified tradesman over video
  chat.</span></p>
  
  <div style="margin:0cm"><a name="_kywe0q9kx6sl"></a><b><span lang="EN-GB" style="font-size:11.0pt;line-height:115%">Why choose a virtual plumber?</span></b></div>
  
  <p class="MsoNormal"><span lang="EN-GB">Some plumbing problems will be serious and
  require a plumber to visit your property, but many of the plumbing issues we
  face are relatively simple to fix if you have the right guidance. A virtual
  plumber is there as the first port of call for plumbing issues, as they will
  likely be able to diagnose the issue via video call and provide advice on how
  to solve it. They will be able to explain whether the problem is something you
  could fix yourself in a few minutes or if a qualified plumber will need to be
  called out for a visit.</span></p>
  
  <p class="MsoNormal"><span lang="EN-GB">A virtual plumber will also save you time.
  Plumbers can be very busy, and unless you want to pay an emergency fee, they
  may take a few days to visit your property to look at the problem. In contrast,
  a virtual plumber could find the fault in minutes thanks to video chat
  technology similar to that which underpins Zoom, Google Meet, and Skype. During
  the call, the plumber will provide clear guidance to help you fix the problem
  yourself within 30 minutes, or if the task is too complex and they’re local,
  then they will provide you a quote for a callout to fix the issue in-person. There
  is no more waiting and much lower fees – just £30 for a 30-minute video call.</span></p>
  
  <div style="margin:0cm"><b><span lang="EN-GB" style="font-size:11.0pt;line-height:
  115%">How do you book a virtual plumber?</span></b></div>
  
  <p class="MsoNormal"><span lang="EN-GB">Booking a video call with a virtual plumber
  could not be easier with Quick Assist. You simply describe the plumbing issue
  you are facing using our online form with the help of some photos if required,
  and book a video call at a time to suit you. Our advisors will then access the
  issue and assign the best person for the job. You’ll receive an email
  containing a joining link to use at the specified time of the call. </span></p>
  
  <p class="MsoNormal"><span lang="EN-GB">When it is time for the video call, just
  click the video call link in the confirmation email from your smartphone and
  you will instantly be connected to an expert plumber to help you diagnose and fix
  your plumbing problems.</span></p>
  
  <div style="margin:0cm"><a name="_459fo6hz7ai4"></a><b><span lang="EN-GB" style="font-size:11.0pt;line-height:115%">What if I need a call-out?</span></b></div>
  
  <p class="MsoNormal"><span lang="EN-GB">Not all problems will be fixable by even
  the keenest DIY enthusiast. In these cases, the trusted plumber we connect you
  with will diagnose the issue during your video call and should be able to provide
  a quote for a member of their team to visit your home. If the call-out occurs,
  with the problem already diagnosed, the team will arrive equipped and ready to
  fix the issue as quickly as possible and they will even knock £15 off their
  callout fee!<a name="_15tln1hhhmod"></a></span></p>
  
  <p class="MsoNormal"><b><span lang="EN-GB">What sort of problems can be fixed via
  video chat?</span></b></p>
  
  <p class="MsoNormal"><span lang="EN-GB">A wide variety of plumbing problems can be
  fixed with the help of a 30-minute video chat with a qualified plumber, from
  central heating and radiator problems to low-pressure showers and toilets with
  flushing issues. A virtual plumber can also help you understand what your
  boiler error code means and explain how to reset your boiler or bleed your
  radiators to make sure your central heating is working as efficiently as
  possible.</span></p>
  
  <p class="MsoNormal"><span lang="EN-GB">Here are some of the problems our qualified
  plumbers can help fix or explain via a quick video call:</span></p>
  
  <p class="MsoNormal"><a name="_u36wonmc74mw"></a><b><span lang="EN-GB">Central
  heating problems sorted</span></b></p>
  
  <p class="MsoNormal" style="margin-left:36.0pt;text-indent:-18.0pt"><span lang="EN-GB">●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">Why do I have no hot water?</span></p>
  
  <p class="MsoNormal" style="margin-left:36.0pt;text-indent:-18.0pt"><span lang="EN-GB">●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">Why is my heating not working?</span></p>
  
  <p class="MsoNormal" style="margin-left:36.0pt;text-indent:-18.0pt"><span lang="EN-GB">●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">How to find and fix leaks</span></p>
  
  <p class="MsoNormal"><a name="_div8wuyft9jfq"></a><b><span lang="EN-GB">Radiator
  problems fixed and explained</span></b></p>
  
  <p class="MsoNormal" style="margin-left:36.0pt;text-indent:-18.0pt"><span lang="EN-GB">●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">Why are there cold patches on my radiators? Why
  are some radiators cold despite the central heating being on?</span></p>
  
  <p class="MsoNormal" style="margin-left:36.0pt;text-indent:-18.0pt"><span lang="EN-GB">●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">How to change the temperature on a radiator</span></p>
  
  <p class="MsoNormal" style="margin-left:36.0pt;text-indent:-18.0pt"><span lang="EN-GB">●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">How to bleed a radiator</span></p>
  
  <p class="MsoNormal"><a name="_my6k1c2z1rfd"></a><b><span lang="EN-GB">Boiler
  questions answered</span></b></p>
  
  <p class="MsoNormal" style="margin-left:36.0pt;text-indent:-18.0pt"><span lang="EN-GB">●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">How to reset your boiler</span></p>
  
  <p class="MsoNormal" style="margin-left:36.0pt;text-indent:-18.0pt"><span lang="EN-GB">●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">What your boiler error codes mean</span></p>
  
  <p class="MsoNormal" style="margin-left:72.0pt;text-indent:-18.0pt"><span lang="EN-GB">○<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">E110, E125, E131 – overheating, bleed radiators
  and reset required</span></p>
  
  <p class="MsoNormal" style="margin-left:72.0pt;text-indent:-18.0pt"><span lang="EN-GB">○<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">E119 – low pressure, water top-up required</span></p>
  
  <p class="MsoNormal" style="margin-left:72.0pt;text-indent:-18.0pt"><span lang="EN-GB">○<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">E133 – boiler failed to ignite, reset required</span></p>
  
  <p class="MsoNormal" style="margin-left:72.0pt;text-indent:-18.0pt"><span lang="EN-GB">○<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">Hundreds of other brand-specific error codes</span></p>
  
  <p class="MsoNormal" style="margin-left:36.0pt;text-indent:-18.0pt"><span lang="EN-GB">●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">Help with your Worcester, Bosch, Vaillant,
  Keston, Greenstar, Ideal, Baxi or other boiler</span></p>
  
  <p class="MsoNormal" style="margin-left:36.0pt;text-indent:-18.0pt"><span lang="EN-GB">●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">What the boiler lights represent</span></p>
  
  <p class="MsoNormal" style="margin-left:36.0pt;text-indent:-18.0pt"><span lang="EN-GB">●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">What are the strange sounds coming from my
  boiler?</span></p>
  
  <p class="MsoNormal"><a name="_hepmdwodyjdg"></a><b><span lang="EN-GB">Toilet
  issues resolved</span></b></p>
  
  <p class="MsoNormal" style="margin-left:36.0pt;text-indent:-18.0pt"><span lang="EN-GB">●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">How to fix a toilet that will not flush</span></p>
  
  <p class="MsoNormal" style="margin-left:36.0pt;text-indent:-18.0pt"><span lang="EN-GB">●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">How to unclog or unblock a toilet</span></p>
  
  <p class="MsoNormal" style="margin-left:36.0pt;text-indent:-18.0pt"><span lang="EN-GB">●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">How to affix a loose toilet handle</span></p>
  
  <p class="MsoNormal" style="margin-left:36.0pt;text-indent:-18.0pt"><span lang="EN-GB">●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">How to replace a toilet handle</span></p>
  
  <p class="MsoNormal"><a name="_7grwsevdxd79"></a><b><span lang="EN-GB">Thermostatic
  shower difficulties diffused</span></b></p>
  
  <p class="MsoNormal" style="margin-left:36.0pt;text-indent:-18.0pt"><span lang="EN-GB">●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">Why is my shower cold?</span></p>
  
  <p class="MsoNormal" style="margin-left:36.0pt;text-indent:-18.0pt"><span lang="EN-GB">●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">Why does my shower have low pressure?</span></p>
  
  <p class="MsoNormal" style="margin-left:36.0pt;text-indent:-18.0pt"><span lang="EN-GB">●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">Why does my shower not drain?</span></p>
  
  <p class="MsoNormal"><span lang="EN-GB">&nbsp;</span></p>
  
  <p class="MsoNormal"><span lang="EN-GB">If you are experiencing problems with your
  home’s plumbing, then why not try Quick Assist’s videochat platform and connect
  with a local plumber for advice and support?</span></p>
  
  </div>`,
};

export const blog_5 = {
  title: "Clean Your Windows",
  data: `<div class="WordSection1">

  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">Summer is the
  ideal season to tackle window cleaning, both inside and out. With the warm
  weather and abundant sunshine, it's the perfect time to let the natural light
  flood into your home through sparkling clean windows. In this article, we'll provide
  you with a comprehensive guide on how to clean your windows effectively,
  ensuring a brighter and more inviting living space. Get ready to let the
  sunshine in and transform the look and feel of your home.</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">&nbsp;</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><b><span lang="EN-GB">The Benefits
  of Clean Windows: Enhancing Your Living Space</span></b></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">Clean windows
  offer numerous benefits beyond their aesthetic appeal. They allow natural light
  to fill your home, creating a bright and uplifting atmosphere. Clear windows
  also provide unobstructed views of the outside world, improving your connection
  with nature and enhancing the overall ambiance of your living space.</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">&nbsp;</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><b><span lang="EN-GB">Gather Your
  Window Cleaning Tools:</span></b></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">Before you begin,
  gather the necessary tools to make the window cleaning process efficient and
  effective. Some essential tools include a bucket, squeegee, microfiber cloth or
  lint-free towels, a window cleaning solution (or DIY solution), a sponge or
  soft bristle brush, and a ladder (if needed for higher windows).</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">&nbsp;</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><b><span lang="EN-GB">Preparing the
  Window Cleaning Solution:</span></b></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">You can choose to
  purchase a commercial window cleaning solution or create a DIY solution using
  readily available ingredients. A simple DIY solution consists of mixing equal
  parts of water and vinegar, or water and liquid washing up liquid.
  Alternatively, you can use glass cleaner. Avoid using abrasive cleaners or
  harsh chemicals that can damage the glass or window frames.</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">&nbsp;</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><b><span lang="EN-GB">Cleaning the
  Windows: Step-by-Step Process</span></b></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">Here's a
  step-by-step process to clean your windows effectively:</span></p>
  
  <p class="MsoListParagraphCxSpFirst" style="text-indent:-18.0pt"><span lang="EN-GB">a.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">Start by removing any dirt, dust, or cobwebs
  from the window frames and sills using a sponge or soft bristle brush.</span></p>
  
  <p class="MsoListParagraphCxSpMiddle" style="text-indent:-18.0pt"><span lang="EN-GB">b.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">Prepare your cleaning solution in a bucket and
  dip the sponge or cloth into the solution.</span></p>
  
  <p class="MsoListParagraphCxSpMiddle" style="text-indent:-18.0pt"><span lang="EN-GB">c.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">Begin cleaning the window surface, starting from
  the top and working your way down. Use horizontal strokes for inside windows
  and vertical strokes for outside windows to easily identify streaks.</span></p>
  
  <p class="MsoListParagraphCxSpMiddle" style="text-indent:-18.0pt"><span lang="EN-GB">d.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">After applying the cleaning solution, use a
  squeegee to remove excess liquid, starting from the top and pulling down in a
  straight line.</span></p>
  
  <p class="MsoListParagraphCxSpMiddle" style="text-indent:-18.0pt"><span lang="EN-GB">e.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">Wipe the squeegee blade with a lint-free cloth
  after each stroke to avoid transferring dirt or streaks.</span></p>
  
  <p class="MsoListParagraphCxSpMiddle" style="text-indent:-18.0pt"><span lang="EN-GB">f.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">For smaller or hard-to-reach areas, use a
  microfiber cloth or lint-free towel to wipe the window surface, ensuring a
  streak-free finish.</span></p>
  
  <p class="MsoListParagraphCxSpLast" style="margin-bottom:0cm;text-indent:-18.0pt"><span lang="EN-GB">g.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">Repeat the process for each window, refreshing
  the cleaning solution as needed.</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">&nbsp;</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><b><span lang="EN-GB">Finishing
  Touches and Additional Tips:</span></b></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">To achieve a
  flawless finish, pay attention to the following tips:</span></p>
  
  <p class="MsoListParagraphCxSpFirst" style="text-indent:-18.0pt"><span lang="EN-GB">a.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">Remove any remaining streaks by wiping the glass
  with a dry microfiber cloth or lint-free towel.</span></p>
  
  <p class="MsoListParagraphCxSpMiddle" style="text-indent:-18.0pt"><span lang="EN-GB">b.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">Clean the window frames and sills using a damp
  cloth or sponge.</span></p>
  
  <p class="MsoListParagraphCxSpMiddle" style="text-indent:-18.0pt"><span lang="EN-GB">c.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">If you encounter stubborn stains or marks,
  consider using a glass scraper or a mixture of baking soda and water for gentle
  scrubbing.</span></p>
  
  <p class="MsoListParagraphCxSpLast" style="text-indent:-18.0pt"><span lang="EN-GB">d.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB">Schedule regular window cleaning sessions to maintain the cleanliness
  and clarity of your windows.</span></p>
  
  <p class="MsoNormal"><span lang="EN-GB">Summer is the perfect time to embrace the
  task of window cleaning, allowing the sun to shine through your newly cleaned
  windows and brighten up your home. By following the step-by-step guide and
  using the right tools and cleaning solutions, you can achieve streak-free,
  crystal-clear windows that enhance your living space and create a refreshing
  ambiance. Prepare to let the sunshine in and enjoy a brighter, more inviting
  home. Not to forget that if you do come across an issue with your windows when
  you are cleaning them that you can get professional advice through Quick Assist.
  </span></p>
  
  </div>`,
};
export const blog_4 = {
  title: "Enhance Outdoor Lighting",
  data: `<div class="WordSection1">

  <p class="MsoNormal"><span lang="EN-GB">The right outdoor lighting can transform
  your outdoor spaces into captivating retreats and extend the enjoyment of your
  evenings. By installing energy-efficient LED lights, you can create a welcoming
  ambiance, illuminate pathways, highlight the beauty of your landscape, and add
  charm to your outdoor gatherings. In this article, we'll explore the benefits
  of enhancing your outdoor lighting and provide tips on how to create a
  mesmerizing atmosphere that will make your warm summer nights truly unforgettable.</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><b><span lang="EN-GB">The Power of
  Outdoor Lighting: Setting the Mood</span></b></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">Outdoor lighting
  serves both functional and aesthetic purposes. It improves visibility and
  safety by illuminating pathways, steps, and entrances. Moreover, carefully
  placed lighting fixtures can create a warm and inviting atmosphere, enhancing
  the beauty of your outdoor spaces and making them more enjoyable during
  evenings. LED lights are an excellent choice for outdoor lighting due to their
  energy efficiency, longevity, and versatility.</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">&nbsp;</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><b><span lang="EN-GB">Pathway
  Illumination: Guiding the Way</span></b></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">Illuminate your
  pathways and walkways with strategically placed LED lights. This not only
  ensures safe navigation but also adds a touch of elegance to your outdoor area.
  Consider using low-voltage LED path lights or in-ground well lights to create a
  subtle and magical ambiance along the paths. Choose warm white or soft yellow
  LED bulbs to create a cosy and inviting glow.</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">&nbsp;</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><b><span lang="EN-GB">Accentuating
  Landscape Features: Highlighting the Beauty</span></b></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">Enhance the
  beauty of your landscape by using outdoor lighting to highlight key features.
  Consider uplighting trees, shrubs, or architectural elements to create dramatic
  silhouettes against the night sky. Use spotlights or floodlights with
  adjustable heads to direct the light precisely where you want it. Experiment
  with different angles and intensities to achieve the desired effect.</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">&nbsp;</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><b><span lang="EN-GB">Creating
  Outdoor Entertainment Zones: Adding Charm and Functionality</span></b></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">Extend your
  living space into the outdoors by creating well-lit entertainment zones.
  Install LED string lights or decorative lanterns to add a magical touch to
  dining areas, seating areas, or pergolas. These charming lighting fixtures not
  only create a warm and cosy atmosphere but also provide functional lighting for
  activities and gatherings.</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">&nbsp;</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><b><span lang="EN-GB">Energy
  Efficiency and Longevity: Benefits of LED Lighting</span></b></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">LED lights are an
  environmentally friendly and cost-effective choice for outdoor lighting. They
  consume significantly less energy than traditional lighting options, resulting
  in lower energy bills. LED bulbs also have a longer lifespan, reducing the need
  for frequent replacements. Additionally, LED lights emit very little heat,
  making them safer to use in outdoor settings.</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">&nbsp;</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><b><span lang="EN-GB">Proper
  Installation and Maintenance: Ensuring Optimal Performance</span></b></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">To maximize the
  benefits of your outdoor lighting, proper installation and regular maintenance
  are essential. Consider a Quick Assist expert to guide you through the
  installation, ensuring your safety. Perform routine checks to ensure that the
  lights are functioning correctly, replace any burnt-out bulbs promptly, and
  clean the fixtures to maintain their optimal performance.</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">&nbsp;</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">By enhancing your
  outdoor lighting with energy-efficient LED lights, you can transform your
  outdoor spaces into captivating retreats that provide comfort, safety, and
  ambiance. From illuminating pathways to highlighting landscape features and
  creating enchanting entertainment zones, LED lighting offers endless
  possibilities. Embrace the beauty of your outdoor areas and make your warm
  summer evenings truly magical with the perfect combination of illumination and
  charm.</span></p>
  
  </div>`,
};

export const blog_3 = {
  title: "Protect Against Pests",
  data: `<div class="WordSection1">

  <p class="MsoNormal"><span lang="EN-GB">As the temperature rises and summer
  arrives, unwanted pests such as ants, wasps, flies, and mosquitoes become more
  prevalent. These pests can disrupt your peace of mind and potentially cause
  health and hygiene issues. In this article, we'll provide you with essential
  tips and strategies to protect your home from summer pests. By implementing
  these measures, you can create a pest-free environment and enjoy a comfortable
  and relaxing summer season.</span></p>
  
  <p class="MsoListParagraphCxSpFirst" style="margin-top:0cm;margin-right:0cm;
  margin-bottom:0cm;margin-left:18.0pt;text-indent:-18.0pt"><b><span lang="EN-GB">1.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span lang="EN-GB">Seal Cracks and Entry Points:</span></b></p>
  
  <p class="MsoListParagraphCxSpLast" style="margin-top:0cm;margin-right:0cm;
  margin-bottom:0cm;margin-left:18.0pt"><span lang="EN-GB">Pests can enter your home
  through tiny cracks and openings. Conduct a thorough inspection of your
  property, paying attention to areas such as windows, doors, vents, and
  foundation gaps. Seal any visible cracks and openings using caulk or
  weatherstripping. This simple step can significantly reduce the chances of
  pests finding their way inside.</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">&nbsp;</span></p>
  
  <p class="MsoListParagraph" style="margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:18.0pt;text-indent:-18.0pt"><b><span lang="EN-GB">2.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span lang="EN-GB">Repair Screens and Install Door Sweeps:</span></b></p>
  
  <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
  margin-left:18.0pt"><span lang="EN-GB">Keep pests out by ensuring that your
  window screens are intact and free from tears or holes. Repair or replace
  damaged screens promptly to prevent insects from entering your home.
  Additionally, installing door sweeps at the bottom of exterior doors helps
  create a barrier against crawling pests like ants and cockroaches.</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">&nbsp;</span></p>
  
  <p class="MsoListParagraphCxSpFirst" style="margin-top:0cm;margin-right:0cm;
  margin-bottom:0cm;margin-left:18.0pt;text-indent:-18.0pt"><b><span lang="EN-GB">3.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span lang="EN-GB">Eliminate Standing Water:</span></b></p>
  
  <p class="MsoListParagraphCxSpLast" style="margin-top:0cm;margin-right:0cm;
  margin-bottom:0cm;margin-left:18.0pt"><span lang="EN-GB">Standing water is a
  breeding ground for mosquitoes and other insects. Regularly check your property
  for any sources of standing water, such as clogged gutters, flowerpots,
  birdbaths, or unused containers. Remove or empty them to prevent mosquitoes
  from laying eggs. Consider using mosquito dunks or adding fish to ornamental
  ponds to control mosquito larvae.</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">&nbsp;</span></p>
  
  <p class="MsoListParagraphCxSpFirst" style="margin-top:0cm;margin-right:0cm;
  margin-bottom:0cm;margin-left:18.0pt;text-indent:-18.0pt"><b><span lang="EN-GB">4.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span lang="EN-GB">Maintain Cleanliness and Proper Waste Management:</span></b></p>
  
  <p class="MsoListParagraphCxSpMiddle" style="margin-top:0cm;margin-right:0cm;
  margin-bottom:0cm;margin-left:18.0pt"><span lang="EN-GB">Pests are attracted to
  food sources, so it's crucial to maintain cleanliness and proper waste
  management practices. Keep your kitchen and dining areas clean, promptly store
  food in sealed containers, and regularly empty and clean garbage bins. This
  reduces the availability of food sources and discourages pests from entering
  your home.</span></p>
  
  <p class="MsoListParagraphCxSpMiddle" style="margin-top:0cm;margin-right:0cm;
  margin-bottom:0cm;margin-left:18.0pt"><span lang="EN-GB">&nbsp;</span></p>
  
  <p class="MsoListParagraphCxSpMiddle" style="margin-top:0cm;margin-right:0cm;
  margin-bottom:0cm;margin-left:18.0pt;text-indent:-18.0pt"><b><span lang="EN-GB">5.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span lang="EN-GB">Use Natural Repellents and DIY Solutions:</span></b></p>
  
  <p class="MsoListParagraphCxSpMiddle" style="margin-top:0cm;margin-right:0cm;
  margin-bottom:0cm;margin-left:18.0pt"><span lang="EN-GB">Instead of relying
  solely on chemical pesticides, explore natural repellents and DIY solutions to
  keep pests at bay. For instance, plant insect-repellent herbs like basil, mint,
  or lavender around your home's entry points. Essential oils such as citronella,
  lemon eucalyptus, or peppermint can also deter insects. Additionally, create
  DIY traps using vinegar, sugar, and dish soap for fruit flies or sticky traps
  for flying insects.</span></p>
  
  <p class="MsoListParagraphCxSpMiddle" style="margin-top:0cm;margin-right:0cm;
  margin-bottom:0cm;margin-left:18.0pt"><span lang="EN-GB">&nbsp;</span></p>
  
  <p class="MsoListParagraphCxSpMiddle" style="margin-top:0cm;margin-right:0cm;
  margin-bottom:0cm;margin-left:18.0pt;text-indent:-18.0pt"><b><span lang="EN-GB">6.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span lang="EN-GB">Seek Professional Pest Control Services:</span></b></p>
  
  <p class="MsoListParagraphCxSpMiddle" style="margin-top:0cm;margin-right:0cm;
  margin-bottom:0cm;margin-left:18.0pt"><span lang="EN-GB">If your pest infestation
  becomes unmanageable or if you prefer a comprehensive and long-term solution,
  it's advisable to seek professional pest control services. Professional pest
  exterminators have the knowledge, experience, and tools to effectively identify
  and eliminate pests, ensuring a pest-free environment for your home.</span></p>
  
  <p class="MsoListParagraphCxSpMiddle" style="margin-top:0cm;margin-right:0cm;
  margin-bottom:0cm;margin-left:18.0pt"><span lang="EN-GB">&nbsp;</span></p>
  
  <p class="MsoListParagraphCxSpLast" style="margin-top:0cm;margin-right:0cm;
  margin-bottom:0cm;margin-left:18.0pt"><span lang="EN-GB">With the right
  preventative measures and strategies, you can protect your home from unwanted
  summer pests. By sealing entry points, eliminating standing water, maintaining
  cleanliness, and exploring natural repellents, you can significantly reduce the
  presence of pests in and around your home. Remember, if the infestation becomes
  overwhelming, don't hesitate to seek professional pest control assistance.
  Enjoy a pest-free summer and create a comfortable environment for you and your
  family.</span></p>
  
  </div>`,
};
export const blog_2 = {
  title: "Check & Clean Gutters",
  data: `<div class="WordSection1">

  <p class="MsoNormal"><span lang="EN-GB">Regular maintenance of your gutters is
  crucial to ensure proper water flow and protect your home from potential
  damage. Neglected gutters can lead to issues such as foundation damage,
  interior flooding, or landscape erosion. In this article, we'll provide you
  with an easy-to-follow guide on how to check and clean your gutters
  effectively, ensuring a well-maintained home that can withstand any weather
  conditions.</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><b><span lang="EN-GB">The Importance
  of Gutter Maintenance: Preventing Costly Damages</span></b></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">Gutters play a
  vital role in directing rainwater away from your home's foundation, preventing
  water accumulation that could lead to structural issues. Regular maintenance
  helps avoid potential problems such as water damage, mould growth, and
  compromised integrity of your property. By keeping your gutters clean and free
  from debris, you ensure proper water flow to protect your investment.</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">&nbsp;</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><b><span lang="EN-GB">Step-by-Step
  Guide: Checking Your Gutters for Issues</span></b></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">Start by visually
  inspecting your gutters for signs of damage, such as cracks, rust, or sagging.
  Look for any loose or detached components, including gutter brackets or
  downspouts. Check for proper alignment and ensure that water flows freely
  without any obstructions. By identifying issues early on, you can prevent further
  damage and address necessary repairs promptly.</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">&nbsp;</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><b><span lang="EN-GB">How to Clean
  Your Gutters: DIY Maintenance Made Easy</span></b></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">Cleaning your
  gutters is a crucial task that should be performed at least twice a year,
  ideally in spring and autumn. Here’s a step-by-step process to help you get
  started:</span></p>
  
  <p class="MsoListParagraphCxSpFirst" style="text-indent:-18.0pt"><span lang="EN-GB">a)<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">Gather the necessary tools: gloves, a ladder, a
  bucket, a garden trowel or scoop, and a garden hose with a spray nozzle.</span></p>
  
  <p class="MsoListParagraphCxSpMiddle" style="text-indent:-18.0pt"><span lang="EN-GB">b)<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">Safely position your ladder near the gutter area
  you plan to clean.</span></p>
  
  <p class="MsoListParagraphCxSpMiddle" style="text-indent:-18.0pt"><span lang="EN-GB">c)<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">Begin by removing large debris, such as leaves or
  twigs, using the trowel or scoop. Place the debris in the bucket for easy
  disposal.</span></p>
  
  <p class="MsoListParagraphCxSpMiddle" style="text-indent:-18.0pt"><span lang="EN-GB">d)<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">After clearing the visible debris, flush out
  remaining residue with a garden hose. Start from one end of the gutter and work
  your way to the other, ensuring water flows smoothly.</span></p>
  
  <p class="MsoListParagraphCxSpMiddle" style="text-indent:-18.0pt"><span lang="EN-GB">e)<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span lang="EN-GB">Pay attention to downspouts, removing any clogs
  by running water through them or using a plumber’s snake.</span></p>
  
  <p class="MsoListParagraphCxSpLast" style="text-indent:-18.0pt"><span lang="EN-GB">f)<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB">Finally, inspect your gutters again to ensure they are clean and
  functioning properly.</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><b><span lang="EN-GB">Safety
  Precautions: Staying Safe During Gutter Maintenance</span></b></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">When working on
  gutters, it’s important to prioritize safety. Use a sturdy ladder and make sure
  it is placed on level ground. Wear gloves to protect your hands from debris and
  consider using safety goggles and a dust mask to shield your eyes and lungs. If
  you feel unsure about performing gutter maintenance, book a call with one of
  the Quick Assist experts to guide you through it.</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">&nbsp;</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><b><span lang="EN-GB">Regular
  Maintenance Schedule: Keeping Your Gutters in Top Shape</span></b></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">To maintain your
  gutters’ functionality and prevent major issues, establish a regular
  maintenance schedule. Cleaning twice a year is a general recommendation, however
  the frequency may vary depending on your location and the surrounding
  environment. For example, areas with heavy foliage may require more frequent cleaning
  to prevent clogs. </span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">&nbsp;</span></p>
  
  <p class="MsoNormal"><span lang="EN-GB">Taking the time to check and clean your
  gutters is a crucial part of home maintenance that should not be overlooked. By
  following this comprehensive guide, you can ensure that your gutters remain
  free from debris, allowing water to flow smoothly and protecting your home from
  potential damages. Regular maintenance will contribute to the longevity of your
  gutters and the overall well-being of your property.</span></p>
  
  </div>`,
};

export const blog_1 = {
  title: "Refresh Your Home with a Coat of Paint",
  data: `<div class="WordSection1">

  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">When it comes to
  revitalizing your living spaces, nothing compares to the transformative power
  of a fresh coat of paint. Whether you're looking to update a single room or
  completely overhaul your home's interior, painting is a cost-effective and
  impactful way to breathe new life into your surroundings. In this article,
  we'll explore the benefits of refreshing your home with paint, provide tips for
  a successful paint project, and guide you through the process of choosing colours
  that will enhance your space and create a welcoming atmosphere.</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">&nbsp;</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><b><span lang="EN-GB">The Power of
  Paint: Enhancing Your Home's Aesthetic Appeal</span></b></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">A fresh coat of
  paint has the remarkable ability to completely change the look and feel of a
  room. It can create a sense of tranquillity, add depth and dimension, or make a
  bold statement with vibrant hues. By refreshing your home's walls, ceilings,
  and trim, you can update outdated colour schemes, cover up imperfections, and
  create a cohesive design that reflects your personal style.</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">&nbsp;</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><b><span lang="EN-GB">Planning Your
  Paint Project: Tips for Success</span></b></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">Before diving
  into a paint project, proper planning is essential to ensure a smooth and
  satisfying result. Start by assessing the condition of your walls, preparing
  them for paint, and gathering the necessary supplies. Take measurements to
  determine the amount of paint you'll need and consider factors such as natural
  lighting and existing decor to guide your colour choices. Additionally, don't
  forget to protect your furniture and floors during the painting process.</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">&nbsp;</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><b><span lang="EN-GB">Choosing the
  Right Colours: Setting the Mood</span></b></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">The colour
  palette you select for your home has a significant impact on the ambiance and
  mood you want to create in each room. Consider the purpose of the space, your
  personal preferences, and the desired atmosphere. Opt for calming blues and
  greens in bedrooms for a soothing retreat, vibrant yellows in kitchens for an
  energetic feel, or neutral tones in common areas to create a versatile backdrop
  for various decor styles. Don't hesitate to explore different shades and
  experiment with accent walls or complementary colour schemes to add visual
  interest.</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">&nbsp;</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><b><span lang="EN-GB">DIY vs. Hiring
  Professionals: Making the Right Choice</span></b></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">Deciding whether
  to tackle the paint project yourself or hire professionals depends on various
  factors. DIY painting can be a rewarding and budget-friendly option, while
  larger projects or complex techniques may require the expertise and precision
  of professional painters. Consider your time, skill level, and the level of
  detail required to make an informed decision. Quick Assist can always help
  guide you through areas you might be stuck on, however professional painters
  can complete those jobs that are more complex or in more dangerous areas – such
  as at heights. </span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">&nbsp;</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><b><span lang="EN-GB">Maintenance
  and Longevity: Preserving Your Painted Spaces</span></b></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">To keep your
  newly painted spaces looking fresh and vibrant for years to come, proper
  maintenance is key. Regular cleaning with mild detergent and a soft cloth,
  avoiding harsh chemicals or abrasive materials, will help maintain the integrity
  of the paint. Touching up scuffs or marks promptly and periodically inspecting
  for any signs of wear or fading will ensure your painted surfaces continue to
  impress.</span></p>
  
  <p class="MsoNormal" style="margin-bottom:0cm"><span lang="EN-GB">&nbsp;</span></p>
  
  <p class="MsoNormal"><span lang="EN-GB">Refresh your home this summer with a coat
  of paint and experience the incredible transformation it can bring. From
  choosing the perfect colours to proper planning and execution, a paint project
  allows you to customize your living spaces and create an environment that truly
  reflects your style and personality. Whether you embark on a DIY adventure or
  enlist the help of professionals, the result will be a rejuvenated home that
  you can enjoy for years to come.</span></p>
  
  </div>`,
};
