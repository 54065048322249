import React, { Component, Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import { DATA } from "../Services/data";
import { connect } from "react-redux";
import {
  setLandingPageHeader,
  setPageCategory,
  setFaqCategory,
  setLogin,
  setApiKey,
} from "../../redux/store";

// Import redux
const mapStateToProps = (state) => {
  return {
    LANDING_PAGE_SECTION: state.LANDING_PAGE_SECTION,
    LOGIN_STATUS: state.LOGIN_STATUS,
  };
};
function withRouter(Children) {
  return (props) => {
    let { pathname } = useLocation();
    let location = pathname.split("/")[1];
    return <Children {...props} location={location} />;
  };
}
class NavCard extends Component {
  render() {
    return (
      <div
        className="col-12 py-2 px-4 col_2 bg-light my-1"
        onClick={() => {
          this.props.handleNavigation(this.props.type);
          this.props.handleMenuBar();
        }}
      >
        <Link to={`/${this.props.route}`}>
          <div className="col_2 px-2">{this.props.name}</div>
        </Link>
      </div>
    );
  }
}
class NavCard_mobile extends Component {
  render() {
    return (
      <div
        className="col-12 px-4 col_2 bg-light my-1"
        onClick={() => {
          this.props.handleNavigation(this.props.type);
          this.props.handleMenuBar();
        }}
      >
        <Link
          to={`/${this.props.route}`}
          className="d-flex flex-row justify-content-start align-items-center"
        >
          <img
            src={this.props.logo}
            alt={this.props.name}
            width="50px"
            height="50px"
          />
          <span className="NavText px-1 col_2">{this.props.name}</span>
        </Link>
      </div>
    );
  }
}
class NavCard_menu extends Component {
  render() {
    return (
      <div
        className="col-12 py-2 px-4 col_2 bg-warning my-1 d-flex flex-row justify-content-between"
        onClick={this.props.handleMenuBar}
      >
        <div className="col_2 px-2">{this.props.name}</div>
        {this.props.mode ? (
          <i class="col_2 bi bi-dash"></i>
        ) : (
          <i class="col_2 bi bi-plus"></i>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLandingPageHeader: (value) => {
      dispatch(setLandingPageHeader(value));
    },
    setPageCategory: (value) => {
      dispatch(setPageCategory(value));
    },
    setFaqCategory: (value) => {
      dispatch(setFaqCategory(value));
    },
    setLogin: () => {
      dispatch(setLogin(false));
    },
    setApiKey: (data) => {
      dispatch(setApiKey(data));
    },
  };
};

class MenuBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "",
      menu: false,
    };
  }
  //  For Mobile
  handleMenuBar = () => {
    this.setState({ menu: !this.state.menu });
  };

  handleHover = (menu) => {
    this.setState({ mode: menu });
  };
  handleNavigation = (value) => {
    this.props.setLandingPageHeader(true);
    if (value !== "") {
      this.props.setPageCategory(value);
    }
  };
  handleFAQNavigation = (value) => {
    this.props.setFaqCategory(value);
  };

  render() {
    return (
      <Fragment>
        <div className="col py-1 d-flex flex-row justify-content-end align-items-center logo">
          {this.state.menu ? (
            <div class="bi bi-x-lg header_3" onClick={this.handleMenuBar}></div>
          ) : (
            <div class="bi bi-list header_3" onClick={this.handleMenuBar}></div>
          )}
        </div>
        {this.state.menu ? (
          <Fragment>
            <NavCard
              name="Home"
              route=""
              type=""
              handleNavigation={this.handleNavigation}
              handleMenuBar={this.handleMenuBar}
            />
            <NavCard
              name="About Us"
              route="about"
              type=""
              handleNavigation={this.handleNavigation}
              handleMenuBar={this.handleMenuBar}
            />
            <NavCard
              name="Blogs"
              route="blog"
              type=""
              handleNavigation={this.handleNavigation}
              handleMenuBar={this.handleMenuBar}
            />
            <NavCard
              name="Register as an Adviser"
              route="newapplicant"
              type=""
              handleNavigation={this.handleNavigation}
              handleMenuBar={this.handleMenuBar}
            />
            <NavCard_menu
              name="Our Services"
              mode={this.state.mode}
              handleMenuBar={() =>
                this.handleHover(this.state.mode === "service" ? "" : "service")
              }
            />
            {this.state.mode === "service" ? (
              <Fragment>
                {DATA.map((item) => {
                  return (
                    <NavCard_mobile
                      name={item.title}
                      route={`service?id=${item.serviceCode}`}
                      logo={item.logo_url}
                      type={item.serviceCode}
                      handleNavigation={this.handleNavigation}
                      handleMenuBar={this.handleMenuBar}
                    />
                  );
                })}
              </Fragment>
            ) : null}

            <NavCard_menu
              name="Access your Booking"
              mode={this.state.mode}
              handleMenuBar={() =>
                this.handleHover(this.state.mode === "login" ? "" : "login")
              }
            />
            {this.state.mode === "login" ? (
              <Fragment>
                <NavCard_mobile
                  name="Adviser Account"
                  route="Adviser"
                  logo="/ServiceCardImg/Adviser.png"
                  type=""
                  handleNavigation={this.handleNavigation}
                  handleMenuBar={this.handleMenuBar}
                />
                <NavCard_mobile
                  name="Customer Account"
                  route="consumer"
                  logo="/ServiceCardImg/Customer.png"
                  type=""
                  handleNavigation={this.handleNavigation}
                  handleMenuBar={this.handleMenuBar}
                />
              </Fragment>
            ) : null}
            <NavCard_menu
              name="FAQ"
              mode={this.state.mode}
              handleMenuBar={() =>
                this.handleHover(this.state.mode === "faq" ? "" : "faq")
              }
            />
            {this.state.mode === "faq" ? (
              <Fragment>
                <NavCard
                  name="Customer FAQ"
                  route="faqConsumer"
                  type=""
                  handleNavigation={() => this.handleFAQNavigation("Consumer")}
                  handleMenuBar={this.handleMenuBar}
                />
                <NavCard
                  name="Adviser FAQ"
                  route="faqConsumer"
                  type=""
                  handleNavigation={() => this.handleFAQNavigation("Adviser")}
                  handleMenuBar={this.handleMenuBar}
                />
              </Fragment>
            ) : null}
          </Fragment>
        ) : null}
      </Fragment>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MenuBar));
