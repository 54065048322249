import React, { Component, Fragment } from "react";

import { Link, useLocation } from "react-router-dom";

// Redux
import { connect } from "react-redux";
import { setFaqCategory, setLandingPageHeader } from "../../redux/store";

//  Import the component from Common folder
import Popup from "./Popup.js";

// Import redux
const mapStateToProps = (state) => {
  return {
    LANDING_PAGE_SECTION: state.LANDING_PAGE_SECTION,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setFaqCategory: (value) => {
      dispatch(setFaqCategory(value));
    },
    setLandingPageHeader: (value) => {
      dispatch(setLandingPageHeader(value));
    },
  };
};

function withRouter(Children) {
  return (props) => {
    let { pathname } = useLocation();
    let location = pathname.split("/")[1];
    return <Children {...props} location={location} />;
  };
}

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuBarId: 1,
    };
  }

  handleFAQNavigation = (value) => {
    this.props.setFaqCategory(value);
  };
  handleBar = (id) => {
    this.setState({ menuBarId: id });
  };
  handleNavigation = () => {
    window.scrollTo(0, 0);
  };

  //Handling Cookies
  handleSetCookie = (name, value, days) => {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires;
  };

  handleGetCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  handleEraseCookie = (name) => {
    document.cookie = name + "=; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  };

  componentWillUnmount() {
    let cookies = this.props.handleGetCookie("_QA");
    if (cookies == "notsubscribed") {
      this.handleEraseCookie("_QA");
    }
  }

  render() {
    if (this.props.location === "orderCard") return null;
    return (
      <div
        className="Footer footer-bottom"
        onMouseEnter={() => this.props.setLandingPageHeader(true)}
      >
        {/* For Mobile */}
        <div className="d-block d-sm-none pt-4">
          <div className="w-100 px-4 py-2 border-bottom border-top">
            <div className="d-flex flex-row justify-content-between">
              <h3 className="col_3">About Us</h3>
              <h3
                onClick={() => this.handleBar(1)}
                className={
                  this.state.menuBarId === 1 ? "bi bi-dash" : "bi bi-plus"
                }
              ></h3>
            </div>
            {this.state.menuBarId === 1 ? (
              <div className="col_1 sub_title_normal">
                Quick Assist is your go-to platform to help with your DIY needs.
                Get to know us and find out more about about Quick Assist!{" "}
                <div className="col_3 pointer ">
                  <a
                    href="/about"
                    style={{
                      color: "inherit",
                      textDecoration: "inherit",
                      display: "inline",
                    }}
                  >
                    Click Here
                  </a>
                </div>
              </div>
            ) : null}
          </div>
          <div className="w-100 px-4 py-2 border-bottom">
            <div className="d-flex flex-row justify-content-between">
              <h3 className="col_3">Information</h3>
              <h3
                onClick={() => this.handleBar(2)}
                className={
                  this.state.menuBarId === 2 ? "bi bi-dash" : "bi bi-plus"
                }
              ></h3>
            </div>
            {this.state.menuBarId === 2 ? (
              <Fragment>
                <div className="col_1 sub_title pointer ">
                  <Link
                    to="/faqAdviser"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    Adviser FAQs
                  </Link>
                </div>
                <div className="col_1 sub_title pointer">
                  <Link
                    to="/faqConsumer"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    Customer FAQs
                  </Link>
                </div>
                <div className="col_1 sub_title pointer">
                  {this.props.location !== "" ? (
                    <Link
                      to="/"
                      style={{ color: "inherit", textDecoration: "inherit" }}
                    >
                      Customer
                    </Link>
                  ) : (
                    <div
                      style={{ color: "inherit", textDecoration: "inherit" }}
                      onClick={this.handleNavigation}
                    >
                      Customer
                    </div>
                  )}
                </div>
                <div className="col_1 sub_title pointer">
                  <Link
                    to="/newapplicant"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    Adviser
                  </Link>
                </div>
                <div className="col_1 sub_title pointer">
                  <Link
                    to="/blog"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    Blogs
                  </Link>
                </div>
              </Fragment>
            ) : null}
          </div>
          <div className="w-100 px-4 py-2 border-bottom">
            <div className="d-flex flex-row justify-content-between">
              <h3 className="col_3">The Legals</h3>
              <h3
                onClick={() => this.handleBar(3)}
                className={
                  this.state.menuBarId === 3 ? "bi bi-dash" : "bi bi-plus"
                }
              ></h3>
            </div>
            {this.state.menuBarId === 3 ? (
              <Fragment>
                <div className="col_1 sub_title pointer">
                  <a
                    href="/privacy"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    Privacy Policy
                  </a>
                </div>
                <div className="col_1 sub_title pointer">
                  <a
                    href="/adviserTerms"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    Adviser Terms and Conditions
                  </a>
                </div>
                <div className="col_1 sub_title pointer">
                  <a
                    href="/CustomersTerms"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    Consumer Terms and Conditions
                  </a>
                </div>
                <div className="col_1 sub_title pointer">
                  <a
                    href="/disclaimer"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    Disclaimer
                  </a>
                </div>
                <div className="col_1 sub_title pointer">
                  <a
                    href="/cancellationPolicy"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    Cancellation Policy
                  </a>
                </div>
              </Fragment>
            ) : null}
          </div>
          <div className="w-100 px-4 py-2 border-bottom">
            <div className="d-flex flex-row justify-content-between">
              <h3 className="col_3">Connect With Us</h3>
              <h3
                onClick={() => this.handleBar(4)}
                className={
                  this.state.menuBarId === 4 ? "bi bi-dash" : "bi bi-plus"
                }
              ></h3>
            </div>
            {this.state.menuBarId === 4 ? (
              <Fragment>
                <a
                  className="col_1 sub_title "
                  href="https://www.facebook.com/people/QuickAssistuk/100064909273893/"
                  target={"_blank"}
                >
                  <i className=" pr-4 bi bi-facebook "></i>
                  <span className="px-2">Facebook</span>
                </a>
                <a
                  className="col_1 sub_title "
                  href="https://www.linkedin.com/company/quickassistuk/about/?viewAsMember=true"
                  target={"_blank"}
                >
                  <i className=" pr-4 bi bi-linkedin "></i>
                  <span className="px-2">Linkedin</span>
                </a>
                <a
                  className="col_1 sub_title "
                  href="https://www.instagram.com/quickassist.uk/"
                  target={"_blank"}
                >
                  <i className=" pr-4 bi bi-instagram "></i>
                  <span className="px-2">Instagram</span>
                </a>
                <a
                  className="col_1 sub_title "
                  href="mailto:info@quickassist.uk?subject = Feedback&body = Message"
                >
                  <i className=" pr-4 bi bi-envelope-fill"></i>
                  <span className="px-2">Email</span>
                </a>
              </Fragment>
            ) : null}
          </div>
        </div>

        {/* For Desktop */}
        <div className="d-none d-sm-block">
          <div className="px-4 py-4 d-flex flex-row justify-content-between align-self-center ">
            <div className="w-25">
              <h3 className="col_3">About Us</h3>
              <div className="col_1 sub_title_normal">
                Quick Assist is your go-to platform to help with your DIY needs.
                Get to know us and find out more about about Quick Assist!
                {""}
                <br />
                <span className="col_3 pointer py-2 ">
                  <a
                    href="/about"
                    style={{
                      color: "inherit",
                      textDecoration: "inherit",
                      display: "inline",
                    }}
                  >
                    Click Here
                  </a>
                </span>
              </div>
            </div>
            <div className=" text-center">
              <div className=" text-start">
                <h3 className="col_3">Information</h3>
                <div className="col_1 sub_title pointer ">
                  <Link
                    to="/faqAdviser"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    Adviser FAQs
                  </Link>
                </div>
                <div className="col_1 sub_title">
                  <Link
                    to="/faqConsumer"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    Customer FAQs
                  </Link>
                </div>
                <div className="col_1 sub_title">
                  {this.props.location !== "" ? (
                    <Link
                      to="/"
                      style={{ color: "inherit", textDecoration: "inherit" }}
                    >
                      Customer
                    </Link>
                  ) : (
                    <div
                      style={{ color: "inherit", textDecoration: "inherit" }}
                      onClick={this.handleNavigation}
                    >
                      Customer
                    </div>
                  )}
                </div>
                <div className="col_1 sub_title">
                  <Link
                    to="/newapplicant"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    Adviser
                  </Link>
                </div>
                <div className="col_1 sub_title d-none d-sm-block">
                  <Link
                    to="/blog"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    Blogs
                  </Link>
                </div>
              </div>
            </div>
            <div className=" ">
              <h3 className="col_3">The Legals</h3>
              <div className="col_1 sub_title">
                <a
                  href="/privacy"
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  Privacy Policy
                </a>
              </div>
              <div className="col_1 sub_title">
                <a
                  href="/adviserTerms"
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  Adviser Terms and Conditions
                </a>
              </div>
              <div className="col_1 sub_title">
                <a
                  href="/CustomersTerms"
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  Customer Terms and Conditions
                </a>
              </div>
              <div className="col_1 sub_title">
                <a
                  href="/disclaimer"
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  Disclaimer
                </a>
              </div>
              <div className="col_1 sub_title">
                <a
                  href="/cancellationPolicy"
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  Cancellation Policy
                </a>
              </div>
            </div>
            <div>
              <div className="text-start">
                <h3 className="col_3">Connect With Us</h3>
                <a
                  className="col_1 sub_title  py-1"
                  href="https://www.facebook.com/people/QuickAssistuk/100064909273893/"
                  target={"_blank"}
                >
                  <i className=" pr-4 bi bi-facebook "></i>
                  <span className="px-2">Facebook</span>
                </a>
                <a
                  className="col_1 sub_title  py-1"
                  href="https://www.linkedin.com/company/quickassistuk/about/?viewAsMember=true"
                  target={"_blank"}
                >
                  <i className=" pr-4 bi bi-linkedin "></i>
                  <span className="px-2">Linkedin</span>
                </a>
                <a
                  className="col_1 sub_title  py-1"
                  href="https://www.instagram.com/quickassist.uk/"
                  target={"_blank"}
                >
                  <i className=" pr-4 bi bi-instagram "></i>
                  <span className="px-2">Instagram</span>
                </a>
                <a
                  className="col_1 sub_title  py-1"
                  href="mailto:info@quickassist.uk?subject = Feedback&body = Message"
                >
                  <i className=" pr-4 bi bi-envelope-fill"></i>
                  <span className="px-2">Email</span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row mx-0 border-top border-light py-4 px-2 align-items-start ">
          <h4 className="col-4  col_2 sub_title_normal ">Quick Assist</h4>
          <h4 className="col-8  text-end col_2 sub_title_normal ">
            &copy; 2022-{new Date().getFullYear()}, LLMK Limited.
          </h4>
        </div>
        {this.props.location == "" ? (
          <Popup
            handleSetCookie={this.handleSetCookie}
            handleGetCookie={this.handleGetCookie}
          />
        ) : null}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Footer));
