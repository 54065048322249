import React, { Component, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

//  Pages
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import AdvsiorTerms from "./Pages/AdvsierTerms";
import CustomersTerms from "./Pages/CustomersTerms";
import Disclamier from "./Pages/Disclamier";
import CancellationPolicy from "./Pages/CancellationPolicy";
import About from "./Pages/About";
import Home from "./Pages/Home";
import OrderCard from "./Pages/OrderCard";
import Blogs from "./Pages/Blogs";

// Components
import Header from "./Components/Common/Header";
import Footer from "./Components/Common/Footer";
import FourZeroFour from "./Pages/FourZeroFour";

//  Error Handling
import Fallback from "./Components/ErrorHandle/ErrorBoundary";
import Loading from "./Components/Common/Loading";

const Consumer = React.lazy(() => import("./Pages/Consumer"));
const Adviser = React.lazy(() => import("./Pages/Adviser"));
const Employee = React.lazy(() => import("./Pages/Employee"));
const Service = React.lazy(() => import("./Pages/Service"));
const NewApplicant = React.lazy(() => import("./Pages/NewApplicant"));
const FaqConsumer = React.lazy(() => import("./Pages/FaqConsumer"));
const FaqAdviser = React.lazy(() => import("./Pages/FaqAdviser"));

const HowItWorksCustomer = React.lazy(() =>
  import("./Pages/HowItWorks_Customer")
);
const HowItWorksAdviser = React.lazy(() =>
  import("./Pages/HowItWorks_Adviser")
);
export default class AppDesktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scroll: 0,
    };
  }
  handleScroll = (menu) => {
    this.setState({ scroll: menu });
  };

  myErrorHandler = (error, errorInfo) => {
    console.log("logging", error, errorInfo);
  };

  render() {
    return (
      <ErrorBoundary FallbackComponent={Fallback} onError={this.myErrorHandler}>
        <Suspense fallback={<Loading />}>
          <Header scroll={this.state.scroll} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/orderCard" element={<OrderCard />} />
            <Route path="consumer" element={<Consumer />} />
            <Route path="adviser" element={<Adviser />} />
            <Route path="employee" element={<Employee />} />
            <Route path="service" element={<Service />} />
            <Route path="newapplicant" element={<NewApplicant />} />
            <Route path="about" element={<About />} />
            <Route path="privacy" element={<PrivacyPolicy />} />
            <Route path="adviserTerms" element={<AdvsiorTerms />} />
            <Route path="customersTerms" element={<CustomersTerms />} />
            <Route path="disclaimer" element={<Disclamier />} />
            <Route path="cancellationPolicy" element={<CancellationPolicy />} />
            <Route path="faqConsumer" element={<FaqConsumer />} />
            <Route path="faqAdviser" element={<FaqAdviser />} />
            <Route path="HowItWorksCustomer" element={<HowItWorksCustomer />} />
            <Route path="HowItWorksAdviser" element={<HowItWorksAdviser />} />
            <Route path="blog" element={<Blogs />} />
            <Route path="/404" element={<FourZeroFour />} status={404} />
            <Route
              path="*"
              element={<Navigate replace to="/404" />}
              status={404}
            />
          </Routes>
          <Footer />
        </Suspense>
      </ErrorBoundary>
    );
  }
}
