import React, { Component } from "react";
import { Helmet } from "react-helmet";

export default class About extends Component {
  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>About </title>
          <meta
            name="description"
            content="Quick Assist aims to break the stigma that help from a qualified tradesperson is inevitably expensive and time consuming"
          />
          <link rel="canonical" href="https://www.quickassist.uk/about" />
        </Helmet>
        <div className="home_section_faq row mx-0 d-flex flex-column justified-content-center ">
          <div className="header_faq row mx-0 parallax_service">
            <div className="col-md-12 col-lg-9 col-xl-7 px-4 mb-4 pb-4 mx-auto text-center captions d-flex flex-column justified-content-center align-self-center">
              <h1 className="col_1 py-2"> About Us</h1>
              <h4 className="col_1">
                Quick Assist provides access to tradespeople on demand<br></br>
                £30 for a 30-minute video call with a qualified adviser!
              </h4>
              <h4 className="col_1"></h4>
            </div>
          </div>

          <div className="row bg-white mx-0">
            <div className="col-sm-12 col-lg-7 col-xl-7 px-4 pb-2 mx-auto captions d-flex flex-column justified-content-start align-self-start">
              <h4 className="mb-2">
                Quick Assist is a platform that aims to break the stigma that
                help from a qualified tradesperson is inevitably expensive and
                time consuming. By using the Quick Assist platform customers
                will get access to a 30-minute video call with a qualified and
                experienced tradesperson when you need their help. The
                tradesperson (advisors as we call them) will be able to guide
                and advise you on emergencies around the home and support with
                troubleshooting while renovating.
              </h4>

              <h4 className="mb-2">
                We are Larisa and Miles – the founders of Quick Assist!
              </h4>
            </div>
            <div className="col-sm-12 col-lg-7 col-xl-7 px-4 mx-auto  mb-4 d-flex flex-column justified-content-start align-self-start">
              <img
                src="/Images/about.jpg"
                width="100%"
                height="auto"
                alt="Founder Members"
              />
            </div>
            <div className="col-sm-12 col-lg-7 col-xl-7 px-4 pb-2 mx-auto  d-flex flex-column justified-content-start align-self-start">
              <h4 className="mb-4">
                We founded Quick Assist while trying to do DIY renovations
                ourselves. The time spent finding tradespeople, waiting for call
                outs then being cancelled on at the last minute was frustrating.
                More time was wasted attempting to find videos, which could
                guide us through completing the renovations, but nothing was
                descriptive enough or tailored for our needs (it was never the
                same tap!)… And then Quick Assist was created!
              </h4>
              <h4 className="mb-4">
                We don’t believe getting advice and guidance from tradespeople
                needs to be costly and time consuming!
              </h4>
            </div>
          </div>
        </div>
      </>
    );
  }
}
